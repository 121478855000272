import React from "react";
import Axios from "axios";
import styled from "@emotion/styled";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff, Mail } from "react-feather";
import { Button, Form, Alert, Row, Col, Spinner } from "react-bootstrap";
import {
  ReasonPhrases,
  StatusCodes,
  getReasonPhrase,
  getStatusCode,
} from "http-status-codes";

import { signup } from "../../service/WalletService";

import { backendBaseUrl, deploymentEnv } from "../../env";

//import { withLocale } from "react-easy-localization";


import "./style.css";

import Page from "../../components/Page";

Axios.defaults.withCredentials = false;


const Privacy = ({ i18n, languageCode, changeLanguage }) => {


  return (
    <Page withSidenav={false}>
      <Row>
        <Col sm={0} md={1} lg={2}></Col>
        <Col sm={12} md={10} lg={8}>
          <table className="table" id="about_content">
            <tr>
              <td><h1>Privacy Policy</h1></td>
            </tr>
            <tr>
              <td>
              <div className="row">
                  <p>At Rapaygo.com, we are committed to protecting your privacy. This Privacy Policy describes our policies and practices in relation to how we collect, handle, use and disclose your personal information. It also deals with how you can complain about a breach of the privacy laws, how you can access the personal information we hold about you and how to have that information corrected.</p>

                  <p>This Privacy policy describes the privacy practices that are applicable to your use of the Rapaygo mobile and web application (the “App”) provided by Rapaygo LLC (the “Company”).</p>
                </div>
                <div className="row">
                  <h2>No Use by Children</h2>
                  <p>The App is not intended for use by children under the age of thirteen (13). If you are under the age of thirteen (13), please do not use the App.</p>
                </div>
                <div className="row">
                  <h2>Data Safety</h2>
                  <p>Rapaygo LLC is committed to protecting your privacy. We will not share your personal information with third parties without your consent unless required to do so under obligation. We will only share your personal information with third parties if we have a good faith belief that access to that information is necessary to:

                    <ul>
                      <li>fulfill a contract with you</li>
                      <li>fulfill our legal obligations</li>
                      <li>protect and defend the rights or property of Rapaygo LLC</li>
                      <li>prevent harm to the rights, property or safety of Rapaygo LLC, its users, or the public</li>
                      <li>protect the rights, property or safety of others</li>
                    </ul>
                  </p>


                  <h3>Notification of Data Shared with Third Parties</h3>
                  <p>
                    We will notify you of any changes to our privacy policy by posting the changes on the App. We will also notify you if any third party has shared your personal information with us, or requested that we share your personal information with them.
                  </p>
                  <h3></h3>
                </div>

                <div className="row">
                  <h2>Information Collection and Use</h2>
                  <p>The company’s policy is to collect as little user information as possible to ensure a completely private and anonymous user experience when using the Service. During the use of our application your data is encrypted both in transit to us (ingress) as well while it is being stored in our databases of file systems.</p>

                  <p>Aggregated data and basic usage events for analytics and application crashes are collected in order to maintain the integrity of the service and help users debug in case of errors. This data collection can be opt-out by the user at any moment from the application settings privacy options.</p>

                  <p>Any information that you provide to us when you communicate with our customer or technical support, will only be used for purposes of addressing your specific support request.</p>

                  <h3>Type of personal information collected.</h3>
                  <p>Your email address is the only personal information that we collect from you. It is required for a unique identifier in which to provide our users with a specific account of the rapaygo application and to communicate with you as a user. We will only use your email address to:
                    <ul>
                      <li>verifiy the account ownership identity</li>
                      <li>send you information and updates about the service</li>
                      <li>send you information about new features and products</li>
                      <li>send you information about special offers and promotions</li>
                      <li>Communicate on support requests</li>
                      <li>Communicate with you about your account</li>
                    </ul>
                  </p>


                  <h3>Storage Of Personal Information</h3>
                  <p>
                    We will store your personal information in a secure and encrypted storage area. Since personal data is encrypted both in transit and storage we cannot access it directly on your behalf or on request. You will need to provide us with your password to access your personal information, or reset your password. 
                  </p>
                
                </div>
                <div className="row">
                  <h2>Third Party App Stores</h2>
                  <p>If you purchase or access the App through a third party app store, such as iTunes or Google Play (an “App Store”), you will have a separate, additional account relationship with that App Store and the information you provide to that App Store will be handled in accordance with the privacy practices of the applicable App Store. App Stores are not partners or representatives of the Company and the Company is not responsible for the acts or omissions of an App Store with regard to the collection, use, maintenance or disclosure of your information. If you want to change your preferences with regard to how an applicable App Store collects or uses your information, you should review the privacy practices of the applicable App Store and adjust your privacy or other settings directly through the applicable App Store. The Company will not receive or have access to any of the information you provide to, or that is collected by, an applicable App Store.</p>

                  <p>Creators of Rapaygo never received any inquiries from NSA, FBI, FSB or any other government institution.</p>

                  <p>Nor NSA nor any other government institution was ever granted access to Rapaygo’s end users data.</p>

                  <p>Nor NSA nor any other government institution was ever granted permission to install backdoor or other potentially malicious code in any part of Rapaygo’s software.</p>

                </div>

                <div className="row">
                  <h2>Fair Information Practices</h2>

                  <p>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</p>

                  <p>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur: We will notify the users via in-site notification • Within 7 business days</p>

                  <p>We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</p>
                </div>

                <div className="row">
                  <h2>CAN SPAM Act</h2>

                  <p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</p>

                  <p>We collect your email address in order to: Send you the updates you subscribe for.</p>

                  <p>To be in accordance with CANSPAM and GDPR, we agree to the following:</p>

                  <p>If at any time you would like to unsubscribe from receiving future emails, you can email us at socialmedia@rapaygo.com and we will promptly remove you from ALL correspondence.</p>
                </div>
                <div className="row">
                  <h2>Contact Us</h2>

                  <p>If there are any questions regarding this privacy policy, you may contact us using the information below.</p>

                  <p>socialmedia@rapaygo.com</p>

                  <p>Last Edited on 04/10/2022</p>
                </div>

              </td>
            </tr>

          </table>
        </Col>
        <Col sm={0} md={1} lg={2}></Col>
      </Row>
    </Page>
  );
};

export default Privacy;


