import React, { useRef, useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import VoucherDetail from './VoucherDetail';

import {FiPrinter} from 'react-icons/fi';

const PrintableVoucherDetails = ({ voucher, userCurrency, satsRate }) => {
    const componentRef = useRef();
    const handlePrint =  useReactToPrint({
      content: () => componentRef.current,
    });

    const hp = () => {
        console.log("handlePrint")
        handlePrint();
    };
  
    return (
        <>{voucher &&             
            <div className='flex flex-col justify-center'>
                <div className='invisible w-0 h-0'><VoucherDetailsWithRef ref={componentRef} userCurrency={userCurrency} voucher={voucher} satsRate={satsRate}/></div>
                <div className='justify-center flex sm:justify-center w-full' onClick={()=>hp()}>
                    <button className='w-fit btn-common-blue'><FiPrinter className='mr-1'/> Print</button>
                </div>
            </div>
        }
        </>
    );
};

const VoucherDetailsWithRef = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <VoucherDetail {...props} />
        </div>
    );    
});

export default PrintableVoucherDetails;