import React, {useState, useEffect } from 'react';
import { Alert } from "react-bootstrap";
import { AddressService } from "../service/AddressService";
import { AxiosService } from '../service/AxiosService';

const AddressForm = ({ address, showForm, handleCancel, handleCreated, handleUpdated }) => {

    const [formState, setFormState] = useState({
        "name": "",
        "first_name": "",
        "last_name": "",
        "street_address": "",
        "street_address_2": "",
        "country": "USA",
        "city": "",
        "state": "",
        "zip_code": ""
    });

    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    useEffect(() => {
        if(address){
            console.log("useeffect address", address);
            // const nAddr = JSON.parse(JSON.stringify(address))
            // city: "PORTLAND"
            // country: "USA"
            // created_at: "Fri, 23 Sep 2022 10:52:01 GMT"
            // first_name: "Clayton"
            // id: 3
            // last_name: "Graham"
            // name: "rapaygo LLC HQ"
            // phone_number: null
            // state: "OR"
            // street_address: "6310 SE REEDWAY ST"
            // updated_at: "Fri, 14 Oct 2022 10:24:27 GMT"
            // zip_code: "97206"
            setFormState({
                "id": address.id,
                "name": address.name,
                "first_name": address.first_name,
                "last_name": address.last_name,
                "street_address": address.street_address,
                "street_address_2": address.street_address ? address.street_address_2 : "",
                "phone_number": address.phone_number ? address.phone_number : "",
                "country": "USA",
                "city": address.city,
                "state": address.state,
                "zip_code": address.zip_code
            });
        }
    } , [address]);
     
    const handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
    
        setFormState((formState) => ({
          ...formState,
          [name]: value
        }));  
    };  

    const clearForm = () => {
        setFormState({
            "name": "",
            "first_name": "",
            "last_name": "",
            "street_address": "",
            "street_address_2": "",
            "country": "USA",
            "city": "",
            "state": "",
            "zip_code": ""
        });
    };

    const onSaveButton = () => {
      setError(null); 
      console.log("onSaveButton", formState);
      if(!formState.street_address_2){ formState.street_address_2 = ""; }
      if(formState.id){
        AddressService.update(formState).then((cr) => {
            console.log("onSaveButton: cr", cr);
            setFormState(cr.data);
            handleUpdated(cr.data);
            setSuccess("Address updated");
            
        }).catch((err) => {
            let error_model = AxiosService.handleAxiosError(err);
            console.log("error", error_model);
            setError(error_model.message);
        });
      } else {
        AddressService.create(formState).then((response) => {
            console.log("response", response);
            setFormState(response.data);
            handleCreated(response.data);
            setSuccess("Address created");
        }).catch((err) => {
            let error_model = AxiosService.handleAxiosError(err);
            console.log("error", error_model);
            setError(error_model.message);
        });
    }
    };
  

    return (
        <>
            {/* {showForm ? (<>show</>) :(<>hide</>)} */}
            <div className={showForm ? "row" : "hidden"}>
                { error ? <Alert variant="danger">{error}</Alert> : null }
                { success && <Alert variant="success">{success}</Alert>}
                
                <div>
                    {formState.id ? <h3>Edit Address</h3> : <h3>Add Address</h3>}
                    {/* <div className='font-monospace text-xs break-all'>{JSON.stringify(formState)}</div> */}
                </div>
                <div className="flex flex-row bg-orange-400">
                    <label className="m-2 block w-full">
                    <span className="text-gray-700">Address Name</span>
                    <input type="text" name="name" onChange={(e)=>handleInputChange(e)} className="mt-1 block w-full rounded" placeholder="" value={formState.name} />
                    </label>
                </div>

                <div className='flex flex-row'>
                  <div className="w-1/2">
                        <label className="m-2 block">
                            <span className="text-gray-700">Contact First Name</span>
                            <input type="text" name="first_name" onChange={(e)=>handleInputChange(e)} className="mt-1 block w-full rounded" placeholder=""   value={formState.first_name} />
                        </label>
                    </div>
                    <div className="w-1/2">
                        <label className="m-2 block">
                            <span className="text-gray-700">Contact Last Name</span>
                            <input type="text" name="last_name" onChange={(e)=>handleInputChange(e)} className="mt-1 block w-full rounded" placeholder=""  value={formState.last_name}/>
                        </label>
                    </div>
                </div>

                <div className="flex flex-row bg-orange-400">
                    <label className="m-2 block w-full">
                    <span className="text-gray-700">Street Address Line 1</span>
                    <input type="text" name="street_address" onChange={(e)=>handleInputChange(e)} className="mt-1 block w-full rounded" placeholder=""  value={formState.street_address}/>
                    </label>
                </div>

                <div className="flex flex-row bg-orange-400">
                    <label className="m-2 block w-full">
                    <span className="text-gray-700">Street Address Line 2</span>
                    <input type="text" name="street_address_2" onChange={(e)=>handleInputChange(e)} className="mt-1 block w-full rounded" placeholder=""   value={formState.street_address_2}/>
                    </label>
                </div>

                <div className='flex flex-row'>
                  <div className="w-full sm:w-full md:w-1/3">
                        <label className="m-2 block">
                            <span className="text-gray-700">City</span>
                            <input type="text" name="city" onChange={(e)=>handleInputChange(e)} className="mt-1 block w-full rounded" placeholder=""  value={formState.city}/>
                        </label>
                    </div>
                    <div className="w-full sm:w-full md:w-1/3">
                        <label className="m-2 block">
                            <span className="text-gray-700">State</span>
                            <select name="state" value={formState.state} className="mt-1 block w-full rounded" onChange={(e)=>handleInputChange(e)}>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                        </label>
                    </div>
                    <div className="w-full sm:w-full md:w-1/3">
                        <label className="m-2 block">
                            <span className="text-gray-700">Postal Code</span>
                            <input type="text" name="zip_code" onChange={(e)=>handleInputChange(e)} className="mt-1 block w-full rounded" placeholder="" value={formState.zip_code}/>
                        </label>
                    </div>                   
                </div>
  
                <div className="flex flex-row justify-end">
                    <button className="btn-common-gray" onClick={()=>handleCancel()}>Cancel</button>
                    <button className="btn-common-blue" onClick={()=>clearForm()}>Clear Form</button>
                    <button className="btn-common-blue" onClick={()=>onSaveButton()}>Save Address</button>
                </div>      
            </div>
 
        </>
    );
  };
  
  
  export default AddressForm
  
  