import React, { useState, useEffect } from "react";
import lodash from "lodash";
import { useNavigate } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago'
import { Eye } from "react-feather";
import { fiatConvert } from "../service/WalletService";
import {FiTrash2} from 'react-icons/fi';

import { OrderService } from "../service/OrderService";
import ConfirmModal from "./ConfirmModal";

const OrderPaymentSummary = ({order, satsRate}) => {
    let total = 0;
    let totalFiat = 0;
    return (
        <div className="order-payment-summary">
            {order.order_payments.map((item, index) => {
                // let itemTotal = item.quantity * item.price;
                // let itemTotalFiat = fiatConvert(itemTotal, satsRate);
                // total += itemTotal;
                // totalFiat += itemTotalFiat;
                return (
                    <div key={index} className="order-payment-summary-item">
                        {item.invoice_payment && <>                     
                            <div className="flex flex-row justify-end">
                                {item.invoice_payment.status === "PENDING" && 
                                <div className="m-1 mr-3 h-7 rounded bg-gray-400 p-1 text-sm font-bold text-white">
                                    PAYMENT {item.invoice_payment.status}</div>}
                                {item.invoice_payment.status === "COMPLETED" && 
                                <div className="m-1 mr-3 h-7 rounded bg-green-600 p-1 text-sm font-bold text-white">
                                    PAYMENT {item.invoice_payment.status}</div>}            
                            </div>
                        
                        </>}
                    </div>
                );
            })}
        </div>
    )
};



const OrdersTable = ({orders, setOrders, satsRate, showCustomer=false}) => { 
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState();

    const setModalId = (id) => {
        setDeleteId(id);
        setShowModal(true);
    }

    const handleDeleteOrder = () => {
        console.log("delete order:",deleteId);
        
        OrderService.delete(deleteId).then(res => {
            console.log("deleted order:",deleteId);
            let ordersCopy = [...orders];
            let index = lodash.findIndex(ordersCopy, {id: deleteId});
            ordersCopy.splice(index, 1);
            setOrders(ordersCopy);
            setShowModal(false);
        }).catch(err => {
            console.error(err);
            setError(err.message);
            setShowModal(false);
        });
    }

    return (
        <>    
        {orders ?
            <div className="row">
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                {orders && orders.map((o, index) => 
                    <div key={index} className="p-2 m-1 rounded bg-slate-100">
                        <div className="text-sm text-gray-500 flex flex-row justify-between">
                            <div>{new Date(o.created_at).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour:"numeric", minute:"2-digit"})} - <ReactTimeAgo className="italic text-slate-600" date={Date.parse(o.created_at)}/></div>
                            <div className="flex flex-row justify-end">
                                    {o.payment_status === "CREATED" && <div className="m-1 mr-3 h-7 rounded bg-gray-400 p-1 text-sm font-bold text-white">{o.payment_status}</div>}
                                    {o.payment_status === "INVOICED" && <div className="m-1 mr-3 h-7 rounded bg-orange-600 p-1 text-sm font-bold text-white">{o.payment_status}</div>}
                                    {/* {p.status === "COMPLETED" && <div className="m-1 mr-3 h-7 rounded bg-green-600 p-1 text-sm font-bold text-white">{p.status}</div>}  
                                    {p.status === "CONFIRMED" && <div className="m-1 mr-3 h-7 rounded bg-blue-600 p-1 text-sm font-bold text-white">{p.status}</div>}                     */}
                            </div>
                            
                        </div>
                        <div className="flex flex-row justify-end"><OrderPaymentSummary order={o} satsRate={satsRate}/></div>

                        { showCustomer && o.customer && <div className="text-slate-500 font-bold text-xl cursor-pointer underline" onClick={()=>navigate(`/customer/${o.customer.id}`)}>{o.customer.company_name}</div> }


                        <div className="flex justify-between">
                            <div>
                                <div className="flex flex-wrap">
                                    {o.items.map((line, index) => <div key={index}>{line && <div className="bg-blue-100 rounded text-xs p-1 m-1"><strong>{line.quantity}</strong> X {line.payment_item.name}</div>}</div>)}
                                </div>
                            </div>
                            <div className="flex flex-col justify-center w-1/3">

                                <div className="text-3xl font-bold font-monospace text-green-600 text-right">                           
                                    {Intl.NumberFormat('en-US', 
                                    {style:'currency', currency: 'USD' }).format(o.total_cost_fiat)}
                                </div>
                                <div className="text-right font-monospace font-bold">
                                    { Intl.NumberFormat('en-US').format(fiatConvert(satsRate, o.total_cost_fiat).sats)} SATS
                                </div>
                                <div className="text-right flex justify-end">
                                <div className="button cursor-pointer hover:text-blue" onClick={()=>navigate(`/order/${o.id}`)}><Eye/>
                                </div>
                                {["CREATED", "INVOICED"].includes(o.payment_status) && 
                                <button className="ml-3 button cursor-pointer hover:text-blue text-2xl" onClick={()=>setModalId(o.id)}><FiTrash2/></button>}
                                </div>

                            </div>

                        </div>
                    </div>
                )}
                <ConfirmModal showModal={showModal} actionName={'Delete Order'} actionDescription={'delete this order'} actionCallback={handleDeleteOrder}/>
            </div>:
            <div className="row">
                <h3>No orders.</h3>
            </div>
        }
        </>
    );
}



export default OrdersTable;