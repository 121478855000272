import React, {useEffect, useState} from "react";
import { Row, Col, Button, Modal, Alert, Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import { Eye, EyeOff, XCircle, Trash } from "react-feather";

import { useNavigate } from 'react-router-dom';
import lodash from 'lodash';
import Ajv from "ajv";

import { FiEdit3 } from "react-icons/fi";

import { WalletService } from '../../service/WalletService';
import { AddressService } from "../../service/AddressService";
import Page from "../../components/Page";
import HelpAlert from "../../components/HelpAlert";
import AddressSelector from "../../components/AddressSelector";
import InlineTextInput from "../../components/InlineTextInput";
import {appDomain, deploymentEnv, deploymentInfo} from "../../env";


import { getUser,
    AuthenticationService,
    disconnect,
    getAccessTokenInfo } from '../../service/auth';

import "./style.css";


const PassPhraseModal = () => {
    const navigate = useNavigate();

    const [formState, setFormState] = useState({
        oldPassPhrase: "",
        passPhrase: "",
        passPhrase2: "",
        error: ""
    });
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState('');
    const [isHidden, setIsHidden] = useState(true);

    const handleClose = () => {      
        setShow(false);
        setFormState({
            oldPassPhrase: "",
            passPhrase: "",
            passPhrase2: "",
            error: ""
        });
        setErrors([]);
        setSuccess(false);
    };
    const handleShow = () => setShow(true);

    const handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        setFormState((formState) => ({
            ...formState,
            [name]: value
        }));
    };



    const togglePasswords = () => {
        let oldPassPhrase = document.getElementById("oldPassPhrase");
        let passPhrase = document.getElementById("passPhrase");
        let passPhrase2 = document.getElementById("passPhrase2");

        if(isHidden) {
            oldPassPhrase.type = "text";
            passPhrase.type = "text";
            passPhrase2.type = "text";
            
        } else {
            oldPassPhrase.type = "password";
            passPhrase.type = "password";
            passPhrase2.type = "password";
        }  
        setIsHidden(!isHidden);
    };

    const pwRe = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})";
    const formSchema = {
        type: "object",
        properties: {
            oldPassPhrase:  {type: "string"},
            passPhrase:     {type: "string", pattern: pwRe},
            passPhrase2:    {type: "string", pattern: pwRe},
            error:          {type: "string"}
        },
        required: ["oldPassPhrase","passPhrase","passPhrase2"],
        additionalProperties: false
    };


    let renderErrors = (errors) => {
        console.log(errors);
        return errors.map((error, index) => (
            <div key={index}>Invalid {error.instancePath} {error.keyword}</div>
        ));
    };

    const validateForm = (formState) => {

        if(formState.passPhrase !== formState.passPhrase2){
            let allErrors = [...errors]
            allErrors.push({
                "instancePath": "/passPhrase2",             
                "keyword": "new pass phrase match",
                "message": "new pass phrase does not match old pass phrase"
            });
            setErrors(allErrors);

        }

        //check that old password is correct
        AuthenticationService.verifyPassphrase(formState.oldPassPhrase).then((r) => {
            console.log("passphrase matched");      
        }).catch((error) => {
            let allErrors = [...errors]
            allErrors.push({
                "instancePath": "/oldPassPhrase",             
                "keyword": "match",
                "message": "pass phrase does not match"
            });
            setErrors(allErrors);
        });         
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("handle submit foo");
        setErrors([]);
        
        // setup formats
        const ajv = new Ajv();
        const validate = ajv.compile(formSchema);
        const schemaValid = validate(formState);
        validateForm(formState);

        if (!schemaValid || errors.length>0) {
            let allErrors = [];
            allErrors.push(...validate.errors);
            setErrors(allErrors);
        } else {
            AuthenticationService.updatePassphrase(formState.oldPassPhrase, formState.passPhrase).then(r=>{
                setSuccess("You have successfully changed your pass phrase. Logging you out.");
                setTimeout(() => {  
                    disconnect();
                    navigate('/login');
                }, 3000);
            }).catch((error) => {
                let allErrors = [...errors]
                allErrors.push({
                    "instancePath": "/newPassPhrase",             
                    "keyword": "unknown error, try again.",
                    "message": "pass phrase does not match"
                });
                setErrors(allErrors);
            });     
            
        }

    };

    return (
        <>
            <Button variant="blue" onClick={handleShow}>
                Change Pass Phrase
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Pass Phrase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="info"><strong>NOTE: Changing your password successfully will force you to
                    log back in with your new password on all devices!</strong></Alert>
                    {errors && errors.length>0 && <Alert className="flex flex-col" variant="danger">
                        <div className="font-bold">Validation Errors Found {renderErrors(errors)}</div>
                        <div>
                            <ul className="list-disc">
                                <li>Old pass phrase must be valid</li>
                                <li>Both new phrases must match</li>
                                <li>Be 8-16 in length</li>
                                <li>Upper and lower case</li>
                                <li>At least one special char (!@#$%&amp;*). </li>                                                               
                            </ul>
                        </div>
                    </Alert>}
                    {success ? <Alert variant="success">{success}</Alert>:
                    <>
                        <Row className="mb-1">
                            <Col>Old Pass Phrase:</Col>
                            <Col><input className="p-1 rounded bg-gray-200 border-solid border-gray-400 border-1" id="oldPassPhrase" type="password" name="oldPassPhrase" value={formState.oldPassword} onChange={(e) => handleInputChange(e)}/></Col>
                        </Row>

                        <Row className="mb-1">
                            <Col>New Pass Phrase:</Col>
                            <Col><input className="p-1 rounded bg-gray-200 border-solid border-gray-400 border-1" 
                                id="passPhrase" type="password" 
                                name="passPhrase" value={formState.passPhrase} onChange={(e) => handleInputChange(e)}/></Col>
                        </Row>

                        <Row className="mb-1">
                            <Col>New Pass Phrase Again:</Col>
                            <Col><input className="p-1 rounded bg-gray-200 border-solid border-gray-400 border-1" id="passPhrase2" type="password" name="passPhrase2" value={formState.passPhrase2} onChange={(e) => handleInputChange(e)}/></Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>{" "}</Col>
                            <Col className="align-left">
                                {isHidden ? <Eye onClick={()=>{togglePasswords()}}/> : 
                                    <EyeOff onClick={()=>{togglePasswords()}}/>}
                            </Col>
                        </Row>
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <button className="bg-gray-300 p-2 rounded hover:bg-gray-400" onClick={handleClose}>
                        Close
                    </button>
                    {!success && <button className="bg-blue-500 p-2 rounded hover:bg-blue-600" onClick={handleSubmit}>
                        Save Changes
                    </button>}
                </Modal.Footer>
            </Modal>
            </>
    );
    
};

const CompanyAddressManager = ({walletInfo, setWalletInfo}) => {

    const navigate = useNavigate();
    const [addresses, setAddresses] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [addressSelected, setAddressSelected] = useState(-1);
    const [addressAssigned, setAddressAssigned] = useState(walletInfo.company_address != null)


    useEffect(() => {
        setIsLoading(true);
        fetchAddressesPage({page: 1, page_size: 30});
        setIsLoading(false);
      } , [walletInfo]);
 
    const fetchAddressesPage = (cPage) => { 

        if(walletInfo.company_address == null){
            setAddressSelected(-1);
        } else {
            setAddressSelected(walletInfo.company_address.id);
        }

        setIsLoading(true);

        AddressService.getPaged(cPage).then((cr) => {
          setAddresses(cr.data.addresses);    
          setIsLoading(false);
        });
    };

    const handleAddressSelection = (event) => {
        
        let target = event.target
        let value = target.value
        let name = target.name  
        console.log("handleAddressSelection", name, value);
        //handleAddressSelection company_address_id 4
        if(name === 'company_address_id'){
            const nWalletInfo = {...walletInfo, company_address_id:value};
            setWalletInfo(nWalletInfo);
            setAddressAssigned(true);
        }
               
    };  

    const handleAddressEdit = () => {
        console.log("handleAddressEdit");
        setAddressAssigned(false);
        setAddressSelected(-1);
    };


    return (<>
        {isLoading && <Spinner animation="border"/>}

        {addressAssigned ? 
        <div className="flex flex-row justify-start">{`${walletInfo.company_address.street_address} ${walletInfo.company_address.city} ${walletInfo.company_address.state} ${walletInfo.company_address.zip_code}`} <FiEdit3 className="ml-1 cursor-pointer" onClick={()=>handleAddressEdit()}/></div> : 
        <>No company address set. 
            
        {addresses && addresses.length===0 ?
            <div><span className="underline cursor-pointer" onClick={()=>navigate('/addresses')}>Create an address</span> for this wallet.</div>:
            <div className="mr-2">
                Select an address for this wallet.
                <AddressSelector name={'company_address_id'} addresses={addresses} addressSelected={addressSelected} onChange={(e)=>handleAddressSelection(e)} />
            </div>}
        
        
        </>}   
    </>);

};

const KeyManager = () => {
    const [tokenInfo] = useState(getAccessTokenInfo(getUser()));
    
    const [keys, setKeys] = useState();

    useEffect(() => {
        if(tokenInfo.active) {
            WalletService.getKeys().then((keys) => {
                setKeys(keys.data); 
            });
        }

    }, [tokenInfo]);

    const handleCreate = (name, expires, key_assignment) => {
        if(tokenInfo.active) {
            WalletService.createKey(name, expires, key_assignment).then((key) => {
                let n_key = key.data;
                let c_keys = [...keys];
                c_keys.push(n_key);
                setKeys(c_keys); 
            });
        }        
    };

    const handleDelete = (id) => {
        if(tokenInfo.active) {
            WalletService.deleteKey(id).then((key) => {
                let c_keys = [...keys];
                // remove elements that matches the predicate
                lodash.remove(c_keys, (key_v) => key_v.id === id);
                setKeys(c_keys); 
            });
        }   
    };

    const handleInvalidate = (id) => {
        if(tokenInfo.active) {
            WalletService.invalidateKey(id).then((key) => {
                let c_keys = [...keys];
                // remove elements that matches the predicate
                var foundIndex = lodash.findIndex(c_keys, (key_v) => key_v.id === id);
                c_keys.splice(foundIndex, 1, key.data);
                setKeys(c_keys); 
            });
        }   
    };

    const keyIsValid =(key) => {
        let now = new Date();
        let expires = Date.parse(key.expires);
        // console.log(typeof(expires), typeof(now));
        return expires >= now;
    };

    return (<>
        <Row className="mb-3">
            <Col xs={12} md={12}>
                <div className="d-flex flex-row p-2 justigy-content-between">
                    {/* <div className="w-75 align-left"><strong>Key Manager</strong></div> */}
                    <div className="w-100 align-right"><KeyModalForm handleCreate={handleCreate}/></div>                  
                </div>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col xs={12} md={12}>
            {keys && keys.map((key, index) => (
                <div key={key.id}>
                    {keyIsValid(key) ?
                    <div className="flex flex-col p-1 bg-green-100 rounded mt-1">
                        <div className="text-lg font-bold">{key.key_name}</div>
                        {key.key_assignment && <div className="text-lg font-bold">{key.key_assignment}</div>}
                        <div className="m-1">
                            <div>API Key: <div className="p-1 bg-gray-600 text-orange font-monospace font-bold rounded">{key.key}</div></div>                        
                        </div>
                        <div className="m-1">   
                            <div>API Secret: <div className="p-1 bg-gray-600 text-orange font-monospace font-bold rounded">{key.secret}</div></div>                        
                        </div>
                        <div className="m-1">
                            Expires: {key.expires}                          
                        </div>
                        <div className="flex justify-end">
                            <XCircle className="m-1 cursor-pointer" onClick={()=>handleInvalidate(key.id)}/>
                            <Trash className="m-1 cursor-pointer" onClick={()=>handleDelete(key.id)}/>                            
                        </div>
                    </div> :
                    <div className="flex flex-col p-1 bg-gray-300 rounded mt-1">
                        <div className="text-lg font-bold">{key.key_name}</div>
                        <div className="m-1">Expired: {key.expires}</div>
                        <div className="flex justify-end">
                            <Trash className="m-1 cursor-pointer" onClick={()=>handleDelete(key.id)}/>                            
                        </div>
                    </div> }



                </div>             
            ))}
            </Col>
        </Row>
    
    </>);
}

const KeyModalForm = ({handleCreate}) => {

    const [formState, setFormState] = useState({
        key_name: "",
        expires_days: 30
    });

    const [selectedText, setSelectedText] = useState("30 Days");

    const [show, setShow] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isHidden, setIsHidden] = useState(true);

    const handleClose = () => {
        setShow(false);
        setFormState({
            key_name: "",
            expires_days: 1
        });
        setError(null);
    };
    const handleShow = () => setShow(true);

    const handleSelect = event => {
        console.log('handleSelect', event);
        setSelectedText(`${event} Days`);
        setFormState((formState) => ({
            ...formState,
            ['expires_days']: parseInt(event)
        }));
    };

    const handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        setFormState((formState) => ({
            ...formState,
            [name]: value
        }));
    };

    const handleSave = event => {
        console.log(handleSave, formState);
        if(formState.key_name.length<4){
            setError('Invalid name for key. Use something that explains what its for.')
        } else {
            handleCreate(formState.key_name, formState.expires_days, formState.key_assignment);
            handleClose();
        }
        
    };

    return (<>
    <div>
        <Button variant="blue" onClick={()=>handleShow()}>
            Create POS Key
        </Button>
    </div>
    <div>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Create POS Key</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            <div>Enter Key Name</div>
            <div className="m-1 w-full">
                <input className="w-full p-1 rounded bg-gray-200 border-solid border-gray-400 border-1" id="key_name" type="text" name="key_name" value={formState.key_name} onChange={(e) => handleInputChange(e)}/>
            </div>

            <div>Enter Key Assignment (ie. Rep Name)</div>
            <div className="m-1 w-full">
                <input className="w-full p-1 rounded bg-gray-200 border-solid border-gray-400 border-1" id="key_assignment" type="text" name="key_assignment" value={formState.key_assignment} onChange={(e) => handleInputChange(e)}/>
            </div>

            <div>Choose Expiration Period</div>
            <div className="m-1">
                <DropdownButton
                    variant="light"
                    title={selectedText}
                    id="dropdown-menu-align-right"
                    onSelect={handleSelect}>
                        <Dropdown.Item eventKey="1">1 Day</Dropdown.Item>
                        <Dropdown.Item eventKey="7">7 Days</Dropdown.Item>
                        <Dropdown.Item eventKey="30">30 Days</Dropdown.Item>
                        <Dropdown.Item eventKey="365">365 Days</Dropdown.Item>
                </DropdownButton>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="bg-gray-300 p-2 rounded hover:bg-gray-400" onClick={handleClose}>
                Close
            </button>
            {!success && <button className="bg-blue-500 p-2 rounded hover:bg-blue-600" onClick={(e)=>{handleSave(e)}}>
                Save Changes
            </button>}
        </Modal.Footer>

    </Modal>
    </div>

    </>);
}

const NonKYCLightningLimits = () => {

    return (<div>
        <div className="flex flex-wrap w-full">
            <div className="mr-4 w-full md:w-52">Max Receive Amount</div>
            <div className="w-full md:w-fit font-monospace font-bold">$500 USD</div>
        </div>
        <div className="mt-2 flex flex-wrap w-full">
            <div className="mr-4 w-full md:w-52">Max Daily Receive Amount</div>
            <div className="w-full md:w-fit font-monospace font-bold">$1500 USD</div>
        </div>
        <div className="mt-2 flex flex-wrap w-full">
            <div className="mr-4 w-full md:w-52">Max Withdrawal</div>
            <div className="w-full md:w-fit font-monospace font-bold">$500 USD</div>
        </div>
        <div className="mt-2 flex flex-wrap w-full">
            <div className="mr-4 w-full md:w-52">Max Daily Withdrawal</div>
            <div className="w-full md:w-fit font-monospace font-bold">$500 USD</div>
        </div>
        <div className="mt-2 flex flex-wrap w-full">
            <div className="mr-4 w-full md:w-52">Max Balance</div>
            <div className="w-full md:w-fit font-monospace font-bold">$2500 USD</div>
        </div> 
    
    </div>);

};

const MerchantLightningLimits = () => {

    return (
    <div>
        <div className="flex flex-wrap w-full">
            <div className="mr-4 w-full md:w-52">Max Receive Amount (SATs)</div>
            <div className="w-full md:w-fit font-monospace font-bold">$500 USD</div>
        </div>
        <div className="mt-2 flex flex-wrap w-full">
            <div className="mr-4 w-full md:w-52">Minimum Order Size</div>
            <div className="w-full md:w-fit font-monospace font-bold">$15 USD</div>
        </div>
    </div>);

};

const UserSettings = ({useStore}) => {

    const navigate = useNavigate();

    const { walletInfo, setWalletInfo, userCurrency, userLang } = useStore();

    const [tokenInfo] = useState(getAccessTokenInfo(getUser()));

    const [accountLimitsType, setAccountLimitsType] = useState("LN_NON_KYC");

    useEffect(() => {

        if (walletInfo && walletInfo.subscription_type  === "FREE"  && (walletInfo.kyc_status === 'NONE' || walletInfo.kyc_status===null) ){
            setAccountLimitsType("LN_NON_KYC")
        } else if (walletInfo && walletInfo.subscription_type  === "MERCHANT"  && walletInfo.kyc_status === 'VERIFIED'){
            setAccountLimitsType("MERCHANT_KYC")
        } else {
            setAccountLimitsType("UNKNOWN")
        }

    }, [walletInfo]);

    const handleEditInlineEmail = (id, value) => {
        console.log("handleEditInlineEmail edit", id, value);
        const nWallet = {...walletInfo, billing_email:value};
        setWalletInfo(nWallet);
    }

    const handleEditInlineStoreName = (id, value) => {
        console.log("handleEditInlineStoreName edit", id, value);
        const nWallet = {...walletInfo, store_name:value};
        setWalletInfo(nWallet);
    }

    const handleEditInlineNip05 = (id, value) => {
        console.log("handleEditInlineNip05 edit", id, value);
        const nWallet = {...walletInfo, nostr_nip05:value};
        setWalletInfo(nWallet);
    }

    const handleEditInlinePhone = (id, value) => {
        console.log("handleEditInlinePhone edit", id, value);
        const nWallet = {...walletInfo, billing_phone:value};
        setWalletInfo(nWallet);
    }
    
    
    return (
        <Page useStore={useStore}>

            <div className="p-4">
                <div className="row">
                    <h1>Settings</h1>
                    <div>
                    <HelpAlert variant='info' textclassName='text-blue' 
                        helpLink='/docs/#/usersettings'>User settings allow you to manage important facts about your account.</HelpAlert>
                    </div>
                </div>
                <div className="grid grid-cols-1 divide-y-4 divide-gray-200">
                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Username</div>
                        <div className="mt-3 w-full md:w-3/4">{tokenInfo ? `${tokenInfo.payload.sid}` : ""}</div>
                    </div>  
                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Subscription Plan</div>
                        <div className="mt-3 w-full md:w-3/4">

                            {walletInfo ? 
                                <>
                                <div>{walletInfo.subscription_type} {walletInfo.subscription_type===null && 'FREE'}</div>
                                {(walletInfo.subscription_type === 'FREE' || walletInfo.subscription_type===null) && <button className='text-gray-700 hover:text-blue-500 underline' onClick={()=>window.location="https://rapaygo.freshdesk.com/support/home"}>Contact Support To Change</button>}
                                </>:
                                <></>}
                        </div>
                    </div>  

                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">KYC Status</div>
                        <div className="mt-3 w-full md:w-3/4">
                            {walletInfo ? 
                            <>
                            <div>{walletInfo.kyc_status} {walletInfo.kyc_status===null && 'NONE'}</div>
                            {(walletInfo.kyc_status === 'NONE' || walletInfo.kyc_status===null) && <button className='text-gray-700 hover:text-blue-500 underline' onClick={()=>window.location="https://rapaygo.freshdesk.com/support/home"}>Contact Support To Change</button>}
                            </>:
                            <></>}                         
                        </div>
                    </div>  

                    <div className="mb-3 flex flex-wrap">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Account Limits</div>

                        {accountLimitsType === "LN_NON_KYC" &&
                        <div className="mt-3 flex w-full flex-col md:w-3/4">
                            <NonKYCLightningLimits/>
                        </div>}
                        {accountLimitsType === "MERCHANT_KYC" &&
                        <div className="mt-3 flex w-full flex-col md:w-3/4">
                            <div className="rounded text-green-600 bg-green-100 p-2 text-em">
                                You are have successfully signed up for the merchant program. This allows you 
                                to use bitcoin onchain without limits. 
                            </div>
                            <div className="mt-2">
                                <div className="font-bold">Lightning Network Limits</div>
                                <MerchantLightningLimits/>
                            </div>
                        </div>}
                        {accountLimitsType === "UNKNOWN" &&
                        <div className="mt-3 flex w-full flex-col md:w-3/4">
                            <div className="rounded text-red-600 bg-red-100 p-2 text-em">
                                Your KYC status and limits are NOT correctly configured.
                                <a className="text-red-600 font-bold ml-2" 
                                    href="https://rapaygo.freshdesk.com/support/home">
                                    Contact support</a> to get your account configured correctly.
                            </div>
                        </div>}
                        


                    </div> 

                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Wallet Pass Phrase</div>
                        <div className="mt-3 w-full md:w-3/4"><PassPhraseModal/></div>
                    </div> 

                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold flex flex-col">
                            <div>Lightning Address</div>
                            <div>Nostr NIP-05 Name</div>
                        </div>
                        {walletInfo ? 
                            <div className="mt-3 w-full md:w-3/4">
                                <div className="font-mono font-bold">{walletInfo.wallet_id}@{appDomain}</div>
                                <button className='text-gray-700 hover:text-blue-500 underline' 
                                onClick={()=>window.location="https://rapaygo.freshdesk.com/support/home"}>
                                    Contact Support To Change</button>
                            </div>:
                            <></>} 
                    </div> 

                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Nostr NIP-05 Hex</div>
                        {walletInfo ? 
                            <div className="mt-3 w-full md:w-3/4">
                                <div className="w-full flex flex-col">

                                    <div>{walletInfo?.nostr_nip05 === null && <>NIP-05 Public Key Not Set</>}
                                    {(walletInfo?.nostr_nip05 && walletInfo?.nostr_nip05.length<16) && <>NIP-05 Public Key Not Set</>}
                                    </div>
                                    <InlineTextInput field_name="store_name" text={walletInfo.nostr_nip05} editId={walletInfo.id} editCallback={handleEditInlineNip05}/>
                                </div>
                            </div>:
                            <></>} 
                    </div>


                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Token Expires</div>
                        <div className="mt-3 w-full md:w-3/4">{`${tokenInfo.expirationDate._d}`}</div>
                    </div>  
                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Wallet Verification</div>
                        <div className="mt-3 w-full md:w-3/4">
                            {walletInfo && walletInfo.payment_modes && walletInfo.payment_modes === 'SUPPORT' && 
                            <div className="rounded text-orange-600 bg-orange-100 p-2 text-em">
                                All voucher transactions must be approved by rapaygo support. You
                                must verify your account to remove the need for 
                                support approval. Account verification will allow for withdraw 
                                without approval. <a className="text-orange-600 font-bold" 
                                    href="https://rapaygo.freshdesk.com/support/home">
                                    Contact support</a> to obtain automatic approval for vouchers.
                            </div>}   

                            {walletInfo && walletInfo.payment_modes && walletInfo.payment_modes === 'VERIFIED' && 
                            <div className="rounded text-green-600 bg-green-100 p-2 text-em">
                                You are verified. Vouchers will be considered VALID and spendable upon creation. 
                            </div>}  


                        </div>
                    </div>  

                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Roles</div>
                        <div className="mt-3 w-full md:w-3/4">
                            {tokenInfo && tokenInfo.payload && tokenInfo.payload.scopes 
                                && tokenInfo.payload.scopes.map((scope, index) => (
                                <div key={index} className="font-monospace text-sm">{scope}</div>
                            ))}
                        </div>
                    </div> 


                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">User Currency</div>
                        <div className="mt-3 w-full md:w-3/4">{userCurrency}</div>
                    </div>
                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">User Language</div>
                        <div className="mt-3 w-full md:w-3/4">{userLang}</div>
                    </div>
                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Company Name</div>
                        <div className="mt-3 w-full md:w-3/4">
                            <div className="w-full flex flex-row">
                                <InlineTextInput field_name="store_name" text={walletInfo.store_name} editId={walletInfo.id} editCallback={handleEditInlineStoreName}/>
                            </div>
                        </div>
                    </div>  
                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Company Address</div>
                        <div className="mt-3 w-full md:w-3/4">
                            <CompanyAddressManager walletInfo={walletInfo} setWalletInfo={setWalletInfo}/>                   
                        </div>
                    </div>    
                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Billing Contact Email</div>
                        <div className="mt-3 w-full md:w-3/4">
                            {/* {walletInfo.billing_email ? `${walletInfo.billing_email}` : ""} */}
                            <div className="w-full flex flex-row">
                                <InlineTextInput field_name="billing_email" text={walletInfo.billing_email} editId={walletInfo.id} editCallback={handleEditInlineEmail}/>
                            </div>
                        </div>
                    </div>    
                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">Billing Contact Phone</div>
                        <div className="mt-3 w-full md:w-3/4">
                        <InlineTextInput field_name="billing_phone" text={walletInfo.billing_phone} editId={walletInfo.id}        editCallback={handleEditInlinePhone}/>
                            {/* {walletInfo.billing_phone ? `${walletInfo.billing_phone}` : ""} */}
                        </div>
                    </div>                                      
                    <div className="flex flex-wrap mb-3">
                        <div className="mt-3 w-full md:w-1/4 font-bold">API Keys</div>
                        <div className="mt-3 w-full md:w-3/4"><KeyManager/></div>
                    </div>  
                </div>
    
            </div>

        </Page>)
};

export default UserSettings;