import {AxiosService} from "./AxiosService";

export const VoucherService = {
    getAll (){
        return AxiosService.get(`/voucher`,{
            headers: {'Accept': 'application/json'}
        });
    },
    getAllUnspent (){
        return AxiosService.get(`/voucher/unspent`,{
            headers: {'Accept': 'application/json'}
        });
    },
    getPaged(pageInfo={page: 1, page_size: 10}) {
        return AxiosService.getPage(`/voucher`, pageInfo);
    },
    getById (id){
        return AxiosService.get(`/voucher/by_id/${id}`,{
            headers: {'Accept': 'application/json'}
        });
    },
    update (voucher){
        return AxiosService.put(`/voucher`, voucher, {
            headers: {'Content-Type': 'application/json'}
        });
    },
    invalidate (voucher_id){
        return AxiosService.put(`/voucher/invalidate/${voucher_id}`, {}, {
            headers: {'Content-Type': 'application/json'}
        });
    }
};