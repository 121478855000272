import {AxiosService} from "./AxiosService";

export const AddressService = {

    getPaged(pageInfo={page: 1, page_size: 10}) {
        return AxiosService.getPage(`/address`, pageInfo);
    },
    getById (id){
        return AxiosService.get(`/address/${id}`,{
            headers: {'Accept': 'application/json'}
        });
    },
    create (address){
        return AxiosService.post(`/address`, address, {
            headers: {'Content-Type': 'application/json'}
        });
    },
    update (address){
        return AxiosService.put(`/address`, address, {
            headers: {'Content-Type': 'application/json'}
        });
    },
    delete(id){
        return AxiosService.del(`/address/${id}`,{
            headers: {'Accept': 'application/json'}
        });
    },
    getAddressesCSV() {
        return AxiosService.get(`/address/csv`);
    },
};