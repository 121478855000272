import React, { useEffect, useState } from "react";
import { WordLogo } from '../components/PageLayout';
import { deploymentInfo } from "../env";
import { BsTwitter } from "react-icons/bs";
import {WalletService} from '../service/WalletService';


function Footer({ useStore }) {

  const [apiVersion, setApiVersion] = useState();

  useEffect(() => {
    
    if(apiVersion === undefined){
      console.log("useStore is undefined");
      WalletService.getApiVersion().then((cr) => {
        setApiVersion(cr.data);
      });

    } 
  }, [apiVersion]);

  return (
      <>
        <footer className="bg-dark text-light lg:text-left">

          <div className="mx-6 py-10 md:text-left">
            <div className="grid grid-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
              <div className="">
                <h6 className="
                    mb-4
                    flex
                    items-center
                    justify-center
                    md:justify-start
                    text-3xl
                  ">
                  <WordLogo />
                </h6>
                <p className="text-left">
                  Rapygo is a simple way for any small business to
                  accept bitcoin as payment.
                </p>
                <ul className="w-full flex items-center justify-center md:justify-start mb-4 text-left version-items text-gray-400">
                 <li>{deploymentInfo.env}</li>
                 <li>{deploymentInfo.branch}</li>
                 <li>{deploymentInfo.commitSha.substring(1, 12)}</li>
                 <li>{deploymentInfo.dateVal.toUTCString()}</li>
                 {apiVersion && <>
                  <li className="mt-2">API v{apiVersion.version}</li>
                  <li className="bg-slate-400 rounded-lg p-1 text-slate-900 w-fit">{apiVersion.lnd_rest_network}</li>                               
                 </>}

                 <div className="mt-3">copyright 2021 - rapaygo.com</div>
               </ul>
              </div>

              <div className="">
                <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                  Useful links
                </h6>
                <p className="flex items-center justify-center md:justify-start mb-4">
                <button className="text-light text-underline cursor-pointer" onClick={()=>window.open('/static/privacy.html')}>Privacy Policy</button>
                </p>
                <p className="flex items-center justify-center md:justify-start mb-4">
                <button className="text-light text-underline cursor-pointer" onClick={()=>window.open('/static/tos.html')}>Terms Of Service</button>
                </p>
                <p className="flex items-center justify-center md:justify-start mb-4">
                <button className="text-light text-underline cursor-pointer" onClick={() => {window.open('/docs/#/', '_blank') }}>Documentation</button>
                </p>
                <p className="flex items-center justify-center md:justify-start mb-4">
                <button className="text-light text-underline cursor-pointer" onClick={() => {window.open('https://rapaygo.freshdesk.com/support/home', '_blank') }}>Support</button>
                </p>
                <p className="flex items-center justify-center md:justify-start mb-4">
                <button className="text-light text-underline cursor-pointer" onClick={() => {window.open('https://documenter.getpostman.com/view/2315651/UyxdKUR7#25029a10-fed6-44b5-99f8-bb662107c9a0', '_blank') }}>API</button>
                </p>
                

              </div>
              <div className="">
                <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                  Contact
                </h6>

                <p className="flex items-center justify-center md:justify-start mb-4">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                    className="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                    </path>
                  </svg>
                  support@rapaygo.com
                </p>
                <p className="flex items-center justify-center md:justify-start mb-4">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                    className="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                    </path>
                  </svg>
                  + 01 (971) 339-0611
                </p>
                <p className="flex items-center justify-center md:justify-start mb-4">
                  <button className="text-white hover:underline flex flex-row justify-center items-center" onClick={()=>window.location='https://twitter.com/rapaygo'}><BsTwitter className="mr-4"/> On Twitter</button>
                </p>
              </div>
            </div>  
          </div>
          <div className="text-center p-6 bg-dark text-light">
            {/* <div>{children}</div> */}
            <span>© 2022 Copyright</span>
            <a className="text-gray-600 font-semibold ml-2" href="https://rapaygo.com/">Rapaygo LLC</a>
          </div>
        </footer>
      </>
     
  );
}



export default Footer;
