import {AxiosService} from "./AxiosService";

export const OrderService = {

    getPaged(pageInfo={page: 1, page_size: 10}) {
        return AxiosService.getPage(`/order`, pageInfo);
    },
    getById (id){
        return AxiosService.get(`/order/${id}`,{
            headers: {'Accept': 'application/json'}
        });
    },
    getPaymentByOrderAndInvoice (order_id, invoice_id){
        return AxiosService.get(`/order/${order_id}/invoice/${invoice_id}/payment`,{
            headers: {'Accept': 'application/json'}
        });
    },
    getPaymentsByOrder (order_id){
        return AxiosService.get(`/order/${order_id}/payment`,{
            headers: {'Accept': 'application/json'}
        });
    },
    create (order){
        return AxiosService.post(`/order`, order, {
            headers: {'Content-Type': 'application/json'}
        });
    },
    createFromCart (cart, customerId){
        let order = {
            customer_id: customerId,
            cart: cart
        };
        return AxiosService.post(`/order`, order, {
            headers: {'Content-Type': 'application/json'}
        });

    },

    update (order){
        return AxiosService.put(`/order`, order, {
            headers: {'Content-Type': 'application/json'}
        });
    },
    delete(id){
        return AxiosService.del(`/order/${id}`,{
            headers: {'Accept': 'application/json'}
        });
    },

    // /invoice_payment/ln/order_payment
    createLightningOrderPayment (orderId, amountSats, memo){
        return AxiosService.post(`/order/ln/order_payment`, 
            {'order_id':orderId, 'amount_sats':amountSats, 'memo':memo}, {
            headers: {'Content-Type': 'application/json'}
        });
    },
    createOnchainOrderPayment (orderId, amountSats, memo){
        return AxiosService.post(`/order/onchain/order_payment`, 
            {'order_id':orderId, 'amount_sats':amountSats, 'memo':memo}, {
            headers: {'Content-Type': 'application/json'}
        });
    },
    getOrdersCSV() {
        return AxiosService.get(`/order/csv`);
    },
}