import React, { useState, useEffect } from "react";
import lodash from "lodash"; 
import moment from 'moment'
import { WalletService } from '../service/WalletService';
import { getUser, getAccessTokenInfo } from '../service/auth';


const APIKeySelector = ({onChange, name, keySelected, label="API Key Selection"}) => {
    const [tokenInfo] = useState(getAccessTokenInfo(getUser()));  
    const [keys, setKeys] = useState(null);
    const [selectedKeyModel, setSelectedKeyModel] = useState(true);

    useEffect(() => {
        // console.log("APIKeySelector: useEffect", keySelected, typeof keySelected, keys);
        if(keys === null) {
          fetchKeys();
        }
        let sval = lodash.filter(keys,(o) => { 
          return o.id === parseInt(keySelected);
        });
        // console.log("APIKeySelector: useEffect: sval", sval);

        setSelectedKeyModel(sval[0]);
    }, [keySelected]);


    const fetchKeys = () => {
      if(tokenInfo.active) {
        WalletService.getKeys().then((keys) => {
            //filter out expired
            let sval = lodash.filter(keys.data,(o) => { 
              let ed = new Date(o.expires);
              return moment().isBefore(ed);
            });
            // console.log("APIKeySelector: keys", sval);
            setKeys(sval); 
        });
      }
    };
  
    return (
      <>
        <div className="w-100">

          <div className="w-full">
              <label className="block">
                  <span className="text-gray-700">{label}</span>
                  <select name={name} value={keySelected} onChange={onChange} className="mt-1 block w-full rounded">
                      <option value={-1}>None Selected</option>
                      {keys && keys.map((keyItem, index) => (<option key={index} value={keyItem.id}>{keyItem.key_name}</option>))}
                  </select>
              </label>
          </div> 

          {selectedKeyModel && 
          <div className="row">
          
            <div className="flex mb-3">
                <div className="mt-3 w-full md:w-1/4">Key Name</div>
                <div className="mt-3 p-1 w-full md:w-3/4 bg-gray-600 text-orange font-monospace font-bold rounded">{selectedKeyModel.key}</div>
            </div>
            {selectedKeyModel.key_assignment && <div className="flex mb-3">
                <div className="mt-3 w-full md:w-1/4">Key Assignment (Rep Name)</div>
                <div className="mt-3 p-1 w-full md:w-3/4 bg-gray-600 text-orange font-monospace font-bold rounded">{selectedKeyModel.key_assignment}</div>
            </div>}
            <div className="flex mb-3">
                <div className="mt-3 w-full md:w-1/4">Key Secret</div>
                <div className="mt-3 p-1 w-full md:w-3/4 bg-gray-600 text-orange font-monospace font-bold rounded">{selectedKeyModel.secret}</div>
            </div>
            <div className="flex mb-3">
                <div className="mt-3 w-full md:w-1/4">Expires</div>
                <div className="mt-3 p-1 w-full md:w-3/4 bg-gray-600 text-orange font-monospace font-bold rounded">{moment(selectedKeyModel.expires).fromNow()}</div>
            </div>
            
          </div>}
  
        </div>
      </>
    );
  }

  export default APIKeySelector;