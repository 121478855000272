import React, {useEffect, useState} from 'react'


import { FiCopy, FiCheckCircle, FiEdit3, FiSave } from "react-icons/fi";

const InlineTextInput = ({field_name, text, editId, editCallback}) => {

  const [editable, setEditable] = useState(false);
  const [formState, setFormState] = useState();

  useEffect(() => {
    let formStateLocal = {};
    formStateLocal[field_name] = text;
    setFormState(formStateLocal);
  }, [text]);

  const handleInputChange = event => {
      const target = event.target
      const value = target.value
      const fname = target.name

      setFormState((formState) => ({
          ...formState,
          [fname]: value
      }));
  };

  const toggleEdit = event => {
    setEditable(!editable);
  }

  return (
    <div className='flex flex-row w-full'>
      {/* {editable === true && <>EDITABLE</>} */}
      {editable  === true ? 
      <div className='w-full flex p-1'>

        {formState && 
        <>
          <textarea id={editId} name={field_name} rows="2" cols="50" defaultValue={formState[field_name]} onChange={(e)=>handleInputChange(e)}/> <FiSave className='ml-2' onClick={() => {editCallback(editId, formState[field_name]); toggleEdit()}}/>
        </>
        }

      </div> : 
      <div className='flex flex-row items-center'>{text}<FiEdit3 className='ml-2 cursor-pointer'onClick={toggleEdit}/></div>}  
    </div>
  );
};

export default InlineTextInput;