import React, { useRef, useState, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print'

import ReactTimeAgo from 'react-time-ago'
import { Trash } from "react-feather";
import lodash from "lodash";
import { useNavigate } from 'react-router-dom';
import { MdOutlinePayments } from "react-icons/md";
import { FaBitcoin } from "react-icons/fa";
import { FiPrinter } from 'react-icons/fi';
import { BsLightningFill } from "react-icons/bs";


import { LightningPaymentService } from "../service/LightningPaymentService";
import { AxiosService } from "../service/AxiosService";
import InlineTextInput from "./InlineTextInput";
import { OrderPaymentDetail } from './InvoiceDetail';
import OrderDetail from './OrderDetail';
import { OrderService } from '../service/OrderService';


const PrintableOrderInvoice = ({ order, payment, satsRate, walletInfo }) => {
    const componentRef = useRef();

    const handlePrint =  useReactToPrint({
      content: () => componentRef.current,
    });

    const hp = () => {
        console.log("handlePrint")
        handlePrint();
    };

    return (
        <>{order && payment &&      
            <div className='flex flex-col justify-center'>             
                <div className='hidden w-0 h-0'><OrderInvoiceDetailsWithRef 
                    ref={componentRef} orderPayment={payment} order={order} satsRate={satsRate} walletInfo={walletInfo} isPrintable={true}/></div>
                <div className='justify-center flex sm:justify-center w-full' onClick={()=>hp()}>
                    <button className='w-fit btn-common-blue'><FiPrinter className='mr-1'/> Print</button>
                </div>
            </div>
        }
        </>
    );
};



const OrderInvoiceDetailsWithRef = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} className="p-4">
            <div className='flex flex-row justify-between'>
                <div><h1>Customer Order Invoice</h1></div>
                {/* <div>{JSON.stringify(props.orderPayment)}</div> */}
                <div className='font-monospace uppercase'>INVOICE NO. {props.orderPayment.invoice_payment_id}</div>
            </div>
                
            <div><OrderDetail {...props} /></div>
            <div><OrderPaymentDetail {...props} /></div>
        </div>
    );    
});


export const OrderPaymentsTableLine = ({ payment, order, walletInfo, showDelete, satsRate, handleDeleteByHash }) => {
    const navigate = useNavigate();
     return (
        <>
        {/* <div>{JSON.stringify(payment)}</div> */}
        {payment && payment.invoice_payment && 
        
            <div className="mb-2 grid grid-flow-col grid-rows-3 gap-1 bg-slate-100 rounded">       
            <div className="row-span-3 flex w-full p-1">
                <div className="flex h-full w-full flex-col justify-between p-1">

                    <div className="w-full bg-slate-100 text-xs text-">

                        {new Date(payment.created_at).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour:"numeric", minute:"2-digit"})} - <ReactTimeAgo className="italic text-slate-600" date={Date.parse(`${payment.created_at} GMT`)} locale="en-US"/>
                        
                    </div>
                    <div className="w-full bg-slate-100 font">
                    {/* {payment.lnurl_payment ? 
                        <div className="flex flex-col">
                            <div className="invoice-action-phrase">{payment.lnurl_payment.action_phrase}</div>
                            <div>{payment.lnurl_payment.description}</div>
                            {payment.lnurl_payment.comment && <div>comment: {payment.lnurl_payment.comment}</div>}
                            </div> : 
                            <div className="w-full flex flex-row"><InlineTextInput field_name="payment_memo" text={payment.memo} editId={payment.payment_hash} editCallback={handleUpdateInvoiceMemo}/></div>} */}
                    </div>
                    <div className="w-full mr-2 flex flex-col">
                        
                        <div className={payment.amount_sat > 0 ? "font-mono text-green":"font-mono text-2xl font-bold text-red-800"}>
                            <span className="text-2xl mr-2 font-bold">{Intl.NumberFormat('en-US', {style:'currency', currency: 'USD' }).format(payment.fiat_amount)}</span>                                   
                        </div>

                        <div className="font-mono font-bold text-gray-500">{parseInt(payment.amount_sat)} SATS</div>
                    </div>            
                </div>
            </div>
            <div className="col-span-2">

                <div className="flex flex-row justify-end">
                    {payment.invoice_payment.status === "PENDING" && <div className="m-1 mr-3 h-7 rounded bg-gray-400 p-1 text-sm font-bold text-white">{payment.invoice_payment.status}</div>}
                    {payment.invoice_payment.status === "PAID" && <div className="m-1 mr-3 h-7 rounded bg-green-500 p-1 text-sm font-bold text-white">{payment.invoice_payment.status}</div>}
                    {payment.invoice_payment.status === "COMPLETED" && <div className="m-1 mr-3 h-7 rounded bg-green-600 p-1 text-sm font-bold text-white">{payment.invoice_payment.status}</div>}  
                    {payment.invoice_payment.status === "CONFIRMED" && <div className="m-1 mr-3 h-7 rounded bg-blue-600 p-1 text-sm font-bold text-white">{payment.invoice_payment.status}</div>}                       
                </div>

            </div>
            <div className="p-1 col-span-2 row-span-2 bg-green-400 flex justify-end">
                <div className="flex flex-col justify-end">
                    <div className="flex flex-row">
                        <div className="hover:text-blue-500 mr-1 cursor-pointer justify-center items-center" onClick={()=>navigate(`/invoices/${payment.invoice_payment_id}`)}>
                                {payment.invoice_payment.is_onchain === 1 ? 
                                    <FaBitcoin className="text-2xl mr-2"/>: 
                                    <BsLightningFill className="text-2xl mr-2"/>}
                        </div>   

                        {payment.invoice_payment.status === "PENDING" && <PrintableOrderInvoice walletInfo={walletInfo} order={order} payment={payment} satsRate={satsRate}/>}
                        
                        {payment.invoice_payment.status === "PENDING" && showDelete && <div className="hover:text-blue-500 mr-1 cursor-pointer" onClick={()=>handleDeleteByHash(payment.payment_hash)}><Trash/></div>}

                    </div>
                </div>
            </div>     
        </div>       
        }
        </>

     );
};

const OrderPaymentsTable = ({order, payments, setPayments, deleteHandler, satsRate, walletInfo, showDelete=false}) => {


    const [error, setError] = useState();

    const handleDeleteByHash = (payment_hash) => {
        console.log("handleDeleteByHash", payment_hash);
        deleteHandler(payment_hash);
    };

    const handleUpdateInvoiceMemo = (payment_hash, memo) => {
        // find the payment by payment_hash and update the memo
        const payment = lodash.find(payments, {payment_hash});
        console.log("payment", payment);
        if (payment) {
            payment.memo = memo;
            LightningPaymentService.update(payment)
            .then((response) => {
                console.log("updated invoice memo", response.data);
                // replace the payment with the updated one in payments
                const newPayments = lodash.map(payments, (payment) => {
                    if (payment.payment_hash === payment_hash) {
                        return response.data;
                    }
                    return payment;
                } );                
                setPayments(newPayments);
            }).catch((err) => {
                let error_model = AxiosService.handleAxiosError(err);             
                setError(`There was an error when attempting to update this invoice. ${error_model.message} reasons: ${error_model.data.reasons}`); 
            });
        }  
    };
           
    return (
        <>
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="flex flex-row justify-end">
                {payments ? <>{payments.length} payments</> : <>No payments</>} 
            </div>
            { payments ?
            <div className="row">
                {payments.map((p, index) => (
                    <div key={p.id}>
                        <OrderPaymentsTableLine payment={p} order={order} walletInfo={walletInfo} satsRate={satsRate} showDelete={showDelete} handleDeleteByHash={handleDeleteByHash} handleUpdateInvoiceMemo={handleUpdateInvoiceMemo}/>
                    </div>))}
            </div>  
            : <><h3>No payments.</h3></>}

        </>
    )
}

export default OrderPaymentsTable;