import React, { useState } from 'react';

import {OrderService} from '../service/OrderService'

import {
    Button, Form, Row, Col, Alert
} from 'react-bootstrap';



const OrderPayerInfoForm = ({ paymentHash, order }) => {

    const [formState, setFormState] = useState(order);
    const [savedStatus, setSavedStatus] = useState("start");

    const handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        setFormState((formState) => ({
            ...formState,
            [name]: value
        }));
    };


    const onSaveButton = () => {
        console.log('save', formState);
        // OrdersService.updateByPaymentHash(paymentHash, formState).then(r=>{
        //     console.log("done", r);
        //     setSavedStatus("done");
        //   }).catch(e=>{
        //     console.log(e);
        //     setSavedStatus("error");
        //   });
    };

    return (

        <Row>
            <Col xs={12} md={3}></Col>
            <Col xs={12} md={6}>
                {savedStatus !== "done"?
 
                <div className='mb-3'>

                    {savedStatus === "start"?
                    <Alert variant="info">Payer needs to save shipping information for 
                    this order to complete order and allow delivery. <strong>Minimum 
                        information is required for shippers to deliver goods!</strong></Alert>:
                    <Alert variant="error">ERROR! Please make sure the 
                    information you entered is correct.</Alert>}

                    <Form>
                        <Row>
                            <Col xs={12} md={8}>
                                <Form.Group>
                                    <Form.Label>Recipient Name*</Form.Label>
                                    <Form.Control
                                        name="shipping_to_name"
                                        value={formState.shipping_to_name}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group>
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        name="phone_number"
                                        value={formState.phone_number}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group>
                            <Form.Label>Street Address*</Form.Label>
                            <Form.Control
                                name="address_1"
                                value={formState.address_1}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Unit or Apartment</Form.Label>
                            <Form.Control
                                name="address_2"
                                value={formState.address_2}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <Form.Label>City*</Form.Label>
                                    <Form.Control
                                        name="city"
                                        value={formState.city}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group>
                                    <Form.Label>State*</Form.Label>
                                    <Form.Control
                                        name="state"
                                        value={formState.state}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group>
                                    <Form.Label>Postal Code*</Form.Label>
                                    <Form.Control
                                        name="postal_code"
                                        value={formState.postal_code}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                    </Form>
                    <Button className="m-2" variant="primary" onClick={onSaveButton}>
                        Save
                    </Button>
                </div>:
                <><Alert variant="success">Shipping ifo Successfully saved.</Alert>
                </>}

            </Col>
            <Col xs={12} md={6}></Col>


        </Row>


    );
};


export default OrderPayerInfoForm

