import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import Page from "../../components/Page";

import {ProductService} from '../../service/ProductService'
import ProductForm from "../../components/ProductForm";

//import { withLocale } from "react-easy-localization";
import { Breadcrumb } from "react-bootstrap";

import "./style.css";


const ProductEditor = ({ useStore }) => {

  const navigate = useNavigate();
  const { id } = useParams();

  const { pathname } = useLocation();
  const [paymentItem, setPaymentItem] = useState();
  const [editFormType, setEditFormType] = useState("create");

  useEffect(() => {
    // console.log("payment item id", pathname.replace("/item/create", "create"));
    let pathCreate = pathname.replace("/item/create", "create");
    let pathEdit = pathname.replace("/item/edit/", "");
    console.log("pathname", pathname, pathCreate, pathEdit);
    
    if (pathCreate === "create") {
      setEditFormType("Create");
    } else {
      // setPaymentItemId(parseInt(pathname.replace("/item/edit/", "")));
      console.log("payment item id", id);
      setEditFormType("Edit");
      ProductService.getById(id).then(r => {
        setPaymentItem(r.data);
      });
    
    }
      
  }, [id]); 
  
  return (
    <Page useStore={useStore}>
      <div className="p-4">
        <div className="row">
          <Breadcrumb>
            <Breadcrumb.Item href="#" onClick={()=>navigate("/items")}>Products</Breadcrumb.Item>
            <Breadcrumb.Item active>{editFormType} Product Item</Breadcrumb.Item>
          </Breadcrumb>
          <h1>{editFormType} Product Item</h1>
          {(paymentItem && editFormType === 'Edit') && 
          <ProductForm paymentItem={paymentItem} formType={editFormType.toLowerCase()}/>}          
          {(editFormType === 'Create') && 
          <ProductForm formType={editFormType.toLowerCase()}/>}        
        </div>
      </div>

    </Page>
  );
};

export default ProductEditor;