import React, { useEffect } from "react";
import Axios from "axios";
import styled from "@emotion/styled";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Form, Alert, Row, Col, Spinner } from "react-bootstrap";
import queryString from 'query-string';
import { Eye, EyeOff } from "react-feather";
//import { withLocale } from "react-easy-localization";

import { AuthenticationService } from "../../service/auth";
import { backendBaseUrl } from "../../env";

import Page from "../../components/Page";

import "./style.css";


Axios.defaults.withCredentials = false;

const ResetPasswordForm = ({resetToken}) => { 

  const navigate = useNavigate();

  const [savingForm, setSavingForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [formState, setFormState] = useState({ 
    error: null, 
    email: "",
    passPhrase: ""
  });

  useEffect(() => {
    console.log("ResetPasswordForm form state", resetToken, formState);
  } , [resetToken]);

  const togglePasswords = () => {
    let passPhrase = document.getElementById("passPhrase");

    if(isHidden) {
        passPhrase.type = "text";    
    } else {
        passPhrase.type = "password";
    }  
    setIsHidden(!isHidden);
};

  const handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormState((formState) => ({
      ...formState,
      [name]: value,
    }));
  };

  const resetFormSubmit = (event) => {
    event.preventDefault();
    setSavingForm(true);
    console.log("ResetPasswordForm form state", formState);

    Axios.post(`${backendBaseUrl}/auth/reset`, formState, 
      {headers:{"Authorization":resetToken}})
    .then((r) => {
      setSavingForm(false);
      setSuccess(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }).catch((e) => {
      console.log("ResetPasswordForm error", e);
      setSavingForm(false);
      // let errorMessage = e.response.data.error;
      let errorMessage = "There was a problem resetting your password.";
      
      setFormState((formState) => ({
        ...formState,
        error: errorMessage,
      }));
    });
  };

  return (
    <ResetPasswordFormWrapper>
      <div className="signup-form justify-center">
        <Row>
          <Col className="align-center">
            {formState.error && <Alert variant="danger">{formState.error}</Alert>}
            { success ? <Alert variant="success">Password reset successful. Please login again.</Alert> :
            <Form onSubmit={resetFormSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  className="mb-2 align-center"
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={handleInputChange}
                  value={formState.email}/>
                <Form.Label>New Pass Phrase</Form.Label>
                <Form.Control
                  className="mb-2 align-center"
                  id="passPhrase"
                  type="password"
                  name="passPhrase"
                  placeholder="Enter password"
                  onChange={handleInputChange}
                  value={formState.passPhrase}/>
              </Form.Group>
              <div className="w-full flex justify-center">
                {isHidden ? <Eye onClick={()=>{togglePasswords()}}/> : 
                    <EyeOff onClick={()=>{togglePasswords()}}/>}
              </div> 
              <div className="w-full flex justify-center">
                <button className="btn-common-blue" type="submit" disabled={savingForm}>
                  {savingForm ? <Spinner animation="border" size="sm" /> : "Reset Password"}
                </button>
              </div>        

            </Form> }
          </Col>
        </Row>
      </div>
      </ResetPasswordFormWrapper>
  );
};

const SendResetEmailForm = () => {

  const navigate = useNavigate();

  const [formState, setFormState] = useState({ error: null, email: "" });
  const [savingForm, setSavingForm] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isValidEmail = (email) => {
    if (validateEmail(email)) {
      return true;
    } else {
      return false;
    }
  }

  const handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormState((formState) => ({
      ...formState,
      [name]: value,
    }));
  };

  const resetFormSubmit = (event) => {
    event.preventDefault();
    
    if (isValidEmail(formState.email)) {
      setSavingForm(true);
      AuthenticationService.sendResetEmail(formState.email).then((r) => {
        setSavingForm(false);
        setSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
        
      })
      .catch((e) => {
        console.log(e);
        setSavingForm(false);
        setFormState({ error: "There was a problem when trying to send the email." });
      });


    } else {
      setFormState((formState) => ({
        ...formState,
        error: "Please enter a valid email address",
      }));
      setSavingForm(false);
    }    
  };

  return(<>
    <ResetPasswordFormWrapper>
      <div className="flex flex-col justify-center">
        <Row>
          <Col>
            <Alert variant="info" className="mt-2">Please enter the email address associated
            with your account. If the account exists we will send instructions on how to reset 
            the pass phrase.</Alert>
            {formState.error && <Alert variant="danger">{formState.error}</Alert>}
            {success && <Alert variant="success">If this account exists an email has been sent to you with instructions on how to reset your password.</Alert>}            
          </Col>
        </Row>
        {!success && 
        <Row>
          <Col>
            <Form onSubmit={resetFormSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="flex justify-center">Email address</Form.Label>
                <Form.Control
                  className="mb-2 text-center"
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={handleInputChange}
                  value={formState.email}/>
              </Form.Group>
              <div className="w-full flex justify-center">
                <button className="btn-common-blue"  type="submit" disabled={savingForm}>
                  {savingForm ? <Spinner animation="border" size="sm" /> : "Reset Password"}
                </button>
              </div>

            </Form>
          </Col>
        </Row>}

      </div>
    </ResetPasswordFormWrapper>  
  
  </>);
};

let useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);  
};

const ResetPassword = () => {

  const query = useQuery();
  const location = useLocation();
  const [resetToken, setResetToken] = useState();


  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if(parsed.reset_token) {
      console.log("ResetPassword parsed reset token", parsed);
      setResetToken(parsed.reset_token);
    }

  }, [location]);


  return (
    <Page withSidenav={false}>
        <div className="p-4 flex justify-center w-full">
          <div className="w-96 mb-4">
            <h1 className="text-center">Reset Password</h1>
            {resetToken ? <ResetPasswordForm resetToken={resetToken}/> :
              <SendResetEmailForm /> }
          </div>
        </div>
    </Page>
  );
};

const ResetPasswordFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ResetPassword;
