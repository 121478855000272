import styled from '@emotion/styled';
import { Row } from 'react-bootstrap';


let WordLogo = ({className}) => {
  return (<div>
    <TextLogoRa className={className}>ra</TextLogoRa>
    <TextLogoPay className={className}>pay</TextLogoPay>
    <TextLogoGo className={className}>go</TextLogoGo>
  </div>)
}


//245, 176, 66
const TextLogoRa = styled.span`
font-family: 'Bree Serif', serif;
color: #f5b042;
`;

// 66, 212, 245
const TextLogoPay = styled.span`
font-family: 'Bree Serif', serif;
color: #42d4f5;
`;

// 116, 224, 110
const TextLogoGo = styled.span`
font-family: 'Bree Serif', serif;
color: #74e06e;
`;

const CenteredContainer = styled.div`
text-align: center;
`;

const LeftContainer = styled.div`
text-align: left;
`;

const PageContainer = styled.header`
`;


const SectionRow = styled(Row)`
margin-top: 30px;
margin-bottom: 60px;
`;


export {
    SectionRow,
    PageContainer,
    CenteredContainer,
    LeftContainer,
    WordLogo
  }