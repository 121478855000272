import React, { useState, useEffect  } from 'react';
import Axios from "axios";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import {  Row, Col, Spinner } from "react-bootstrap";

//import { withLocale } from "react-easy-localization";

// import {LightningPaymentService} from '../../service/LightningPaymentService'
import {WalletService} from '../../service/WalletService'

import PaymentPage from "../../components/PaymentPage";
import { WordLogo } from '../../components/PageLayout';
import InvoiceDetail from '../../components/InvoiceDetail';

import { backendBaseUrl } from '../../env';


import "./style.css";


Axios.defaults.withCredentials = false;
const PayInvoice = () => {
 
  const navigate = useNavigate();   

  const [searchParams] = useSearchParams();
  const {payment_hash} = useParams();
  const [successUrl] = useState(decodeURI(searchParams.get("success")));
  // const [ecommerceOrderId] = useState(decodeURI(searchParams.get("order_id")));
  // const [amountFiat] = useState(decodeURI(searchParams.get("amount_fiat")));
  const [currency] = useState(decodeURI(searchParams.get("currency")));
  const [lang] = useState('en-US');
  const [storeLogo] = useState(decodeURI(searchParams.get("store_logo_url")));


  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [invoicePayment, setInvoicePayment] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const [satsRate, setSatsRate] = useState();

  const [ts, setTs] = useState(Date.now());
  const [donePolling, setDonePolling] = useState(true);
  const [timerId, setTimerId] = useState();

  useEffect(() => {
    if (payment_hash) {
      console.log('making rapaygo call hash', payment_hash)
      Axios.get(`${backendBaseUrl}/invoice_payment/by_payment_hash/${payment_hash}`)
        .then((r) => {
          setInvoicePayment(r.data);
          setDonePolling(false);
          setTs(Date.now()); //keep polling
          Axios.get(`${backendBaseUrl}/quote/sats/${currency}`)
          .then((response) => {
            setSatsRate(response.data);
            setIsLoading(false);
          });
        })
        .catch((error) => {
          setIsError(true);
          setIsLoading(false);
        });

        // LightningPaymentService.getByHash(payment_hash)
        // .then(response => {
        //   let paymentData = response.data;
        //   setInvoicePayment(paymentData);
        //   setDonePolling(false);
        //   setTs(Date.now()); //keep polling
        //   WalletService.getSatsQuote(currency).then(response=>{
        //     setSatsRate(response.data);
        //     setIsLoading(false);
        //   });   
        // })  
        // .catch(error => {
        //   setIsError(true);
        //   setIsLoading(false);
        // });
    } else {
      console.log('no hash')
    }
  }, []);


  // polling
  useEffect(() => {
    //every 10 secs
    setTimerId(setTimeout(() => {
      
      if(!donePolling){
        console.log("polling");

        Axios.get(`${backendBaseUrl}/invoice_payment/by_payment_hash/${invoicePayment.payment_hash}`)
        // LightningPaymentService.getByHash(invoicePayment.payment_hash)
        .then((r) => {     
          setInvoicePayment(r.data);  
          if(r.data.status === 'PENDING') {
            setTs(Date.now()); //keep polling
          }
          if(r.data.status === 'COMPLETED') {
            handleSuccess(r.data.status);
          }
        }).catch((err) => {
          setError(err.message);
        });
      }

    }, 10000));
  },[ts]);

  const handleSuccess = (message) => {
    console.log("success");
    clearTimeout(timerId);
    setDonePolling(true);
    setSuccess(true);
    closeHandler();
  };

  const closeHandler = () => {
    setTimeout(()=>{
      window.location = `${successUrl}`;
    }, 3000);   
  };


  return (

    <PaymentPage>
      <div className='w-100'>

        {isLoading ? <Spinner/> : <></>}

        {/* store logo */}
        {storeLogo &&<div className='p-2 w-full flex justify-center'>
          <img className='w-36' src={storeLogo} alt="store logo"/>
        </div>} 
        
        {(invoicePayment && satsRate) && <>
          <InvoiceDetail userCurrency={currency} userLang={lang} invoice={invoicePayment} satsRate={satsRate}/>        
        </> }

        <div className='w-full flex justify-center'>
          <div className='w-96 text-center flex flex-col'>
            <div><p>You will be redirected back to our store when the purchase is completed.</p></div>
            <div className='w-full flex justify-center'>
              <button className='w-48 m-1 p-1 rounded-md bg-slate-700 hover:bg-slate-600 text-white hover:text-white flex justify-center items-center'>
                <div className='mr-1 hover:underline' onClick={()=>navigate('/')}>payments by</div><WordLogo className="mb-1 text-xl"/></button>
              </div> 
            </div>
      
        </div>
      </div>
    </PaymentPage>
  );
};

export default PayInvoice;
