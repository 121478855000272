import React, { useState, useEffect  } from 'react';
import { useParams } from "react-router-dom";
//import { withLocale } from "react-easy-localization";
import {
  Spinner, Breadcrumb
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {VoucherService} from '../../service/VoucherService';
import VoucherDetail from '../../components/VoucherDetail';
import PrintableVoucherDetails from '../../components/PrintableVoucherDetail';
import Page from "../../components/Page";


import "./style.css";

/**
 * 
 * @param {*} props
	"created_at": "Thu, 26 May 2022 16:35:33 GMT",
	"id": 32,
	"is_unique": 0,
	"k1": "FH2CynM5JthPYgZzpWaQZb",
	"lnurl": "LNURL1DP68GURN8GHJ76MPD3KX7CNN9EHHYEE6X5CRYVP0WA5HG6RYWFSHWTMVDE6HYMP0WD5KUEMVV5HKYJMXG4HKW4PCX49K6NRJD9FRS5N6W34567SSSGUN0",
	"max_withdrawable": 334,
	"min_withdrawable": 334,
	"number": 0,
	"open_time": 1653608133,
	"payment_hash": null,
	"title": "rapaygo POS voucher payment invoice 6a91e 20-EHUJVkFJ ",
	"unique_hash": "bKfEogT85KmLriR8RztkMz",
	"updated_at": "Thu, 26 May 2022 16:35:33 GMT",
	"used": 0,
	"uses": 1,
	"usescsv": "1",
	"wait_time": 0,
	"wallet_id": 20
}} param0 
 * @returns 
 */
const Voucher = ({ useStore}) => {

  const { satsRate } = useStore();
  

  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [voucher, setVoucher] = useState();

  const navigate = useNavigate();
  
 
  useEffect(() => {
    VoucherService.getById(id).then((voucher)=>{
      setIsLoading(false);
      setVoucher(voucher.data);
    });

  }, [id]);

  let handleVoucherInvalidate = (e) => {
    console.log('=== handleVoucherInvalidate invalidate');
    setIsLoading(true);
    let voucherN = {...voucher, status: "INVALID"};
    VoucherService.invalidate(voucherN.id).then((voucher)=>{
      setIsLoading(false);
      setVoucher(voucher.data);  
    });
  };

  return (
    <Page useStore={useStore}>
      <div className='p-4 row'>
      { isLoading && <Spinner animation='border'/>}
      {voucher ?

      <div className='flex flex-col'> 
          <div className='flex justify-center w-full'>
            <Breadcrumb>
              <Breadcrumb.Item href="#" onClick={()=>navigate("/vouchers")}>Vouchers</Breadcrumb.Item>
              <Breadcrumb.Item active>Voucher {voucher.id}</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <VoucherDetail voucher={voucher} satsRate={satsRate} handleVoucherInvalidate={handleVoucherInvalidate} allowInvalidate={true}/>
          <PrintableVoucherDetails voucher={voucher} satsRate={satsRate}/>
      </div>:
      <>
        <div>
          <h3>No voucher found.</h3>
        </div>
      </>}
      </div>
    </Page>
  );
};


export default Voucher;
