import {LocalStorageService} from "./LocalStorageService";
import { deploymentEnv } from '../env';




let cartKey = (store_slug) => {
    return `rapaygo_${deploymentEnv}_${store_slug}-cart`;
};

export const CartService = {
    addItem(paymentItem, quantity, store_slug) { // add item to cart
        console.log("CartService addItem", paymentItem, quantity);
        let cart = JSON.parse(LocalStorageService.get(cartKey(store_slug)));
        if (!cart) {
            cart = {};
        }
        if (!cart[paymentItem.id]) {
            cart[paymentItem.id] = {
                paymentItem: paymentItem,
                quantity: quantity
            };
        } else {
            cart[paymentItem.id].quantity += quantity;
        }

        LocalStorageService.set(cartKey(store_slug), JSON.stringify(cart));
     
    },
    getCart(store_slug) {
        console.log("CartService getCart", store_slug, cartKey(store_slug));
        if (typeof window !== "undefined") {
            if (LocalStorageService.get(cartKey(store_slug))) {
                console.log("CartService cart key", cartKey(store_slug));
                return JSON.parse(LocalStorageService.get(cartKey(store_slug)));
            }
        } else {
            console.log("we are broken");
        }
        return {};
    },

    cartAsList(store_slug) {
        let cartItems = [];
        for (const [key, value] of Object.entries(this.getCart(store_slug))) {
            console.log(`${key}: ${JSON.stringify(value.paymentItem)}`);
            value.paymentItem.quantity = value.quantity;
            cartItems.push(value.paymentItem);
        }
        return cartItems;
    }

};