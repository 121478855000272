import React, {useState, useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";

import { CheckCircle } from "react-feather"; 
//import { withLocale } from "react-easy-localization";
import { Button, Spinner, Row, Col } from 'react-bootstrap';

import Page from "../../components/Page";
import OrderPayerInfoForm from "../../components/OrderPayerInfoForm";
import {LightningPaymentService} from '../../service/LightningPaymentService'
import {OrderService} from '../../service/OrderService'

import "./style.css";
import OrderDetail from "../../components/OrderDetail";

const PaymentSuccess = ({ i18n, languageCode, changeLanguage }) => {  
  let [searchParams, setSearchParams] = useSearchParams();
  let [paymentHash, setPaymentHash] = useState(searchParams.get("payment_hash"));
  let [isLoading, setIsLoading] = useState(true);
  let [isError, setIsError] = useState(false);
  let [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  let [invoicePayment, setInvoicePayment] = useState();
  let [order, setOrder] = useState();

  useEffect(() => {
    if (paymentHash) {
      LightningPaymentService.getByHash(paymentHash)
        .then(response => {
          let paymentData = response.data;
          if(paymentData.state === "initiated") {
            setIsPaymentSuccess(false);
          } else if(paymentData.state === "completed") {
            setIsPaymentSuccess(true);
            setInvoicePayment(paymentData.invoice_payment);
            // if(paymentData.invoice_payment.order_id) {
            //   OrdersService.getByPaymentHash(paymentHash)
            //   .then(response => {
            //     let order = response.data;
            //     setOrder(order);
            //     setIsLoading(false);
            //   });
            // } else {
            //   setIsLoading(false);
            // }
            setIsLoading(false);
          }
        })  
        .catch(error => {
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, [paymentHash]);

  return (
    <Page withSidenav={false}>
      {isLoading ? <Spinner animation="border" variant="primary" /> : 
      <>

{/* {
  "address": {
    "city": "New York", 
    "country": "US", 
    "created_at": "Tue, 15 Feb 2022 23:50:06 GMT", 
    "first_name": "Ruth", 
    "id": 1, 
    "last_name": "Henderson", 
    "phone_number": "212-555-1234", 
    "street_address": "1234 Main Street", 
    "updated_at": "Tue, 15 Feb 2022 23:50:06 GMT", 
    "zip_code": "10001"
  }, 
  "id": 3, 
  "order_items": [], 
  "order_status": "processed", 
  "tracking_number": null
} */}

        {isPaymentSuccess ?
          <div className="align-center mb-3">          
            <div><h1>Payment Successfully Completed</h1></div>
            <CheckCircle size={300} color="#3aeb34" />
            <hr/>
            <div className="mt-3 mb-3">
              <strong>Payment Action Phrase:</strong>
              <div className="display-5 color-dark-green">{invoicePayment.action_phrase}</div>
            </div>
            <hr/>
            <div className="display-4 text-center">
                <span className="color-dark-green">{Intl.NumberFormat('en-US', 
                  { style: 'currency', currency: 'USD' }).format(invoicePayment.fiat_item_price)}</span>
            </div>
            <div>{parseInt(invoicePayment.amount/1000)} SATS</div>
            <div>{invoicePayment.created_at}</div>
            <div className="success-item-name text-center">{invoicePayment.item_name}</div>
            <div className="success-description text-center">{invoicePayment.description}</div>
            {order && order.order_status==="processed"?
            <div>
              Order status: {order.order_status}
              <OrderPayerInfoForm order={order} paymentHash={paymentHash}/>     
            </div>:<></>}

            {order && order.order_status!=="processed"?
            <div><OrderDetail order={order}/></div>:<></>}

          </div>:
          
          <div>Not Payment Success Yet. Waiting...</div> }

        {isError ?
          <div className="color-red"><h4>Error</h4></div>:
          <></> }          
            
      </>}

    </Page>
  );
};


const PageContent = styled.div`
`;

export default PaymentSuccess;

