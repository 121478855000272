import React, { useRef, useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print'

import ReactTimeAgo from 'react-time-ago'
import { Trash } from "react-feather";
import lodash from "lodash";
import { useNavigate } from 'react-router-dom';
import { MdOutlinePayments } from "react-icons/md";
import { FaBitcoin } from "react-icons/fa";

import { BsLightningFill } from "react-icons/bs";


import { LightningPaymentService } from "../service/LightningPaymentService";
import { AxiosService } from "../service/AxiosService";
import InlineTextInput from "./InlineTextInput";


const PaymentsTable = ({deleteHandler, payments, setPayments, satsRate, showDelete=false}) => {

    const navigate = useNavigate();

    const [error, setError] = useState();

    const handleDeleteByHash = (payment_hash) => {
        console.log("handleDeleteByHash", payment_hash);
        deleteHandler(payment_hash);
    };

    const handleUpdateInvoiceMemo = (payment_hash, memo) => {
        // find the payment by payment_hash and update the memo
        const payment = lodash.find(payments, {payment_hash});
        console.log("payment", payment);
        if (payment) {
            payment.memo = memo;
            LightningPaymentService.update(payment)
            .then((response) => {
                console.log("updated invoice memo", response.data);
                // replace the payment with the updated one in payments
                const newPayments = lodash.map(payments, (payment) => {
                    if (payment.payment_hash === payment_hash) {
                        return response.data;
                    }
                    return payment;
                } );                
                setPayments(newPayments);
            }).catch((err) => {
                let error_model = AxiosService.handleAxiosError(err);             
                setError(`There was an error when attempting to update this invoice. ${error_model.message} reasons: ${error_model.data.reasons}`); 
            });
        }  
    };
           
    return (
        <>
            {error && <div className="alert alert-danger">{error}</div>}
            { payments ?
            <div className="row">
                {payments.map((p, index) => (
                    <div key={p.id}>
                        <div  className="mb-2 grid grid-flow-col grid-rows-3 gap-1 bg-slate-100 rounded">
                            <div className="row-span-3 flex w-full p-1">
                                <div className="flex h-full w-full flex-col justify-between p-1">

                                    <div className="w-full bg-slate-100 text-xs text-">

                                        {new Date(p.created_at).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour:"numeric", minute:"2-digit"})} - <ReactTimeAgo className="italic text-slate-600" date={Date.parse(`${p.created_at} GMT`)} locale="en-US"/>
                                        
                                        </div>
                                    <div className="w-full bg-slate-100 font">
                                        {p.lnurl_payment ? 
                                        <div className="flex flex-col">
                                            <div className="invoice-action-phrase">{p.lnurl_payment.action_phrase}</div>
                                            <div>{p.lnurl_payment.description}</div>
                                            {p.lnurl_payment.comment && <div>comment: {p.lnurl_payment.comment}</div>}
                                            </div> : 
                                            <div className="w-full flex flex-row"><InlineTextInput field_name="payment_memo" text={p.memo} editId={p.payment_hash} editCallback={handleUpdateInvoiceMemo}/></div>}
                                    </div>
                                    <div className="w-full mr-2 flex flex-col">
                                        
                                        <div className={p.amount > 0 ? "font-mono text-green":"font-mono text-2xl font-bold text-red-800"}>
                                            <span className="text-2xl mr-2 font-bold">{Intl.NumberFormat('en-US', {style:'currency', currency: 'USD' }).format(p.fiat_amount)}</span>                                   
                                        </div>

                                        <div className="font-mono font-bold text-gray-500">{parseInt(p.amount)} SATS</div>
                                    </div>            
                                </div>
                            </div>
                            <div className="col-span-2">

                                <div className="flex flex-row justify-end">
                                    {p.status === "PENDING" && <div className="m-1 mr-3 h-7 rounded bg-gray-400 p-1 text-sm font-bold text-white">{p.status}</div>}
                                    {p.status === "PAID" && <div className="m-1 mr-3 h-7 rounded bg-green-500 p-1 text-sm font-bold text-white">{p.status}</div>}
                                    {p.status === "COMPLETED" && <div className="m-1 mr-3 h-7 rounded bg-green-600 p-1 text-sm font-bold text-white">{p.status}</div>}  
                                    {p.status === "CONFIRMED" && <div className="m-1 mr-3 h-7 rounded bg-blue-600 p-1 text-sm font-bold text-white">{p.status}</div>}                       
                                </div>

                            </div>
                            <div className="p-1 col-span-2 row-span-2 bg-green-400 flex justify-end">
                                <div className="flex flex-col justify-end">
                                    <div className="flex"> 
                                        {p.withdraw_voucher_id && <div className="hover:text-blue-500 mr-1 cursor-pointer text-2xl" onClick={()=>navigate(`/voucher/${p.withdraw_voucher_id}`)}>
                                            <MdOutlinePayments/>
                                        </div>}
            

                                        <div className="hover:text-blue-500 mr-1 cursor-pointer justify-center items-center" onClick={()=>navigate(`/invoices/${p.id}`)}>
                                            {p.is_onchain === 1 ? 
                                                <FaBitcoin className="text-2xl mr-2"/>: 
                                                <BsLightningFill className="text-2xl mr-2"/>}
                                        </div>


                                        
                                        {p.status === "PENDING" && showDelete && <div className="hover:text-blue-500 mr-1 cursor-pointer" onClick={()=>handleDeleteByHash(p.payment_hash)}><Trash/></div>}
                                    </div>                         
                                </div>
                            </div>
                            
                        </div>

                    </div>))}
            </div>  




            : <><h3>No payments.</h3></>}

        </>
    )
}

export default PaymentsTable;