import React from 'react'
import {
    Row, Col, Button, Dropdown, DropdownButton
} from 'react-bootstrap';


const NewPagination = ({pageInfo, pageMeta, handlePageChange, fetchPage}) => {

    let nextPage = () => {
        console.log("next page");
        fetchPage({page_size:pageInfo.page_size, page:pageMeta.next_page_number});
    }

    let prevPage = () => {
        console.log("prev page");
        fetchPage({page_size:pageInfo.page_size, page:pageMeta.prev_page_number});
    }



    // current_page_number: 1
    // has_next_page: true
    // has_prev_page: false
    // next_page_number: 2
    // next_page_url: "/product?page=2&page_size=3"
    // offset: 0
    // prev_page_number: 1
    // prev_page_url: "/product?page=1&page_size=3"
    // requested_page_size: 3
    // total_items_count: 7
    // total_pages_count: 3
    return (

        <>
        {pageInfo && 
        <Row id='pagination'>
            <Col xs={12} sm={12} md={4} className='col1'>
                <DropdownButton
                    onSelect={(e) =>{handlePageChange(e)}}
                    className="color-black m-1"
                    variant="outline-dark"
                    id="dropdown-basic-button"
                    title="Page Size">
                    {pageInfo.page_size === 5 ? <Dropdown.Item eventKey="5" active>5</Dropdown.Item> :  
                      <Dropdown.Item eventKey="5">5</Dropdown.Item>}
                    {pageInfo.page_size === 10 ? <Dropdown.Item eventKey="10" active>10</Dropdown.Item> :  
                      <Dropdown.Item eventKey="10">10</Dropdown.Item>}
                    {pageInfo.page_size === 25 ? <Dropdown.Item eventKey="25" active>25</Dropdown.Item> :
                      <Dropdown.Item eventKey="25">25</Dropdown.Item>}
                    {pageInfo.page_size === 50 ? <Dropdown.Item eventKey="50" active>50</Dropdown.Item> :
                      <Dropdown.Item eventKey="50">50</Dropdown.Item>}

                  </DropdownButton>
            </Col>
            <Col xs={12} sm={12} md={4} className='text-sm col2'>total items: {pageMeta.total_items_count} | page: {pageMeta.current_page_number} of {pageMeta.total_pages_count}</Col>
            <Col xs={12} sm={12} md={4} className='col3'>
                {pageMeta.has_prev_page && <Button className="m-2" variant='blue' onClick={()=>prevPage()}>previous</Button>}
                {pageMeta.has_next_page && <Button className="m-2" variant='blue' onClick={()=>nextPage()}>next</Button>}                  
            </Col>
        </Row>
        }</>      
    );
};

export default NewPagination;
