import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Page from "../components/Page";
import InvoicePayment from "../components/InvoicePayment";

import { balToSats, balConvert, WalletService } from "../service/WalletService";

// this is not used anymore
const SendBolt11 = ({ useStore }) => {
  //   const { walletInfo, userCurrency, userLang } = useStore();
  const { userCurrency, userLang, satsRate, effectiveBalanceMSats, 
    getVouchersUnspent, setEffectiveBalanceMSats, 
    unpaidVoucherSats  } = useStore();

  const [walletInfo, setWalletInfo] = useState(null);
  useEffect(() => {
    WalletService.getWallet().then((res) => {
      setWalletInfo(res.data);
    });
    // let wi = useStore.getWalletInfo();
    // setWalletInfo(wi);
    // console.log("useEffect SendBolt11 use store", useStore);
  }, [useStore]);

  return (
    <Page useStore={useStore}>
      <div className="p-6">
        <h1>Send SATs via Bolt 11 Invoice</h1>
        {effectiveBalanceMSats && 
        <div className="flex flex-row justify-start">
                <div>Available Balance Fiat: {Intl.NumberFormat(userLang,
                      {style:'currency', currency: userCurrency }).format(balConvert(satsRate, effectiveBalanceMSats).fiat)} {userCurrency}
                </div>
                <div className="ml-4">Available Balance SATs: {balConvert(satsRate, effectiveBalanceMSats).sats}</div>
        </div>}
        {walletInfo ? (
          <InvoicePayment balanceSats={balToSats(walletInfo.balance)} />
        ) : (
          <Spinner animation="border" />
        )}
      </div>
    </Page>
  );
};

export default SendBolt11;
