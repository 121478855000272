import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Eye, Slash } from "react-feather";
import ReactTimeAgo from 'react-time-ago'
import lodash from 'lodash';


import { satsConvert } from "../service/WalletService";
import InlineTextInput from "./InlineTextInput";
import { VoucherService } from "../service/VoucherService";
import { AxiosService } from "../service/AxiosService";


const VouchersTable = ({handleInvalidate, showInvalidate, vouchers, setVouchers, satsRate}) => { 
    const navigate = useNavigate();
    const [error, setError] = useState();

    const handleUpdateMemo = (unique_hash, title) => {
        console.log("handleUpdateMemo", unique_hash, title);
        // find the voucher by voucher_hash and update the memo
        const voucher = lodash.find(vouchers, {unique_hash});
        console.log("voucher", voucher);
        if (voucher) {
            voucher.title = title;
            VoucherService.update(voucher)
            .then((response) => {
                console.log("updated invoice memo", response.data);
                // replace the voucher with the updated one in vouchers
                const newVouchers = lodash.map(vouchers, (voucher) => {
                    if (voucher.unique_hash === unique_hash) {
                        return response.data;
                    }
                    return voucher;
                } );                
                setVouchers(newVouchers);
            }).catch((err) => {
                let error_model = AxiosService.handleAxiosError(err);             
                setError(`There was an error when attempting to update this invoice. ${error_model.message} reasons: ${error_model.data.reasons}`); 
            });
        }  
    };
           

    return (
        <>    
        {vouchers ?
        <div className="row">

            {vouchers.map((v) => (<div key={v.id} className="mb-2 grid grid-flow-col grid-rows-3 gap-1 bg-slate-100 rounded">
                <div className="row-span-3 flex w-full p-1">
                <div className="flex h-full w-full flex-col justify-between p-1">
                    <div className="w-full bg-slate-100 text-xs text-">

                        {new Date(v.created_at).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour:"numeric", minute:"2-digit"})} -
                        <ReactTimeAgo className="italic text-slate-600" date={Date.parse(`${v.created_at} GMT`)} locale="en-US"/>

                        
                    </div>
                    <div className="w-full bg-slate-100 font">
                        {v.lnurl_voucher ? 
                        <div className="flex flex-col">
                            <div className="invoice-action-phrase">{v.lnurl_voucher.action_phrase}</div>
                            <div>{v.lnurl_voucher.description}</div>
                            {v.lnurl_voucher.comment && <div>comment: {v.lnurl_voucher.comment}</div>}
                            </div> : 
                        <div>{setVouchers ? <>
                            <InlineTextInput field_name="payment_memo" text={v.title} editId={v.unique_hash} editCallback={handleUpdateMemo}/>
                        </> : <>{v.title}</>}</div>}
                    </div>
                    <div className="w-full mr-2 flex flex-col">
                        <div className="font-mono text-2xl font-bold text-green">{Intl.NumberFormat('en-US', {style:'currency', currency: 'USD' }).format(satsConvert(satsRate, parseInt(v.max_withdrawable)).fiat)} USD</div>
                        <div className="font-mono font-bold text-gray-500">{parseInt(v.max_withdrawable)} SATS</div>
                    </div>            
                </div>
                </div>
                <div className="col-span-2"> 

                    <div className="flex flex-row justify-end">
                        {v.status === "SPENT" && <div className="m-1 mr-3 h-7 rounded bg-red-500 p-1 text-sm font-bold text-white">{v.status}</div>}
                        {v.status === "INVALID" && <div className="m-1 mr-3 h-7 rounded bg-gray-500 p-1 text-sm font-bold text-white">{v.status}</div>}                 
                        {v.status === "VALID" && <div className="m-1 mr-3 h-7 rounded bg-green-500 p-1 text-sm font-bold text-white">{v.status}</div>}
                        {v.status === "REQUESTED" && <div className="m-1 mr-3 h-7 rounded bg-orange-500 p-1 text-sm font-bold text-white">{v.status}</div>}                         
                    </div>

                </div>
                <div className="p-1 col-span-2 row-span-2 bg-green-400 flex justify-end">
                    <div className="flex flex-col justify-end">
                        <div className="flex">                               
                            <div className="hover:text-blue-500 mr-1 cursor-pointer" onClick={()=>navigate(`/voucher/${v.id}`)}>
                                <Eye/></div>
                            {((v.status === "VALID" | v.status === "REQUESTED") && showInvalidate) ? 
                                <div className="hover:text-blue-500 mr-1 cursor-pointer" onClick={(e)=>handleInvalidate(v.id)}>
                                <Slash/></div>:
                            <></>}
                        </div>                         
                    </div>
                </div>
            </div>))}
        </div>:
        <div className="row">
            <h3>No vouchers.</h3>
        </div>

        }
        </>
    );
}



export default VouchersTable;