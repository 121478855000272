import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
//import { withLocale } from "react-easy-localization";
import QRCode from "react-qr-code";

import Page from "../../components/Page";

import { WalletService, balConvert } from "../../service/WalletService";
import { LightningPaymentService } from "../../service/LightningPaymentService";
import { Spinner } from "react-bootstrap";

import "./style.css";

const DrainWallet = ({ i18n, languageCode, changeLanguage }) => {
  const [walletInfo, setWalletInfo] = useState();
  const [satsRate, setSatsRate] = useState();
  const [invoice, setInvoice] = useState();

  useEffect(() => {
    if (satsRate) {

      WalletService.getWallet().then((wallet) => {
        let wallet_data = wallet.data;
        setWalletInfo(wallet_data);
        console.log("walletInfo", wallet_data);

        // 10 sats for fees
        let amount_sats = balConvert(satsRate, wallet_data.balance).sats - 10;
  
        LightningPaymentService.createSendVoucher(amount_sats, `rapaygo POS drain wallet voucher ${wallet_data.id}`)
        .then(invoice => {     
          let ival = Object.assign({}, invoice.data);
          console.log("invoice created", ival);
          setInvoice(ival);
        });    
      });

    }

  }, [satsRate]);

  useEffect(() => {
    WalletService.getSatsQuote("USD").then((rate) => {
      setSatsRate(rate.data);
    });  
  }, []);


  return (
    <Page>
      <div className="text-center w-100"><h1>Withdraw Funds</h1></div>
      {walletInfo ? (
        <div className="drain-wallet-section text-center w-50" id="drain-qr">
          <Alert variant="warning">
            This is private. Dont show it to anyone. <strong>The below 
              amount will be drained from your wallet by scanning 
              this invoice.</strong>
          </Alert>
          {walletInfo && satsRate ? 
              <div>
                <div>
                  <span className="sats-number-wallet">
                    {balConvert(satsRate, walletInfo.balance).sats} SATS
                  </span>
                </div>
                <div className="fiat-number-wallet">
                {Intl.NumberFormat('en-US', 
                    {style:'currency', currency: 'USD' }).format(balConvert(satsRate, walletInfo.balance).fiat)}
                </div>
                <div className="drain-wallet-qr">
                  {invoice ? 
                  <QRCode
                      size={256}
                      className="qr-drain mb-3"
                      value={invoice.lnurl}
                  />:<></>}
                </div>
              </div>: 
              <Spinner animation="border" />}
        </div>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default DrainWallet;
