import jwtdecode from 'jwt-decode';
import moment from 'moment';
import Axios from 'axios';
import { backendBaseUrl, deploymentEnv } from '../env';
// import AsyncLock from 'async-lock';
import {AxiosService} from './AxiosService';


Axios.defaults.withCredentials = false; 

export const AuthenticationService = {
    updatePassphrase(oldPassPhrase, newPassPhrase){
      let updateModel = {oldPassPhrase, newPassPhrase}; 
      return AxiosService.put(`/auth/passphrase`, updateModel);     
    },
    sendResetEmail(email){
      let updateModel = {email}; 
      return AxiosService.post(`/auth/sendreset`, updateModel);     
    },
    loginWallet(username, pass_phrase, type="wallet.own"){
      return AxiosService.post(`/auth/access_token`, {username, pass_phrase, type});
    },
    verifyPassphrase(wallet_phrase){
      return AxiosService.post(`/auth/verify`, {wallet_phrase});
    },


};


// const refreshLock = new AsyncLock();

export const isBrowser = () => typeof window !== 'undefined';

export const disconnect = () => {
  window.localStorage.removeItem('rapaygoWallet');
};

export const setUser = (user) => {
  console.log('set user', user);
  AxiosService.setUser(user);
  window.localStorage.setItem('rapaygoWallet', JSON.stringify(user));
};

export const getUser = () => {
  if (isBrowser() && window.localStorage.getItem('rapaygoWallet')) {
    let wallet = JSON.parse(window.localStorage.getItem('rapaygoWallet'));
    // console.log(wallet);
    return wallet;
  } else {
    // console.log('cant get wallet');
    return {};
  }
};

/*
{ user, password, token }
*/
export const getAccessTokenInfo = (userInfo, caller = 'default') => {

  if(userInfo.access_token !== undefined){
    const token = userInfo.access_token;

    // get the decoded payload and header for kid
    const decoded_header = jwtdecode(token, { header: true });
    const decoded_payload = jwtdecode(token);

    let expirationMoment = moment(`${decoded_payload.exp}`, 'X');
    let active = moment(`${decoded_payload.exp}`, 'X').isAfter(moment.utc());

    return {
      header: decoded_header,
      payload: decoded_payload,
      expirationDate: expirationMoment,
      active: active,
    };

  } else {
    return {
      active: false
    };

  }
  
};

export const getAccessToken = (code, state) => {
  return Axios.post(`${backendBaseUrl}/auth/access_token`, {
    env: `${deploymentEnv}`,
    code: code,
    state: state,
  }).then((res) => {
    setUser(res.data.result);
    return res;
  });
};

export const refreshToken = () => {
  console.log('refreshing tokens');

  if(getUser().refresh_token !== undefined){
    return Axios.post(`${backendBaseUrl}/auth/refresh_token`, {
      env: `${deploymentEnv}`,
      refresh_token: getUser().refresh_token,
    }).then((res) => {
      console.log('refresh token response', res);
      const { access_token, refresh_token } = res.data;
      setUser({ ...getUser(), access_token, refresh_token });
      return res;
    });
  } else {
    // remove the user
    setUser(null);
  }
};


// Axios.interceptors.request.use((config) => {
//   if (
//     config.url.endsWith('/refresh_token') ||
//     config.url.endsWith('/access_token') ||
//     config.url.endsWith('/version')
//   ) {
//     // Without this, calling refresh_token will trigger infinite recursion
//     return config;
//   }
//   return refreshLock.acquire('', () => {
//     const info = getAccessTokenInfo(getUser());
//     if (info.expirationDate.isBefore(moment.utc().add(1, 'minutes'))) {
//       return refreshToken().then(() => config);
//     }
//     return config;
//   });
// });

