import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import Page from "../../components/Page";
import {HybridPaymentItemDetails} from "../../components/PaymentItemDetails"
import {ProductService} from '../../service/ProductService'

//import { withLocale } from "react-easy-localization";
import { Spinner,  Row, Col, Breadcrumb } from "react-bootstrap";

import "./style.css";

const ProductViewer = ({ useStore }) => {

  const { pathname } = useLocation();
  const [paymentItemId] = useState(parseInt(pathname.replace("/item/details/", "")));

  const navigate = useNavigate();

  const [paymentItem, setPaymentItem] = useState();

  useEffect(() => {
    console.log("useEffect", paymentItemId)
    ProductService.getById(paymentItemId).then(r => {
      console.log(r.data);
      setPaymentItem(r.data);
    });
      
  }, [paymentItemId]); 
  
  return (
    <Page useStore={useStore}>
      <div className="p-4 flex flex-col">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="#" onClick={()=>navigate("/items")}>Product</Breadcrumb.Item>
            <Breadcrumb.Item active>View Product Item</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>{ paymentItem ? <HybridPaymentItemDetails paymentItemId={paymentItem.id}/>:
          <Spinner animation="border"/> }</div>

      </div>      
    </Page>
  );
};

export default ProductViewer;