import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { getUser } from "../service/auth";

import { AiOutlineDashboard } from "react-icons/ai";

import { GiArchiveRegister } from "react-icons/gi";
import { BsBoxSeam, BsLightningChargeFill } from "react-icons/bs";
import { MdOutlinePayments } from "react-icons/md";
import { SiWoo } from "react-icons/si";


import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from "react-icons/tb";
import { FaCashRegister, FaMoneyCheck, FaListOl, FaBitcoin } from "react-icons/fa";
import { ImUserTie } from "react-icons/im";
import {FiShoppingCart} from "react-icons/fi";
import {MdOutlineLocalPostOffice} from "react-icons/md";

const Sidebar = ({ useStore }) => {

  const {  walletInfo, userCurrency, userLang } = useStore();

  const {
    selectedPath,
    setSelectedPath
  } = useStore();

  const navigate = useNavigate();
  const [user] = useState(getUser());
  const [collapsed, setCollasped] = useState(false);

  const freeNavItems = [
    { label: "Dashboard", path: "/wallet", icon: <AiOutlineDashboard/> },
    { label: "Ledger", path: "/invoices" , icon: <GiArchiveRegister/>},  
  ];

  const ingressNavItems = [  
    { label: "Cart", path: "/cart" , icon: <FiShoppingCart/>},
    { label: "Point Of Sale", path: "/pos/receive" , icon: <FaCashRegister/>},
    { label: "Products", path: "/items", icon: <BsBoxSeam/> },
    { label: "E-Commerce", path: "/ecommerce" , icon: <SiWoo/>}, 
  ];

  const egressNavItems = [
    { label: "Send (Voucher)", path: "/pos/send" , icon: <MdOutlinePayments/>},
    { label: "Vouchers", path: "/vouchers", icon: <FaMoneyCheck/> },
    { label: "Bolt11", path: "/send/bolt11", icon: <BsLightningChargeFill/> },
    // { label: "On Chain", path: "/send/onchain", icon: <FaBitcoin/> },    
  ];

  const merchantNavItems = [
    { label: "Orders", path: "/orders", icon: <FaListOl/> },
    { label: "Customers", path: "/customers", icon: <ImUserTie/> },
    { label: "Addresses", path: "/addresses", icon: <MdOutlineLocalPostOffice/> },   
  ];

  const sendEnabledNavItems = [
  ];

  const adminNavItems = [
    { label: "Users", path: "/users" },
    { label: "Subscriptions", path: "/subscriptions" },
    { label: "Settings", path: "/settings" },
  ];

  const handleLinkClick = (path) => (e) => {
    e.preventDefault();
    setSelectedPath(path);
    navigate(path);
  };

  const handleToggleCollapse = () => {
    console.log("handleToggleCollapse");
    if(collapsed){
      setCollasped(false);
    } else {
      setCollasped(true);
    }
  };


  const makeNavLinks = (navItems, title) => {
    return (
      <div className={!collapsed ? ' bg-slate-800 rounded w-screen md:w-48 relative sm:relative flex flex-wrap m-1' : 
      'bg-slate-800 rounded w-fill md:w-48 relative sm:relative sm:flex sm:flex-wrap md:hidden m-1'}>
        {title && <div className="ml-2 text-gray-200 font-bold text-xl">{title}</div>}
        {navItems.map(({ label, path, icon }) => (         
        <Nav.Link
          key={path}
          eventKey={path}
          className={`m-1 rounded-md hover:bg-blue-600 sidebar-nav-link ${
            selectedPath === path ? "bg-gray-600" : ""
          }`}
          onClick={handleLinkClick(path)}
        >
          <div className="flex">{icon}<span className="nav-text">{label}</span></div>                
          
        </Nav.Link>
      ))}</div>
    )
  }

  return (
    <>
      <div className={!collapsed ? 'w-full md:w-52 xl:w-56 relative sm:relative md:flex-col flex-row' : 
        'w-full md:w-10 relative sm:relative md:flex-col flex-row'}>
        <div className="sm:h-1 sm:invisible md:h-8 md:visible flex flex-row justify-end">
          <div className="cursor-pointer m-1 text-2xl hover:text-blue-200" onClick={()=>handleToggleCollapse()}>
          {!collapsed ? <TbLayoutSidebarLeftCollapse/>:<><TbLayoutSidebarLeftExpand/></>}
          </div>
        </div>
        {makeNavLinks(freeNavItems, "")}
        {makeNavLinks(ingressNavItems, "Receive Payment")}
        {makeNavLinks(egressNavItems, "Send Payment")}
        { walletInfo.subscription_type === 'MERCHANT' && makeNavLinks(merchantNavItems, "For Merchants")}

      </div>
    </>
  );
};



export default Sidebar;
