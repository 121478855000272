import React, { useState, useEffect  } from 'react';
import { Breadcrumb } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { FaBitcoin } from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";


import Page from "../../components/Page";
import {OrderPaymentDetail} from '../../components/InvoiceDetail';
import OrderPaymentsTable, {OrderPaymentsTableLine} from "../../components/OrderPaymentsTable";
import {OrderService} from '../../service/OrderService'
import {LightningPaymentService} from '../../service/LightningPaymentService'
import { AxiosService } from '../../service/AxiosService';
import OrderDetail from '../../components/OrderDetail';




import "./style.css";
/**
 *  an order is a bundle of products and quantities that can be paid for with a single payment
 *  via an LNURL, can be created from a cart, or from a list of products
 * 
 * @param {*} props
 * @returns 
 */

const Order = ({ useStore }) => {
    const navigate = useNavigate();

    const { satsRate, userCurrency, walletInfo } = useStore();

    const { id } = useParams();
    const [order, setOrder] = useState();
    const [error, setError] = useState();
    // const [orderPayment, setOrderPayment] = useState();
    const [invoice, setInvoice] = useState();
    // const [invoices, setInvoices] = useState();
    const [payments, setPayments] = useState();
    const [countdown, setCountdown] = useState(0);
    const [timerId, setTimerId] = useState();
    const [donePolling, setDonePolling] = useState(true);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
      if(order == null) {
        fetchOrder();
      }

      // if(invoice != null && order != null && !donePolling) {
      //   setTimerId(setTimeout(() => {
      //       LightningPaymentService.getById(invoice.id)
      //       .then((r) => {     
      //         setInvoice(r.data);
      //         setCountdown(countdown - 10000);   
      //         if(countdown <= 0) {
      //           setDonePolling(true);
      //         }
      //       }).catch((err) => {
      //         setError(err.message);
      //       });

      //   }, 10000));
      // }
      
    } , [countdown, donePolling, invoice, id, order]);


    let fetchOrder = () => {
      setIsLoading(true);
      console.log("fetching order", id);
      OrderService.getById(id).then((cr) => {
        setOrder(cr.data);
        fetchOrderPayments(cr.data.id);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
    };

    let fetchOrderPayments = (orderId) => {
      setIsLoading(true);
      console.log("fetching order payments", orderId);
      OrderService.getPaymentsByOrder(orderId).then((cr) => {
        setPayments(cr.data);
        setIsLoading(false);
      }).catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
    };

    const makeLightningPaymentRequest = (order) => {
      let amountSats = parseInt(getOrderTotal(order)*satsRate.rate[1]);
      let orderMemo = `Order #${order.id} - ${order.customer.company_name} - ${order.items.length} items}`;
      OrderService.createLightningOrderPayment(order.id, amountSats, orderMemo)
      .then(orderPayment => {
          let n_p = [...payments]
          n_p.push(orderPayment.data);
          setPayments(n_p);
      }).catch(err => { 
          // setError(err.message);
          let error_model = AxiosService.handleAxiosError(err);
          console.log("error", error_model);
          if(error_model.data) {
            setError(`${error_model.message} ${error_model.data.reasons.join(', ')}`);
          } else {
            setError(`${error_model.message}`);
          }
          
      });
    };

    const makeOnChainPaymentRequest = (order) => {
      console.log("make on chain payment request", order);
      let amountSats = parseInt(getOrderTotal(order)*satsRate.rate[1]);
      let orderMemo = `Order #${order.id} ${amountSats} - ${order.customer.company_name} - ${order.items.length} items`;
      console.log("make on chain payment request", amountSats, orderMemo);
      OrderService.createOnchainOrderPayment(order.id, amountSats, orderMemo)
          .then(orderPayment => {
            let n_p = [...payments]
            n_p.push(orderPayment.data);
            setPayments(n_p);
            console.log("onchain order payments", n_p);
          }).catch(err => { 
              // setError(err.message);
              let error_model = AxiosService.handleAxiosError(err);
              console.log("error", error_model);
              if(error_model.data) {
                setError(`${error_model.message} ${error_model.data.reasons.join(', ')}`);
              } else {
                setError(`${error_model.message}`);
              }
          });
    };


    const getOrderTotal = (order) => {
      let total = 0;
      order.items.forEach(orderLine => {
          total += getLinePrice(orderLine);
      });
      return total;
    };

    const getLinePrice = (item) => {
        return item.payment_item.price * item.quantity;
    };

    
    return (
      <Page useStore={useStore}>
          <div className="p-4 row">
              <div>
                <Breadcrumb>
                  <Breadcrumb.Item href="#" onClick={()=>navigate("/orders")}>Orders</Breadcrumb.Item>
                  <Breadcrumb.Item active>{order && <>Order #{order.id}</>}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className='flex flex-row justify-between'>
                <h1 className="text-center">Order #{order && order.id}</h1>
                <div className="flex flex-row">
                  {order && <div className="flex flex-row justify-end">
                      {order.payment_status === "CREATED" && <div className="m-1 mr-3 h-7 rounded bg-gray-400 p-1 text-sm font-bold text-white">{order.payment_status}</div>}
                      {order.payment_status === "INVOICED" && <div className="m-1 mr-3 h-7 rounded bg-orange-600 p-1 text-sm font-bold text-white">{order.payment_status}</div>}
                      {/* {payments && <div className="m-1 mr-3 h-7 rounded bg-orange-600 p-1 text-sm font-bold text-white">INVOICED</div>} */}
                      {/* {p.status === "PAID" && <div className="m-1 mr-3 h-7 rounded bg-green-500 p-1 text-sm font-bold text-white">{p.status}</div>}
                      {p.status === "COMPLETED" && <div className="m-1 mr-3 h-7 rounded bg-green-600 p-1 text-sm font-bold text-white">{p.status}</div>}  
                      {p.status === "CONFIRMED" && <div className="m-1 mr-3 h-7 rounded bg-blue-600 p-1 text-sm font-bold text-white">{p.status}</div>}*/}
                  </div>}
                </div>
              </div>
              
              
              {error && <div className="alert alert-danger" role="alert">{error}</div>}
              <div>
                {order &&                
                  <div>
                    <div className="w-full flex justify-end">
                        { (!payments || payments.length === 0) && order && order.payment_status === "CREATED" && <>                       
                        <button className="btn-common-blue" onClick={()=>makeLightningPaymentRequest(order)}><BsLightningFill className='mr-1'/>Add Lightning Payment</button>
                        <button className="btn-common-blue" onClick={()=>makeOnChainPaymentRequest(order)}><FaBitcoin className='mr-1'/> Add On-Chain Payment</button>
                        </>}                                     
                    </div>

                    <OrderDetail walletInfo={walletInfo} order={order} satsRate={satsRate}/>
         
                    {payments && payments.length > 0 ? 
                    <div className='mt-3'>
                      <h5>Order Invoices</h5>
                      <OrderPaymentsTable walletInfo={walletInfo} order={order} satsRate={satsRate} payments={payments} setPayments={setPayments} showDelete={false}/>            
                    </div>:
                    <></>}
                    
                  </div>
                }
              </div>

          </div>
      </Page>
    );
};

export default Order;
