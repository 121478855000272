import styled from "@emotion/styled";
import { Container, Row, Col } from "react-bootstrap";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { WordLogo } from "./PageLayout";

const Wrapper = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  flex-grow: 1;
`;

const PaymentPage = ({children }) => {

  return (

    <>
      <div id="container" className="flex min-h-screen flex-col">
        <div id="main" className="flex flex-grow flex-col md:flex-row">
            <div className="flex-grow">{children}</div>
        </div>
        <div id="footer" className="p-0 bg-black color-white flex justify-center" onClick={()=>window.location = 'https://rapaygo.com'}>
          <div className="hover:bg-slate-800  m-1 p-1 flex rounded justify-center items-center">
            <div className="mr-1 text-sm items-center underline">payments by</div> <WordLogo className="text-lg"/>
          </div>
         
        </div> 
      </div>   
    </>



    // <Wrapper>
    //   <Container fluid>
    //     <Row className="page-row">
    //       {withSidenav && (
    //         <Col className="sidebar-col" xs={12} md={3}>
    //           <Sidebar />
    //         </Col>
    //       )}
    //       <Col xs={12} md={withSidenav ? 9 : 12} className={withSidenav? contentClass: fullContentClass}>
    //         <ContentWrapper>{children}</ContentWrapper>
    //       </Col>
    //     </Row>
    //   </Container>
    // </Wrapper>
  );
};

export default PaymentPage;
