import {AxiosService} from "./AxiosService";

/**
 * THESE ARE PRODUCTS 
 */
export const ProductService = {
    fetchPage(query = {location: '/product', page: 1, page_size: 15}) {
        return AxiosService.fetchPage(query.location, query);
    },
    getBySlug(slug) {
        if (typeof slug !== "string") {
            throw new Error(
                "Slug must be a string"
            );
        }
        return AxiosService.get(`/product/by_slug/${slug}`);
    },
    ///product/by_id/<payment_item_id>
    getById(id) {
        console.log("getById", id);
        return AxiosService.get(`/product/${id}`);
    },
    createPaymentItem(formData) {
        return AxiosService.post(`/product`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    updatePaymentItem(formData) {
        return AxiosService.put(`/product`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    deletePaymentItem(paymentItemId) {
        return AxiosService.del(`/product/${paymentItemId}`);
    },
    getPaymentItems (){
        return AxiosService.get(`/product`);
    },
    getPaymentItemsPaged (pageInfo={page: 1, page_size: 15}){
        return AxiosService.getPage(`/product`, pageInfo);
    },
    uploadProductsCSVFile(formData) {
        return AxiosService.post(`/product/csv`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    }

};



