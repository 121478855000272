import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import lodash from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import Page from "../../components/Page";
import { AddressService } from '../../service/AddressService';
import AddressesTable from "../../components/AddressesTable";
import AddressForm from "../../components/AddressForm";
import HelpAlert from "../../components/HelpAlert";
import NewPagination from '../../components/NewPagination';
import ConfirmModal from '../../components/ConfirmModal';


import "./style.css";


const Addresses = ({ useStore }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [addresses, setAddresses] = useState();
  const [addressesPage, setAddressesPage] = useState({page: 1, page_size: 15});
  const [addressesPageMeta, setAddressesPageMeta] = useState({total_items_count:15, current_page_number:1, total_pages_count:1});
  const [showForm, setShowForm] = useState(false);

  const [address, setAddress] = useState();

  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  
  useEffect(() => {
    setIsLoading(true);
    fetchAddressesPage(addressesPage);
    setIsLoading(false);
  } , [address]);

  const fetchAddressesPage = (cPage) => { 
    setIsLoading(true);
    AddressService.getPaged(cPage).then((cr) => {
      setAddresses(cr.data.addresses);
      setAddressesPage(cPage)
      setAddressesPageMeta(cr.data.page_meta);
      setIsLoading(false);
    });
  };

  const deleteItem = (addressId) => {
    console.log("deleteItem", addressId);
    setDeleteId(addressId);
    setShowModal(true);
  };

  const handleConfirmDeleteItem = () => {
    console.log("deleteItem", deleteId);
    AddressService.delete(deleteId)
    .then((cr) => {
      // setInvalidateId(addressId);
      // copy the array of addresses
      let newAddresses = [...addresses];
      // find the index of the address to delete
      let index = newAddresses.findIndex((a) => a.id === deleteId);
      // remove the address from the array
      newAddresses.splice(index, 1);
      // update the state
      setAddresses(newAddresses);
      setShowModal(false);
    }).catch((error) => {
      console.log("error", error);
    });
  };


  const setEditItem = (addressId) => {
    console.log("setEditItem ID", addressId);
    AddressService.getById(addressId)
    .then((cr) => {
      console.log("setEditItem", cr);
      if(cr.data){     
        setAddress(cr.data);
        setShowForm(true);
      }
    });    
  };

  // should trigger reload
  const handlePageChange = (e) => {
      setAddressesPage({page: 1, page_size: parseInt(e)});
  } 

  const handleNew = () => {
    setShowForm(true);
    console.log("handleNew");
  };

  const handleCancel = () => {
    setShowForm(false);
    setAddress(null);
  };

  const handleCreated = (address) => {
    const addr = {
      ...address
    };

    setAddresses([...addresses, addr]);    
    // setShowForm(false);
    setAddress(null);
  };

  const handleUpdated = (address) => {
    let cAddresses = [...addresses];

    //replace the address in the array
    cAddresses = cAddresses.map((a) => {
      if(a.id === address.id){
        return address;
      }
      return a;
    });

    setAddresses(cAddresses);

    setShowForm(false);
    setAddress(null);
  };

  const handleCSV = () => {
    AddressService.getAddressesCSV().then((csv) => {
      const blob = new Blob([csv.data], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${uuidv4().replace("-","").substring(0,6)}-address.csv`;
      link.click();
    });
  };




  return (
    <Page useStore={useStore}>
        <div className="p-4 row">
            <h1>Addresses</h1>
            <HelpAlert variant='info' textClass='text-blue' 
                helpLink='/docs/#/addresses'>As a merchant your addresses are locations of either a customer is based or you are using for shipping.</HelpAlert>            
            <div className="m-1 flex flex-row w-full justify-end">
                {(address == null || address.id == null) && showForm === false && <button className="btn-common-blue" onClick={()=>handleNew()}>Add New</button>}
                <button className="btn-common-blue" onClick={()=>handleCSV()}>Download CSV</button>
            </div>
            <div className='mb-3'>
              <AddressForm address={address} setAddress={setAddress} showForm={showForm} handleCancel={handleCancel} handleCreated={handleCreated} handleUpdated={handleUpdated}/>
            </div>
            <AddressesTable addresses={addresses} setEditItem={setEditItem} deleteItem={deleteItem}/>

            <hr/>
            {addressesPage && <NewPagination pageInfo={addressesPage} pageMeta={addressesPageMeta} handlePageChange={handlePageChange} fetchPage={setAddressesPage}/>} 


        </div>
        <ConfirmModal showModal={showModal} actionName={'Delete Address'} actionDescription={'delete this address'} actionCallback={handleConfirmDeleteItem}/>
    </Page>
  );
};

export default Addresses;
