import React, { useState, useEffect  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Modal, Breadcrumb } from "react-bootstrap";


import Page from "../../components/Page";
import {CustomerService} from '../../service/CustomerService'
import {CustomerItem} from "../../components/CustomersTable";
import CustomerForm from "../../components/CustomerForm";
import OrdersTable from "../../components/OrdersTable";

const Customer = ({ useStore }) => {

  const navigate = useNavigate();
  const { satsRate  } = useStore();

  const { id } = useParams();
  const [customer, setCustomer] = useState();
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    console.log("Customer: useEffect", id);
    if(id){
      CustomerService.getById(id)
      .then((cr) => {
        console.log("Customer: useEffect", cr);
        setCustomer(cr.data);
        CustomerService.getOrdersById(id)
        .then((cr) => {
          console.log("orders:", cr.data);
          setOrders(cr.data);
          setIsLoading(false);
        });
      }
      );
    }
  }, [id]);

  return (
    <Page useStore={useStore}>
        <div className="p-4 row">
            <Breadcrumb>
                <Breadcrumb.Item href="#" onClick={()=>navigate("/customers")}>Customers</Breadcrumb.Item> 
                {customer ? <Breadcrumb.Item active>{customer.company_name}</Breadcrumb.Item> : <Breadcrumb.Item active>Cart Checkout</Breadcrumb.Item>}
            </Breadcrumb>
            {error && <Alert variant="danger">{error} </Alert>}
            <h1>Customer</h1> 
            {customer && !isEdit && <CustomerItem customer={customer} isEditable={false} showView={false}/>}
            {customer && isEdit && <CustomerForm customer={customer} />}     
        </div>
        {orders && orders.length>0 ?
        <div className="p-4 row">
            <h2>Customer Orders {orders.length}</h2> 
            <OrdersTable orders={orders} satsRate={satsRate}/>  
        </div>:<div className="p-4 row"><h2>No orders yet.</h2></div>}
        
    </Page>
  );
};

export default Customer;
