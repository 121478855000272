import React from "react";
import { useNavigate } from 'react-router-dom';


export const CustomerItem = ({customer, setEditItem, deleteItem, isPrintable=false, isEditable=true, showView=true}) => {
    const navigate = useNavigate();
    return (<>
            
            <div key={customer.id} className="p-1 mb-1 flex flex-row justify-between border-solid border-b-2 border-gray-300">
                
                <div className="flex flex-col justify-start">
                    <div className="text-slate-800 text-2xl font-bold">{customer.company_name}</div>
                    {customer.contact_address && 
                        <>
                            <div className="leading-tight">{customer.contact_address.first_name} {customer.contact_address.last_name}</div>
                            <div className="leading-tight">{customer.contact_address.street_address} {customer.contact_address.city} {customer.contact_address.state} {customer.contact_address.zip_code}</div>
                        </>
                    }
                    <div className="leading-tight">{customer.company_email}</div>
                </div>
                {!isPrintable && <div className="flex flex-col justify-end">    
                    <div className="flex flex-row justify-end"> 
                        {showView && <div className="btn-common-blue text-xs cursor-pointer" onClick={()=>navigate(`/customer/${customer.id}`)}>view</div>}    
                        {isEditable && <><div className="btn-common-blue text-xs cursor-pointer" onClick={()=>setEditItem(customer.id)}>edit</div>
                        <div className="btn-common-blue text-xs cursor-pointer" onClick={()=>deleteItem(customer.id)}>delete</div></>}
                    </div>
                </div>}
            </div>
    </>);
};

export const CustomersTable = ({customers, deleteItem, setEditItem}) => { 
    return (
        <>    
        {customers ?
        <div className="row">
            {customers.map((c, index) => (
                <CustomerItem key={c.id} customer={c} setEditItem={setEditItem} deleteItem={deleteItem}/>         
            ))}
        </div>:
        <div className="row">
            <h3>No customers.</h3>
        </div>

        }
        </>
    );
}

