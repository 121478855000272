import React, {useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import Ajv from "ajv";

import { AddressService } from '../service/AddressService';
import { CustomerService } from '../service/CustomerService';
import APIKeySelector from './APIKeySelector';
import AddressSelector from './AddressSelector';



const CustomerForm = ({ customer, showForm, handleCancel, handleCreated, handleUpdated }) => {
  
    const [formState, setFormState] = useState({
        'company_name': '', 
        'contact_name': '', 
        'company_email': '',
        'contact_email': '',
        'contact_address_id': -1,
        'phone': '', 
        'notes': '', 
        'sales_rep_pos_key': -1});

    const [addresses, setAddresses] = useState();
    const [addressesPage, setAddressesPage] = useState({page: 1, page_size: 50});
    const [addressesPageMeta, setAddressesPageMeta] = useState({total_items_count:50, current_page_number:1, total_pages_count:1});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [errors, setErrors] = useState([]);
  
    useEffect(() => {
        if(customer){
            console.log("CustomerForm: customer", customer);
            setFormState(customer);
        }
        setIsLoading(true);
        fetchAddressesPage(addressesPage);
        setIsLoading(false);
    } , [customer]);

    let fetchAddressesPage = (cPage) => { 
        setIsLoading(true);
        AddressService.getPaged(cPage).then((cr) => {
            setAddresses(cr.data.addresses);
            setAddressesPage(cPage)
            setAddressesPageMeta(cr.data.page_meta);
            setIsLoading(false);
        });
    };

    const handleInputChange = event => {
        
        console.log("handleInputChange1", event);
        
        let target = event.target
        let value = target.value
        let name = target.name  
        console.log("handleInputChange2", name, value);
        
        //cast to int if needed
        if(['contact_address_id', 'sales_rep_pos_key'].includes(target.name)){
            setFormState((formState) => ({
                ...formState,
                [name]: parseInt(value)
            }));
        } else {
            setFormState((formState) => ({
                ...formState,
                [name]: value
            }));
        }
          
    };  

    const clearForm = () => {
        setFormState({
        'company_name': '', 
        'contact_name': '', 
        'company_email': '',
        'contact_email': '',
        'contact_address_id': -1,
        'phone': '', 
        'notes': '', 
        'sales_rep_pos_key': -1});
    };  

    let renderErrors = (errors) => {
        console.log(errors);
        return errors.map((error, index) => (
            <div key={index}>Invalid {error.instancePath} {error.keyword} {error.message}</div>
        ));
    };

    const formSchema = {
        type: "object",
        properties: {
            company_name:          {type: "string", "nullable": false, minLength: 4, maxLength: 64},
            contact_address_id:    {type: "number", "nullable": false, minimum: 1},
            sales_rep_pos_key:     {type: "number", "nullable": false, minimum: 1},
        },
        required: [ "company_name", "contact_address_id", "sales_rep_pos_key" ],
        additionalProperties: true
    };
  
    const onSaveButton = () => {
        setError(null); 
        console.log("onSaveButton form state", formState);  
         
        // setup formats
        const ajv = new Ajv();
        const validate = ajv.compile(formSchema); //we dont want to validate the images
        const schemaValid = validate(formState);

        // const saveObject = {...formState};

        if (!schemaValid) {
            console.log("validation errors:", validate.errors, formState)
            let allErrors = [];
            allErrors.push(...validate.errors);
            setErrors(allErrors);
        } else {
            // console.log("saving", saveObject);
            if(formState.id){
                CustomerService.update(formState).then((cr) => {
                    console.log("onSaveButton RESPONSE", cr);
                    handleUpdated(cr.data);
                    setSuccess("Customer created successfully");
                }).catch((err) => {
                    console.log("error", err);
                    setError(err.message);
                });            
            } else {    
                CustomerService.create(formState).then((cr) => {
                    console.log("onSaveButton", cr);
                    handleCreated(cr.data);
                    setSuccess("Customer created successfully");
                }).catch((err) => {
                    console.log("error", err);
                    setError(err.message);
                });
            }
        } 

    };
  
    return (
        <>
            <div className={showForm ? "row" : "hidden"}>
                {error && <Alert variant="danger">{error}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}
                {errors && errors.length>0 && <Alert className="flex flex-col" variant="danger">
                    <div className="font-bold">Validation Errors Found {renderErrors(errors)}</div>
                </Alert>}
                <div>
                    {formState.id ? <h3>Edit Customer</h3> : <h3>Add Customer</h3>}
                    {/* <div className='font-monospace text-xs break-all'>{JSON.stringify(formState)}</div> */}
                </div>

                <div className="flex flex-row bg-orange-400">
                    <label className="m-2 block w-full">
                    <span className="text-gray-700">Company Name</span>
                    <input type="text" onChange={(e)=>handleInputChange(e)} name="company_name" className="mt-1 block w-full rounded" placeholder="" value={formState.company_name} />
                    </label>
                </div>
                <div className="flex flex-row bg-orange-400">
                    <label className="m-2 block w-full">
                    <span className="text-gray-700">Company Email</span>
                    <input type="email" onChange={(e)=>handleInputChange(e)} name="company_email" className="mt-1 block w-full rounded" placeholder="" value={formState.company_email}/>
                    </label>
                </div>                

                <div className="flex flex-row">
                    <div className="w-1/2">
                        <label className="m-2 block">
                            <span className="text-gray-700">Company Phone</span>
                            <input type="text" onChange={(e)=>handleInputChange(e)} name="phone" className="mt-1 block w-full rounded" placeholder=""  value={formState.phone}/>
                        </label>
                    </div>

                    <div className="w-1/2 mt-2">
                        <AddressSelector name={'contact_address_id'} addresses={addresses} addressSelected={formState.contact_address_id} onChange={(e)=>handleInputChange(e)} />
                    </div>                    
                </div>     

                <div className="flex flex-row mt-2">
                    <div className="w-full">
                        <label className="block">
                            <span className="text-gray-700 font-bold">Sales Rep Key</span>
                            <APIKeySelector name="sales_rep_pos_key" label="API Key Selection" 
                                onChange={(e)=>handleInputChange(e)} keySelected={formState.sales_rep_pos_key}/>
                        </label>
                    </div>                    
                </div>    
                <div className="flex flex-row mt-2">
                    <div className="mb-3 w-full">
                        <label className="form-label inline-block mb-2 text-gray-700">Notes</label>
                        <textarea
                        className="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="notes"
                        name="notes"
                        rows="3"
                        onChange={(e)=>handleInputChange(e)}
                        value={formState.notes}
                        placeholder="Company notes"
                        ></textarea>
                    </div>
                </div> 

                <div className="flex flex-row justify-end">
                    <button className="btn-common-gray" onClick={()=>handleCancel()}>Cancel</button>
                    <button className="btn-common-blue" onClick={()=>clearForm()}>Clear Form</button>
                    <button className="btn-common-blue" onClick={onSaveButton}>Save Customer</button>
                </div>      
            </div>
 
        </>
    );
  };
  
  
  export default CustomerForm
  
  