import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';

import Countdown from "react-countdown";
import QRCode from "react-qr-code";
import { Slash, CheckCircle } from "react-feather";
import ReactTimeAgo from 'react-time-ago';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { FiCopy, FiPrinter } from "react-icons/fi";
import { TbArrowBack } from "react-icons/tb";

import { satsConvert, fiatConvert } from "../service/WalletService";
import spent_voucher from "../assets/img/qr_voucher_spent.png";
import invalid_voucher from "../assets/img/qr_voucher_invalid.png";
import requested_voucher from "../assets/img/qr_voucher_request.png";

const VoucherDetail = ( { voucher, satsRate, userCurrency, userLang, handleVoucherInvalidate, allowInvalidate=false}) => {

  const navigate = useNavigate();

  const expires = 180000;
  const [countdown, setCountdown] = useState(expires);
  const [copied, setCopied] = useState(false);

  useEffect(() => { 
    setTimeout(() => {
      setCountdown(countdown - 1000);
    }, 1000);
  },[countdown]);
  
  return (
    
    <div className='flex justify-center w-100'>
      { voucher && 
        <div className='rounded bg-gray-200 w-96 p-3'>
            <div className="flex flex-row justify-center">
                {voucher.status === "SPENT" && <div className="m-1 mr-3 h-7 rounded bg-red-500 p-1 text-sm font-bold text-white">{voucher.status}</div>}
                {voucher.status === "INVALID" && <div className="m-1 mr-3 h-7 rounded bg-gray-500 p-1 text-sm font-bold text-white">{voucher.status}</div>}
                
                {voucher.status === "VALID" && <div className="m-1 mr-3 h-7 rounded bg-green-500 p-1 text-sm font-bold text-white">{voucher.status}</div>}
                {voucher.status === "REQUESTED" && <div className="m-1 mr-3 h-7 rounded bg-orange-500 p-1 text-sm font-bold text-white">{voucher.status}</div>}                         
            </div>

            <div className="font-bold text-3xl text-center">SCAN TO RECEIVE</div>
            <div className="text-center text-sm text-gray-500">

                {new Date(voucher.created_at).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour:"numeric", minute:"2-digit"})} -
                <ReactTimeAgo className="italic text-slate-600" date={Date.parse(`${voucher.created_at} GMT`)} locale="en-US"/>
            </div>

            {satsRate && 
            <div>
              <div className="flex justify-center text-4xl font-bold font-monospace text-green-600">
                {Intl.NumberFormat('en-US', 
                  {style:'currency', currency: 'USD' }).format(satsConvert(satsRate, parseInt(voucher.max_withdrawable)).fiat)} USD
              </div>
              <div className="d-flex flex-row justify-content-center sats-number-wallet">
                {voucher.min_withdrawable} SATs
              </div>
              <div className='flex justify-center'>
                  <div className="sats-rate-wallet">1 {userCurrency} = {fiatConvert(satsRate, 1).sats} SATS</div>
              </div>      
            </div>}
            
            {voucher.status === 'VALID' && voucher.uses>voucher.used && 
            <>
            <div className='w-full flex justify-center'>
              <div className="p-2 rounded-xl bg-white flex  items-center">
                <QRCode size={200} value={voucher.lnurl}/>
              </div>               
            </div>
            </>          
            }

            {voucher.status === 'SPENT' &&  voucher.uses==voucher.used && <><img className='img-qr-used' src={spent_voucher}/></> }
            {voucher.status === 'INVALID' && 
              <><img className='img-qr-used' src={invalid_voucher}/></> 
            }
            {voucher.status === 'REQUESTED' && <>
            <div className="justify-content-center">
              <div className='d-flex flex-row justify-content-center'>
                <button className='text-gray-700 hover:text-blue-500 underline'  onClick={()=>window.location="https://rapaygo.freshdesk.com/support/home"}>Contact Support To Make Valid</button></div>
              <div className='d-flex flex-row justify-content-center'><img className='img-qr-used' src={requested_voucher}/></div>
            </div>
            </>}
            <div className='w-full flex justify-center'>
              <div className='text-xs font-monospace text-gray-500'>{voucher.unique_hash}</div>
            </div>
            <div className='flex w-full justify-center m-2 p-2'>

              <div className='grid grid-cols-2'>
                <div className='flex flex-col justify-center'>
                  <div className='mr-2 w-16 h-16 bg-slate-300 rounded-full flex justify-center items-center text-3xl'>
                  {voucher.uses}
                  </div>
                  <div className='w-16 text-center'>uses</div>
                </div>
                <div className='flex flex-col justify-center'>
                  <div className='mr-2 w-16 h-16 bg-slate-300 rounded-full flex justify-center items-center text-3xl'>
                  {voucher.used}
                  </div>
                  <div className='w-16 text-center'>used</div>
                </div>
              </div>

            </div>
            {/* {allowInvalidate && (voucher.status == 'VALID' | voucher.status == 'REQUESTED') && voucher.uses>voucher.used ?  */}
            <div className='flex flex-col w-full justify-center'>
              <div className='flex flex-row w-full justify-center'>
                <button onClick={()=>navigate('/vouchers')} className='hover:underline m-1 p-1 flex justify-center items-center'><TbArrowBack className='mr-1'/>Go Back</button>
                {/* <button className='hover:underline m-1 p-1 flex justify-center items-center'><FiPrinter className='mr-1'/>Print</button> */}
                
                
                
                 {(voucher.status === 'VALID' || voucher.status === 'REQUESTED') && <div className='flex justify-content-center'>
                  <CopyToClipboard text={voucher.lnurl}
                    onCopy={() => setCopied(true)}>
                    <button className='flex m-1 p-1 justify-center items-center'><FiCopy className='mr-1 hover:underline'/>Copy</button>   
                  </CopyToClipboard>{' '}
                  {copied ? <div className='flex mr-1 text-green font-bold items-center'><CheckCircle/>{' '} Copied.</div> : null}
                </div>}

              </div>               
        
              {/* invalidate */}
              <div className='flex flex-row w-full justify-center'>
                {(voucher.status == 'VALID' || voucher.status == 'REQUESTED') === true && <button className='bg-red-600 text-white hover:bg-red-400 btn-common-nocolor' onClick={(e)=>handleVoucherInvalidate(e)}><Slash className='mr-1'/>Invalidate</button>}
              </div>
            </div>
      </div>}
    </div> 
  )

};

export default VoucherDetail;