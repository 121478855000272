import Axios from 'axios';
import { round } from 'lodash';
import {AxiosService} from "./AxiosService";

Axios.defaults.withCredentials = false; 

export const WalletService = {
    getWallet(){
        return AxiosService.get(`/wallet`);     
    },

    getApiVersion(){
        return AxiosService.get(`/version`);     
    },

    loginWallet(username, wallet_phrase){
        return AxiosService.post(`/auth/access_token`, {username, wallet_phrase});
    },

    getSatsQuote(currency = 'USD'){
        // return AxiosService.post(`/auth/access_token`, {username, wallet_phrase});
        return AxiosService.get(`/quote/sats/${currency}`);
    },

    getKeys(){
        // return AxiosService.post(`/auth/access_token`, {username, wallet_phrase});
        return AxiosService.get(`/wallet/key`);
    },
    createKey(key_name, expires_days, key_assignment=null){
        return AxiosService.post(`/wallet/key`,{key_name, expires_days, key_assignment});
    },
    deleteKey(id){
        return AxiosService.del(`/wallet/key/${id}`);
    },
    invalidateKey(id){
        return AxiosService.put(`/wallet/key/invalidate/${id}`);
    },

    put(wallet){
        return AxiosService.put(`/wallet`, wallet, {
            headers: {'Content-Type': 'application/json'}
        });
    }

};



export const balConvert = (quote, balance) => {
    const sats = round(balance/1000);
    const fiat = round(sats/quote.rate[1],2);
    return {sats, fiat};
};

export const satsConvert = (quote, sats) => {
    // console.log("satsConvert", quote, sats);
    const fiat = round(sats/quote.rate[1],2);
    return {sats, fiat};
};

export const fiatConvert = (quote, fiat) => {
    const sats = round(fiat*quote.rate[1]);
    return {sats, fiat};
};


export const formatFiat = ( fiatAmount, satsRate, userCurrency, userLang, showCurrency=true) => (
<>
    {Intl.NumberFormat(userLang,{style:'currency', currency: userCurrency }).format(fiatAmount)} {showCurrency && userCurrency}
</>);


// /subscription/upgrade/<wallet_id>
export const getUpgradeSubscriptionRequest = (wallet_id) => {
    return AxiosService.get(`/subscription/upgrade/${wallet_id}`);
};


export const pollPaymentHash = (user, paymentHash) => {
    return AxiosService.get(`/poll/payment/${paymentHash}`);
};

export const balToSats = (balance) => {
    return round(balance/1000);
};

export const btcToSats = (invoiceAmount) => {
    return round(invoiceAmount*100000000);
};

export const signup = (email) => {
    return AxiosService.post(`/signup`, { email });
};





