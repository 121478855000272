import React, { useState, useEffect  } from 'react';
import lodash from 'lodash';

import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
//import { withLocale } from "react-easy-localization";

import { MdOutlinePayments } from "react-icons/md";

import NewPagination from "../../components/NewPagination";
import {VoucherService} from '../../service/VoucherService'
import Page from "../../components/Page";
import VouchersTable from "../../components/VouchersTable";
import ConfirmModal from "../../components/ConfirmModal";
import HelpAlert from "../../components/HelpAlert";

import "./style.css";


/**
 * 
 * @param {*} props
	"created_at": "Thu, 26 May 2022 16:35:33 GMT",
	"id": 32,
	"is_unique": 0,
	"k1": "FH2CynM5JthPYgZzpWaQZb",
	"lnurl": "LNURL1DP68GURN8GHJ76MPD3KX7CNN9EHHYEE6X5CRYVP0WA5HG6RYWFSHWTMVDE6HYMP0WD5KUEMVV5HKYJMXG4HKW4PCX49K6NRJD9FRS5N6W34567SSSGUN0",
	"max_withdrawable": 334,
	"min_withdrawable": 334,
	"number": 0,
	"open_time": 1653608133,
	"payment_hash": null,
	"title": "rapaygo POS voucher payment invoice 6a91e 20-EHUJVkFJ ",
	"unique_hash": "bKfEogT85KmLriR8RztkMz",
	"updated_at": "Thu, 26 May 2022 16:35:33 GMT",
	"used": 0,
	"uses": 1,
	"usescsv": "1",
	"wait_time": 0,
	"wallet_id": 20
}} param0 
 * @returns 
 */
const Vouchers = ({ i18n, languageCode, changeLanguage, useStore }) => {

  const navigate = useNavigate();

  const { walletInfo, satsRate  } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [vouchers, setVouchers] = useState();
  const [vouchersPage, setVouchersPage] = useState({page: 1, page_size: 15});
  const [vouchersPageMeta, setVouchersPageMeta] = useState({total_items_count:15, current_page_number:1, total_pages_count:1});
  const [invalidateId, setInvalidateId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState();
  

  useEffect(() => {
    setIsLoading(true);
    fetchVouchersPage(vouchersPage);
    setIsLoading(false);
  }, [vouchersPage]);

  let fetchVouchersPage = (vPage) => { 
    setIsLoading(true);
    VoucherService.getPaged(vPage).then((vr) => {
      // console.log("vouchers", vouchers);
      setVouchers(vr.data.withdraw_vouchers);
      setVouchersPage(vPage)
      setVouchersPageMeta(vr.data.page_meta);
      setIsLoading(false);
      console.log("vouchers", vouchers);
    });
  };

  // should trigger reload
  const handlePageChange = (e) => {
    console.log("handlePageChange", e);
    setVouchersPage({page: 1, page_size: parseInt(e)});
  }


  const invalidateVoucher = () => {

    let voucher_by_id = lodash.filter(vouchers, (o) => { console.log(o); return o.id === invalidateId; });
    let voucherN = {...voucher_by_id[0], status: "INVALID"}
    VoucherService.invalidate(voucherN.id).then((voucher)=>{
      console.log('returned voucher',voucher.data);
      setVouchers(lodash.unionBy([voucher.data], vouchers, 'id'));
      setShowModal(false); //all done
    }).catch((error)=>{
      // setAlert('Could not invalidate this voucher.');
      setModalError("There was a problem while attempting to invalidate.");
    });

    
  };

  const showInvalidateConfirmation = (invalidateId) => {
    console.log("confirm invalidate", invalidateId);
    setInvalidateId(invalidateId);
    setShowModal(true);
  };

  return (
    <>
    <Page useStore={useStore}>
      <div className='p-4'>
        <div className='row'>
          <h1>Vouchers</h1>
          {walletInfo && walletInfo.payment_modes && walletInfo.payment_modes ==='SUPPORT' && <HelpAlert variant='warning' textClass='text-orange-600' helpLink='/docs/#/vouchers'>
            <p>Vouchers authorize the removal of SATs from your account.
              <strong>Your wallet has not been verified.</strong></p>
            <ul className='list-disc'>
              <li>Vouchers require support approval to be made VALID for unverified wallets. (No KYC Required)</li>
              <li>Approvals can be expected within 24hrs.</li>
              <li>Account verification will allow for automatic approval. (KYC Required)</li> 
              <li><button className='text-gray-700 hover:text-orange-500 underline mr-1' onClick={()=>window.location="https://rapaygo.freshdesk.com/support/home"}>Contact Support</button> 
                to obtain automatic approval for vouchers.</li>
            </ul>
          </HelpAlert>} 
          {walletInfo && walletInfo.payment_modes && walletInfo.payment_modes ==='VERIFIED' && <HelpAlert variant='info' textClass='text-blue' helpLink='/docs/#/vouchers'>
            <p>Vouchers authorize the removal of SATs from your account.<strong>Take care when creating and distributing vouchers.</strong></p>
          </HelpAlert>} 
        </div>
        <div className='row'> 
          {isLoading && <Spinner animation="border"/>}
          <div className="m-1 flex flex-row w-full justify-end">
            {/* @TODO need to support voucher download */}
            {/* <button className="btn-common-blue" 
              onClick={()=>{console.log('click download')}}><BsFillCloudDownloadFill className='mr-1'/> Download CSV</button> */}

            <button className="btn-common-blue" 
              onClick={()=>{navigate('/pos/send')}}><MdOutlinePayments className='mr-1'/> Send Payment Voucher</button>
            
          </div>
          {(vouchers && satsRate) && <div>
              <VouchersTable vouchers={vouchers} setVouchers={setVouchers} satsRate={satsRate} handleInvalidate={showInvalidateConfirmation} showInvalidate={true}/>       
              <hr/>
              {(vouchers && satsRate) && <NewPagination pageInfo={vouchersPage} pageMeta={vouchersPageMeta} handlePageChange={handlePageChange} fetchPage={setVouchersPage} />}

          </div>}        
        </div>
      </div>
      
      <ConfirmModal showModal={showModal} actionName={'Delete Invoice'} actionDescription={'delete this invoice'} actionCallback={()=>invalidateVoucher()} actionClose={()=>setShowModal(false)} errorValue={modalError}/>
    
    </Page>

    </>
  );
};


export default Vouchers;
