// import React, {useState, useEffect } from 'react';
import React, {useState, useEffect } from 'react';
import Ajv from "ajv";
import { useNavigate } from 'react-router-dom';
import { createSignal } from "@react-rxjs/utils"
import { bind } from "@react-rxjs/core"
import {
    Button, Form, Spinner, ListGroup, Row, Alert
} from 'react-bootstrap';
import ImageUploading from 'react-images-uploading';
import { Trash, Edit } from "react-feather";
import { ProductService } from '../service/ProductService'


const [tagChange$, setTags] = createSignal();
const [useTags] = bind(tagChange$, []);

const TagTypeList = ({ typeTags }) => {
  return (
    <ListGroup>
    {typeTags.map((tag, index) => (
      <ListGroup.Item key={index}>{tag.type} | {tag.name} | {tag.description}</ListGroup.Item>
      ))}
    </ListGroup>
  );

};

const ItemTagFormGroup = () => {

  let tags = useTags();

  return (
  <Form.Group>
    <h4>Product Item Attributes</h4>
    <TagTypeList typeTags={tags}/>
    <ItemTagFormControl/>
  </Form.Group>  
  );

};

const ItemTagFormControl = () => {

  let tags = useTags();

  const [formState, setFormState] = useState({
    name: "",
    description: "",
    value: "",
    price: "",
    shipping_cost_usps_5:0.0,
    shipping_cost_ups_3:0.0
  });


  const handleInputChange = event => {
    let target = event.target
    let value = target.value
    let name = target.name

    // if (name === "price") {
    //   value = value.replace(/\D/g,'');
    // }

    setFormState((formState) => ({
        ...formState,
        [name]: value
      }));  
  };  

  const addTag = (e) => {
    let tagsCopy = [...tags];
    tagsCopy.push({name:formState.name, type:formState.type, description:formState.description});
    console.log(tagsCopy);
    setTags(tagsCopy);
  };

  return (
    <div className='mt-3 d-flex'>
        <Form.Control onChange={(e)=>{handleInputChange(e)}} className='col w-25 m-1' type="text" name="type" placeholder="type of attribute"/>
        <Form.Control onChange={(e)=>{handleInputChange(e)}} className='col w-25 m-1' type="text" name="name" placeholder="value of attribute"/>
        <Form.Control onChange={(e)=>{handleInputChange(e)}} className='col w-50 m-1' type="text" name="description" placeholder="longer description"/>
        <Button variant="primary" onClick={(e)=>{addTag(e)}}>Add Tag</Button>
    </div>
  );
};

const ProductForm = ({ paymentItem, formType }) => {

  const maxNumber = 1;

  const navigate = useNavigate();

  const [formState, setFormState] = useState(paymentItem);
  const [images, setImages] = useState([]);
  const [savedStatus, setSavedStatus] = useState("start");
  const [wallet] = useState(null);
  const [errors, setErrors] = useState([]);


  useEffect(() => {
    if(formType==='edit' && paymentItem){
      const returnedTarget = Object.assign({}, paymentItem);
      console.log("setting form state", returnedTarget);
      setFormState(returnedTarget);
      if(paymentItem.images && paymentItem.images.length>0){
        setImages(paymentItem.images);
      }
    } else {
      setFormState({
        name: "",
        description: "",
        price: "" 
      });
      setErrors([]);
      setSavedStatus("start")
    }
  }, [paymentItem]);


  function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

  const handleInputChange = event => {
      const target = event.target
      let value = target.value
      const name = target.name

      if(name === 'price'){
        value = parseFloat(value.replace(/[^\d.-]/g, ''));
        console.log("h price", value);
      }

      const n_state = {
        ...formState,
        [name]: value
      }
      console.log("new form state", n_state);
  
      setFormState(n_state);  

      
  };  

  const onImagesChange = (imageList, addUpdateIndex) => {
      // data for submit
      console.log("onImagesChange", imageList, addUpdateIndex);
      // if we do this we will allow the new changed 
      // image to be what is used, otherwise the o
      // image will be used
      delete formState.images;
      setImages(imageList);
  };



  const formSchema = {
      type: "object",
      properties: {
          name:           {type: "string", "nullable": false, minLength: 4, maxLength: 64},
          description:    {type: "string", "nullable": false, minLength: 4, maxLength: 500},
          price:          {type: "number", "nullable": false},
      },
      required: [ "name", "description", "price" ],
      additionalProperties: true
  };

  let renderErrors = (errors) => {
    console.log(errors);
    return errors.map((error, index) => (
        <div key={index}>Invalid {error.instancePath} {error.keyword} {error.message}</div>
    ));
  };

  const onCancel = () => {
    setFormState({
      name: "",
      description: "",
      price: "" 
    });
    setErrors([]);
    navigate("/items");
  };

  const onSaveButton = (e) => {
    e.preventDefault();
    setErrors([]);
    
    console.log("onSaveButton", formState);
    let saveObject = Object.assign({images},formState);

    //set price to number or set errors
    console.log("saving object", saveObject, isNumber(saveObject.price));
    if(isNumber(saveObject.price)){
      saveObject.price = parseFloat(saveObject.price);
      formState.price = parseFloat(formState.price);
          // setup formats
      const ajv = new Ajv();
      const validate = ajv.compile(formSchema); //we dont want to validate the images
      const schemaValid = validate(formState);

      if (!schemaValid) {
        console.log("validation errors:", validate.errors, formState)
        let allErrors = [];
        allErrors.push(...validate.errors);
        setErrors(allErrors);
      } else {
        console.log("saving", saveObject);
        if(saveObject.id){
      
          ProductService.updatePaymentItem(saveObject).then(r=>{
            // console.log("done", r);
            setSavedStatus("done");
          }).catch(e=>{
            setErrors([{
              "instancePath": "/save",             
              "keyword": "unknown error, try again.",
              "message": "problem saving product"
            }]);
            setSavedStatus("error");
          });
    
        } else {
    
          ProductService.createPaymentItem(saveObject).then(r=>{
            setSavedStatus("done");
          }).catch(e=>{
            setErrors([{
              "instancePath": "/save",             
              "keyword": "unknown error, try again.",
              "message": "problem saving product"
            }]);
            setSavedStatus("error");
          });
        }
      }
    } else {
      setErrors([{
        "instancePath": "/price",              
        "keyword": "type price must be a numeric value.",
        "message": "problem saving product"
      }]);
      setSavedStatus("error");
    }
  };

  return (

    <>{formState?
    <>
      {errors && errors.length>0 && <Alert className="flex flex-col" variant="danger">
        <div className="font-bold">Validation Errors Found {renderErrors(errors)}</div>
      </Alert>}
      {savedStatus==="done" && <Alert variant="success">Item successfully saved.</Alert>}
      <Form className='mb-5'>
         <Form.Group className="mb-3" controlId="formName">
             <Form.Label>Product Name<span className='color-red'>*</span></Form.Label>
             <Form.Control name="name" onChange={handleInputChange} type="text" placeholder="Product Name" value={formState.name}/>
         </Form.Group>
         {formState.id?
         <Form.Group className="mb-3" controlId="formSlug">
             <Form.Label>Product Slug<span className='color-red'>*</span></Form.Label>
             <Form.Control name="slug" onChange={handleInputChange} type="text" placeholder="Product Slug" value={formState.slug}/>
         </Form.Group>:<></>}         
         <Form.Group className="mb-3" controlId="formDescription">
             <Form.Label>Description<span className='color-red'>*</span></Form.Label>
             <Form.Control name="description" onChange={handleInputChange} as="textarea" rows={4} value={formState.description} placeholder="Item description..."/>
         </Form.Group>
         <Form.Group className="mb-3" controlId="formPrice">
             <Form.Label>Item Price USD<span className='color-red'>*</span></Form.Label>
             <Form.Control type="text" name="price" onChange={handleInputChange} placeholder="price in USD" value={formState.price}/>
         </Form.Group> 

         <Form.Group className="mb-3" controlId="formPrice">
             <Form.Label>Image (Max {maxNumber} Image)</Form.Label>
             <ImageUploading
                    multiple
                    value={images}
                    onChange={onImagesChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        <div className='w-100 d-flex mb-2'>
                          <div className='product-image-drag'
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                            >
                            Click or Drop here
                          </div>
                          &nbsp;
                          <button className='p-1 rounded bg-blue hover:bg-blue-600' onClick={onImageRemoveAll}>Remove All Images</button>
                        </div>

                        {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                            <img src={image['data_url']} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                            <Edit onClick={() => onImageUpdate(index)}/>
                            <Trash onClick={() => onImageRemove(index)}/>
                            </div>
                        </div>
                        ))}
                    </div>
                    )}
                </ImageUploading>

            </Form.Group>

            {(wallet && wallet.subscription_type === "SELLER") && 
            <>           
            <Row>

              <Form.Group className="mb-3" controlId="formSku">
                <Form.Label>SKU</Form.Label>
                <Form.Control name="sku_id" onChange={handleInputChange} type="text" placeholder="Unique SKU" defaultValue={formState.sku_id}/>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formQty">
                <Form.Label>In Stock Quantity</Form.Label>
                <Form.Control type="text" name="stock" onChange={handleInputChange} placeholder="in stock now qty" defaultValue={formState.stock}/>
              </Form.Group> 
              <Form.Group className="col col-6 mb-3" controlId="formShippingCostUSPS">
                  <Form.Label>Shipping Cost USPS 5 day</Form.Label>
                  <Form.Control type="text" name="shipping_cost_usps_5" onChange={handleInputChange} placeholder="shipping cost in USD" defaultValue={formState.shipping_cost_usps_5}/>
              </Form.Group>
              <Form.Group className="col col-6 mb-3" controlId="formShippingCostUPS">
                  <Form.Label>Shipping Cost UPS 3 day</Form.Label>
                  <Form.Control type="text" name="shipping_cost_ups_3" onChange={handleInputChange} placeholder="shipping cost in USD" defaultValue={formState.shipping_cost_ups_3}/>
              </Form.Group>
            </Row>               
            <ItemTagFormGroup/></>}     

            <div className='flex justify-end'>
              <div className='p-2'>
                <button className='m-1 p-1 rounded bg-gray-400 hover:bg-gray-500' onClick={()=>onCancel()}>Cancel</button>
                <button className='m-1 p-1 rounded bg-blue hover:bg-blue-600' onClick={(e)=>onSaveButton(e)}>Save Item</button>
              </div>
              <div className='p-2 mt-3'>            
                {savedStatus==="saving"?<Spinner animation='border'/>:<></>}
                {/* {savedStatus==="done"?<span className='color-green'>Saved</span>:<></>}
                {savedStatus==="error"?<span className='color-red'>Error</span>:<></>} */}
              </div>
            </div>


        </Form>        
    
    </>:
    
    <Spinner animation="border"/>}</>
    
  );
};


export default ProductForm

