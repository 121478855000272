import React from "react";


const AddressesTable = ({addresses, setEditItem, deleteItem}) => { 

    return (
        <>    
        {addresses ?
        <div className="flex flex-col">
            {addresses.length == 0 && <div className="text-2xl mb-3">No Addresses.</div>}

            {addresses.map((a) => (

            <div key={a.id} className="mb-2 flex flex-col bg-slate-100 rounded p-1">

                <div className="flex flex-col justify-end">
                    <div className="text-slate-500 text-2xl font-bold">{a.name}</div>
                    <div>{a.first_name} {a.last_name}</div>
                    <div>{a.street_address}</div>
                    {a.street_address_2 ? <div>{a.street_address_2}</div> : null}
                    <div>{a.city} {a.state} {a.zip_code} {a.country}</div>
                </div>

                <div className="flex flex-col justify-end">    
                    <div className="flex flex-row justify-end">                    
                        <div className="btn-common-blue text-xs cursor-pointer" onClick={()=>setEditItem(a.id)}>edit</div>
                        <div className="btn-common-blue text-xs cursor-pointer" onClick={()=>deleteItem(a.id)}>delete</div>
                    </div>
                </div>

            </div>))}
        </div>:
        <div className="row">
            <h3>No addresses.</h3>
        </div>

        }
        </>
    );
}


export default AddressesTable;