import Axios from 'axios';
import { getParams } from 'js-lnurl'
import { backendBaseUrl } from '../env';
import {AxiosService} from "./AxiosService";

Axios.defaults.withCredentials = false; 

export const LightningPaymentService = {

    /**
     * this is the public version of the lightning payment service
     * @param {paymentHash} paymentHash 
     * @returns 
     */
    getById (id) {
        // return Axios.get(`${backendBaseUrl}/invoice_payment/by_id/${id}`);
        return AxiosService.get(`/invoice_payment/by_id/${id}`);
    },
    getByHash (paymentHash) {
        return Axios.get(`${backendBaseUrl}/invoice_payment/by_payment_hash/${paymentHash}`);
    },
    getInvoicePayments() {
        return AxiosService.get(`/invoice_payment`);
    },
    getInvoicePaymentsPaged(pageInfo={page: 1, page_size: 10}) {
        return AxiosService.getPage(`/invoice_payment`, pageInfo);
    },
    getOrderInvoicePayments(orderId) {
        return AxiosService.get(`/invoice_payment/by_order_id/${orderId}`);
    },
    createInvoice(amount_sats, memo) { //receive sats only
        return AxiosService.post(`/invoice_payment/ln/invoice`, { amount_sats, memo });
    },
    createOnchainInvoice(amount_sats, memo) { //receive sats only
        return AxiosService.post(`/invoice_payment/onchain/invoice`, { amount_sats, memo });
    },
    createSendVoucher(amount_sats, memo, use_type) {
        return AxiosService.post(`/invoice_payment/ln/voucher`, { amount_sats, memo });
    },   
    getLNURLBody(lnurlString) {       
        return getParams(lnurlString);
    },
    getInvoicePaymentsCSV() {
        return AxiosService.get(`/invoice_payment/csv`);
    },
    sendBolt11Payment(bolt11, memo=null) {
        ///invoice_payment/pay/bolt11
        return AxiosService.post(`/invoice_payment/pay/bolt11`, { bolt11, memo });
    },

    /**
     * this is the private version of the lightning payment service
     * /invoice_payment/ln/invoic
     * @param {paymentHash} paymentHash
     * @returns
     * @memberof LightningPaymentService
     * @private
     * @throws {Error}
     **/
    getByPaymentHash(paymentHash) {
        return AxiosService.get(`/invoice_payment/${paymentHash}`);
    },
    delete(paymentHash) {
        console.log("delete", paymentHash);
        return AxiosService.del(`/invoice_payment/${paymentHash}`);
    },
    update(data) {
        return AxiosService.put(`/invoice_payment`, data);
    }

};









