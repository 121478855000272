import React, { useRef, useState, useEffect } from "react";
import lodash from 'lodash';
import { Alert, Modal, Breadcrumb } from "react-bootstrap";
import { Printer, Trash, ArrowDownCircle, ArrowUpCircle } from "react-feather";
import { useReactToPrint } from 'react-to-print'
import { useNavigate } from "react-router-dom";

import { LightningPaymentService } from '../../service/LightningPaymentService'
import { CustomerService } from "../../service/CustomerService";
import { formatFiat, fiatConvert } from '../../service/WalletService'
import Page from "../../components/Page";
import InvoiceDetail from "../../components/InvoiceDetail";

import "./style.css";

import { FaCashRegister } from "react-icons/fa";
import { OrderService } from "../../service/OrderService";


const PosRecieptPrintableCart = ({ productCart, satsRate, userCurrency, userLang,
    invoice, satsTotal, currencyTotal }) => {

    const [showModalLocal, setShowModalLocal] = useState(false);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const hp = () => {
        handlePrint();
    };

    const handleClose = () => {
        setShowModalLocal(false);
    };

    return (<>
        <Modal show={showModalLocal} onHide={handlePrint} size="sm">

            <Modal.Body>            
                <div className="p-3 pos-recept" ref={componentRef}>
                    <h3 className="print-pos">Cart Checkout</h3>
                    <TotalLine productCart={productCart} satsRate={satsRate} userCurrency={userCurrency} userLang={userLang} currencyTotal={currencyTotal} satsTotal={satsTotal} />
                    {invoice &&
                        <div className="mb-2">
                            <InvoiceDetail userCurrency={userCurrency} userLang={userLang} amountSats={satsTotal} amountFiat={currencyTotal} invoice={invoice} satsRate={satsRate} showTitle={false} qrSize={200} mode="receipt"/>
                        </div>}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className="btn-common-gray" onClick={()=>handleClose()}>Cancel</button>
                <button className="btn-common-blue" onClick={()=>hp()}>Print</button>
            </Modal.Footer>
        </Modal>
        <div className="align-center">
            <button className="p-1 flex bg-blue hover:bg-600 rounded" onClick={() => setShowModalLocal(true)}><Printer /> {' '} Print POS Receipt</button>
        </div>
    </>);
};


const LegalSizePrintableCart = ({ productCart, satsRate, userCurrency, userLang,
    invoice, satsTotal, currencyTotal }) => {

    const [showModalLocal, setShowModalLocal] = useState(false);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const hp = () => {
        handlePrint();
    };

    const handleClose = () => {
        setShowModalLocal(false);
    };

    return (<>
        <Modal show={showModalLocal} onHide={handlePrint} size="lg">

            <Modal.Body>            
                <div className="p-3" ref={componentRef}>
                    <h3 className="print-pos">Cart Checkout</h3>
                    <CartItemsTable className="w-100 print-pos" productCart={productCart} satsRate={satsRate} userCurrency={userCurrency} userLang={userLang} />
                    <TotalLine productCart={productCart} satsRate={satsRate} userCurrency={userCurrency} userLang={userLang} currencyTotal={currencyTotal} satsTotal={satsTotal} />
                    {invoice &&
                        <div className="mb-2">
                            <InvoiceDetail userCurrency={userCurrency} userLang={userLang} amountSats={satsTotal} amountFiat={currencyTotal} invoice={invoice} satsRate={satsRate} showTitle={false} qrSize={200} />
                        </div>}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className="btn-common-gray" onClick={()=>handleClose()}>Cancel</button>
                <button className="btn-common-blue" onClick={()=>hp()}>Print</button>
            </Modal.Footer>
        </Modal>
        <div className="align-center">
            <button className="p-1 flex bg-blue hover:bg-600 rounded" onClick={() => setShowModalLocal(true)}><Printer /> {' '} Print Letter Sized</button>
        </div>
    </>);
};


const TotalLine = ({ productCart, satsRate, userCurrency, userLang, currencyTotal, satsTotal }) => {

    return (<>

            <div className="flex w-full flex-row justify-between p-1">
                    <div className="flex w-4/6 flex-col">
                        <div className="text-lg font-bold">Total</div>
                        
                    </div>
                    <div className="flex w-1/6 flex-col justify-start bg-blue-300">
                        <div className="text-right font-bold text-2xl font-monospace">{formatFiat(currencyTotal, satsRate, userCurrency, userLang, false)}</div>
                        <div className="text-lg text-right font-bold font-monospace">{satsTotal} SATS</div>

                    </div>
                </div> 


    </>);
};


/**
 * categories: []
 * @param {*} param0 
 * @returns 
 */
const CartItemsTable = ({ productCart, satsRate, userCurrency, userLang, className, showArrows=true, qtyHandler }) => {
    const getLinePrice = (item) => {
        return item.price * item.qty;
    };
    return (
        <>
            {productCart.map(cartItem => (
            
                <div key={cartItem.name} className="flex w-full flex-row justify-between p-2 border-b-4">
                    <div className="flex w-4/6 flex-col">
                        <div className="text-lg font-bold">{cartItem.name}</div>
                        <div>{cartItem.description}</div>
                    </div>
                    <div className="flex w-1/6 flex-col justify-start bg-green-300">
                        <div className="text-right font-monospace text-2xl">{cartItem.qty} x {formatFiat(cartItem.price, satsRate, userCurrency, userLang, false)}</div>
                        <div className="text-right font-monospace text-xs text-gray-500">{fiatConvert(satsRate, cartItem.price).sats} SATS each</div>
                        <div className="flex justify-end text-right font-monospace text-xs text-gray-500">
                        <div className="m-2 cursor-pointer hover:text-blue-500 text-slate-700" onClick={()=>qtyHandler('add', cartItem)}><ArrowUpCircle/></div>
                        <div className="m-2 cursor-pointer  hover:text-blue-500 text-slate-700" onClick={()=>qtyHandler('remove', cartItem)}><ArrowDownCircle/></div>
                        </div>
                    </div>
                    <div className="flex w-1/6 flex-col justify-start bg-blue-300">
                        <div className="text-right font-monospace text-2xl">= {formatFiat(getLinePrice(cartItem), satsRate, userCurrency, userLang, false)}</div>
                        <div className="text-right font-monospace text-xs text-gray-500">{fiatConvert(satsRate, getLinePrice(cartItem)).sats} SATS</div>
                    </div>
                </div>            
            
            ))}

        </>);
};

const OrderModal = ({useStore, showOrderModal, setShowOrderModal, orderCreatedCallback}) => {

    const navigate = useNavigate();

    const { productCart } = useStore();

    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState();
    const [customersPage, setCustomersPage] = useState({page: 1, page_size: 25});
    const [customersPageMeta, setCustomersPageMeta] = useState({total_items_count:25, current_page_number:1, total_pages_count:1});

    const [customer, setCustomer] = useState();

    useEffect(() => {
        fetchCustomersPage(customersPage);
    }, [customersPage]);

    let selectCustomer = (e) => {
        console.log(e.target.value);
        setCustomer(e.target.value);
    };

    let fetchCustomersPage = (cPage) => { 
        setIsLoading(true);
        CustomerService.getPaged(cPage).then((cr) => {
          setCustomers(cr.data.customer_accounts);
          setCustomersPage(cPage)
          setCustomersPageMeta(cr.data.page_meta);
          setIsLoading(false);
        });
    };

    let createOrder = () => {
        console.log('create order', customer);
        OrderService.createFromCart(productCart, customer)
        .then((cr) => {
            console.log('order created', cr);
            orderCreatedCallback(cr.data);
        })
        .catch((err) => {
             console.log('order create error', err);
        });
    };

    return (
        <>
            <Modal show={showOrderModal} onHide={() => setShowOrderModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Order From Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    {customers && customers.length>0 ? 
                        <div className="flex flex-col"> 
                            <div>Choose Order Customer.</div>
                            <select name="company" onChange={(e)=>selectCustomer(e)} className="mt-1 block w-full rounded">  
                                <option value="">Select Customer</option>
                                {customers.map((c) => {
                                    return (<option value={c.id} key={c.id}>{c.company_name}</option>)
                                })}
                            </select>
                        </div>:
                    <>
                        <div className="flex flex-row bg-orange-100 text-orange-600 w-full">
                            No customers have been created yet. <span className="ml-2 underline cursor-pointer mr-1" onClick={()=>navigate('/customers')}>Click here</span> to create one.
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-common-gray" variant="secondary" onClick={() => setShowOrderModal(false)}>Close</div>
                    <div className="btn-common-blue" onClick={()=>createOrder()}>Create Order</div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const CartCheckout = ({ useStore }) => {
    const { productCart, getCartSize, emptyCart, 
        satsRate, userCurrency, userLang, 
        removeProductFromCart, addProductToCart } = useStore();

    let navigate = useNavigate();

    const [invoice, setInvoice] = useState();
    const [currencyTotal, setCurrencyTotal] = useState(0);
    const [satsTotal, setSatsTotal] = useState(0);
    const [error, setError] = useState();
    // timestamp as state
    const [timestamp, setTimestamp] = useState(Date.now());
    const [timerId, setTimerId] = useState();

    const [showOrderModal, setShowOrderModal] = useState(false);
    

    // we want to poll the lightning payment service by hash
    // and update the invoice state when it is paid
    useEffect(() => {
        // set timeout to 10 seconds to poll the lightning payment service
        setTimerId(setTimeout(() => {
            if (invoice) {
                LightningPaymentService.getByHash(invoice.payment_hash)
                  .then(response => {
                    let ival = response.data;
                    setInvoice(ival);
                    console.log('invoice', ival);
                    if (ival.status !== 'COMPLETED') {
                        setError(null);
                        setTimestamp(Date.now());
                    } else {
                        // set timeout for 3 seconds to empty the cart
                        setTimeout(() => {
                            clearTimeout(timerId);
                            emptyCart();
                            navigate('/items');
                        }, 3000);
                    }
                  })  
                  .catch(error => {
                    setError("There was an error polling the lightning payment service");
                  });
                } else {
                    console.log('no invoice, wait 10 seconds');
                    setTimestamp(Date.now());
                }
        } , 10000));


    } , [timestamp]);



    const getLinePrice = (item) => {
        return item.price * item.qty;
    };

    const getLineSats = (item, satsRate) => {
        return fiatConvert(satsRate, getLinePrice(item)).sats;
    };

    useEffect(() => {
        let cartTotal = lodash.sumBy(productCart, (o) => getLinePrice(o));
        setCurrencyTotal(cartTotal);
        let cartTotalSats = lodash.sumBy(productCart, (o) => getLineSats(o, satsRate));
        setSatsTotal(cartTotalSats);     
    }, [productCart]);

    const qtyHandler = (method, productItem) => {
        if (method == 'add'){
            addProductToCart(productItem);
        }
        if (method == 'remove'){
            removeProductFromCart(productItem);
        }

    }

    const makeReceiveInvoice = (amountSats) => {
        LightningPaymentService.createInvoice(amountSats, `rapaygo POS income payment invoice`)
            .then(invoice => {
                setInvoice(invoice.data);
            }).catch(err => {
                setError(err.message);
            });
    };

    const orderCreated = (order) => {
        console.log('order created callback', order);
        setShowOrderModal(false);
        emptyCart();
        navigate(`/order/${order.id}`);
    };



    return (
        <>
            <Page useStore={useStore}>
                <div className="p-4">
                    <Breadcrumb>
                        <Breadcrumb.Item href="#" onClick={()=>navigate("/items")}>Products</Breadcrumb.Item>
                        <Breadcrumb.Item active>Cart Checkout</Breadcrumb.Item>
                    </Breadcrumb>
                    {error && <Alert variant="danger">{error} </Alert>}
                    {productCart.length > 0 ? <>
                        <div className="flex flex-row justify-between w-100 mb-2">
                            <div className="w-1/3"><h1>Cart Checkout</h1></div>
                            <div className="w-2/3 flex justify-end">
                                <button className="mr-3 btn-common-blue" onClick={()=>emptyCart()}><Trash/> Empty Cart</button>
                                <button className="btn-common-blue" onClick={()=>makeReceiveInvoice(satsTotal)}><FaCashRegister className="ml-1 mr-2"/> Checkout</button>
                                <button className="btn-common-blue" onClick={()=>setShowOrderModal(true)}>Create Order</button>
                            </div>
                        </div>
                        <div className="row">
                            <CartItemsTable className="mb-3 w-100" productCart={productCart} satsRate={satsRate} userCurrency={userCurrency} userLang={userLang} qtyHandler={qtyHandler}/>
                            <TotalLine productCart={productCart} satsRate={satsRate} userCurrency={userCurrency} userLang={userLang} currencyTotal={currencyTotal} satsTotal={satsTotal} />
                            
                            {invoice &&
                                <div className="w-100">
                                    <div className="mb-2">
                                        <InvoiceDetail userCurrency={userCurrency} userLang={userLang} amountSats={satsTotal} amountFiat={currencyTotal} invoice={invoice} satsRate={satsRate} showTitle={false} />
                                    </div>
                                    <div className="d-flex flex-row mb-3 justify-content-center">
                                        <div className="m-1"><LegalSizePrintableCart productCart={productCart} satsRate={satsRate} userCurrency={userCurrency} userLang={userLang} invoice={invoice} satsTotal={satsTotal} currencyTotal={currencyTotal} /></div>
                                        <div className="m-1"><PosRecieptPrintableCart productCart={productCart} satsRate={satsRate} userCurrency={userCurrency} userLang={userLang} invoice={invoice} satsTotal={satsTotal} currencyTotal={currencyTotal} /></div>
                                    </div>
                                </div>}                            
                        </div>
                    
                    
                    </> : 
                    <>
                        <div className="row"><h3>Cart is empty.</h3></div>
                    </>}

                    <OrderModal useStore={useStore} showOrderModal={showOrderModal} setShowOrderModal={setShowOrderModal} orderCreatedCallback={orderCreated}/>               



                </div>

            </Page>
        </>);
};

export default CartCheckout

