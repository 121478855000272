import React, { useState, useEffect } from "react";
//import { withLocale } from "react-easy-localization";
import { DropdownButton, Dropdown } from 'react-bootstrap';
import lodash from "lodash"; 
import moment from 'moment'

import { WalletService } from '../../service/WalletService';
import Page from "../../components/Page";
import APIKeySelector from "../../components/APIKeySelector";
import HelpAlert from "../../components/HelpAlert";
import { getUser, getAccessTokenInfo } from '../../service/auth';
import { appUrl, backendBaseUrl } from "../../env";

import "./style.css";

// const WalletKeySelector = () => {
//   const [tokenInfo] = useState(getAccessTokenInfo(getUser()));  
//   const [keys, setKeys] = useState();

//   const [keySelected, setKeySelected] = useState(null);

//   useEffect(() => {
//       if(tokenInfo.active) {
//           WalletService.getKeys().then((keys) => {
//               //filter out expired
//               let sval = lodash.filter(keys.data,(o) => { 
//                 let ed = new Date(o.expires);
//                 return moment().isBefore(ed);
//               });
//               setKeys(sval); 
//           });
//       }

//   }, [tokenInfo]);

//   const handleSelect = selectItem => {
//     setKeySelected(true);
//     console.log('handleSelect', selectItem);
//     let sval = lodash.filter(keys, 
//       { 'id': parseInt(selectItem) }
//     );
//     sval[0].expires = new Date(sval[0].expires);
//     setKeySelected(sval[0]);
//     console.log("selected key",keySelected);
//   };

//   return (
//     <>
//       <div className="w-100">
//         <div>
//           <DropdownButton
//               variant="light"
//               title={keySelected ? keySelected.key_name : "Choose Key" }
//               id="dropdown-menu-align-right"
//               onSelect={handleSelect}>
//                 {keys && keys.map((key, index) => (
//                   <Dropdown.Item key={index} eventKey={key.id}>{key.key_name}</Dropdown.Item>
//                 ))}
//             </DropdownButton>
//         </div>
//         {keySelected && 
//         <div className="row">
        
//           <div className="flex mb-3">
//               <div className="mt-3 w-full md:w-1/4">Key Name</div>
//               <div className="mt-3 p-1 w-full md:w-3/4 bg-gray-600 text-orange font-monospace font-bold rounded">{keySelected.key}</div>
//           </div>
//           <div className="flex mb-3">
//               <div className="mt-3 w-full md:w-1/4">Key Secret</div>
//               <div className="mt-3 p-1 w-full md:w-3/4 bg-gray-600 text-orange font-monospace font-bold rounded">{keySelected.secret}</div>
//           </div>
//           <div className="flex mb-3">
//               <div className="mt-3 w-full md:w-1/4">Expires</div>
//               <div className="mt-3 p-1 w-full md:w-3/4 bg-gray-600 text-orange font-monospace font-bold rounded">{moment(keySelected.expires).fromNow()}</div>
//           </div>
          
//         </div>}

//       </div>
//     </>
//   );
// }

const ECommerce = ({ i18n, languageCode, changeLanguage, useStore }) => {
  const [wallet, setWallet] = useState();
  const [tokenInfo] = useState(getAccessTokenInfo(getUser()));
  const [formState, setFormState] = useState({});

  useEffect(() => {
    if(tokenInfo.active) {
        WalletService.getWallet().then((wallet) => {
            setWallet(wallet.data); 
        });
    }

  }, [tokenInfo]);

  const handleInputChange = event => {
    console.log('handleInputChange', event.target.name, event.target.value);
    const target = event.target
    const value = target.value
    const name = target.name

    setFormState((formState) => ({
      ...formState,
      [name]: value
    }));  
  };  

  return (
    <Page useStore={useStore}>
      <div className="p-4">
        <div className="row">
          <h1>E-Commerce</h1>
          <HelpAlert variant='info' textClass='text-blue' helpLink='/docs/#/woo'>
            Use this tool to choose the key to use on your WooCommerce Site. The POS key and secret are <strong>DEPOSIT ONLY</strong> and can only be used for inbound payments from your WooCommerce site using the Rapaygo API.
          </HelpAlert>
        </div>
        <div className="grid grid-cols-1 divide-y-4 divide-gray-200">
            <div className="flex mb-3">
                <div className="mt-3 w-full md:w-1/4">API URL</div>
                <div className="mt-3 p-1 w-full md:w-3/4 bg-gray-600 text-orange font-monospace font-bold rounded">{backendBaseUrl}</div>
            </div>  
            <div className="flex mb-3">
                <div className="mt-3 w-full md:w-1/4">Application URL</div>
                <div className="mt-3 p-1 w-full md:w-3/4 bg-gray-600 text-orange font-monospace font-bold rounded">{appUrl}</div>
            </div>   
            <div className="flex mb-3">
                <div className="mt-3 w-full md:w-1/4">Choose Key</div>
                <div className="mt-3 w-full md:w-3/4"><APIKeySelector onChange={(e)=>handleInputChange(e)} keySelected={formState.ecommerce_api_key} name="ecommerce_api_key"/></div>
            </div>  
        </div>

      </div>
      
    </Page>
  );
};

export default ECommerce;
