import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Alert,  Form, FloatingLabel, Spinner
} from 'react-bootstrap';

import {LightningPaymentService} from '../service/LightningPaymentService';


// I dont think this is used anymore
const InvoicePayment = ({balanceSats}) => {

    const navigate = useNavigate();

    // const [state, setState] = useState({error: null, 
    //     paymentInvoice: null,
    //     walletBalanceAvailable:false});

    // const [changed, setChanged] = useState(false);

    const [bolt11, setBolt11] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [payment, setPayment] = useState(false);

    const [formState, setFormState] = useState({
        "bolt11": "",
        "memo": ""
    });

    const handleInputChange = event => {
        setPayment(null);
        setError(null);
        const target = event.target
        const value = target.value
        const name = target.name
    
        setFormState((formState) => ({
          ...formState,
          [name]: value
        }));  
    };  


    const handlePayment = () => {
        setPayment(null);
        setError(null);
        
        setIsLoading(true);
        LightningPaymentService.sendBolt11Payment(formState.bolt11)
        .then((r)=>{
            console.log(r); 
            setIsLoading(false);
            setPayment(r.data);   
            navigate(`/invoices/${r.data.invoice_id}`); 
        }).catch((error) =>{

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);   
                setError(`There was an error processing your payment. ${error.response.data.message}`);         
            } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
                console.log(error.request);
                setError("There was an error processing your payment.");
            } else {
            // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                setError(error.message);
            }
            console.log(error.config);


            // console.log(e);
            // setError(e.message);
            setIsLoading(false);
            // let walletBalanceAvailable = false;
            // setState((state) => ({
            //     ...state,
            //     paymentInvoice: null,
            //     invoiceError: e.message,
            //     walletBalanceAvailable
            // }));
        });      
    }

    return (
        <div className="drain-wallet-section text-center" id="drain-qr">
       
            { payment && payment?.status === "OK" &&
                <><Alert className="text-left" variant="success">Success.</Alert></>}
            { payment && payment?.status === "ERROR" &&
                <><Alert className="text-left" variant="danger">Error. There was an error processing your payment.</Alert></>}
                
            { error && <><Alert className="text-left" variant="danger">{error}</Alert></>} 
            { isLoading && <Spinner animation='border'/> }
            
            <FloatingLabel className="mt-3 invoice-text" controlId="floatingTextarea2" label="enter invoice lnbc..">
                <Form.Control
                    name="bolt11"
                    onChange={(e)=>handleInputChange(e)}
                    className="invoice-text mb-2"
                    as="textarea"
                    placeholder="Enter Invoice here"
                    style={{ height: '140px' }}
                />
            </FloatingLabel>
            
            <div style={{ width: '100%' }} className="mt-1 text-left flex flex-row justify-end">
                <div variant="success" 
                    onClick={handlePayment} className="btn-common-blue">Send Payment</div>
            </div> 
        </div>
    );
}

export default InvoicePayment;