import React, { useState, useEffect  } from 'react';
import { Modal, Alert } from "react-bootstrap";

const ConfirmModal = ({showModal, actionName, actionDescription, actionCallback, actionClose, errorValue}) => {
    const [showModalLocal, setShowModalLocal] = useState(false);
    const [error, setError] = useState(true);
    
    useEffect(() => {
      console.log("show modal", showModal);
      setShowModalLocal(showModal);
    },[showModal]);

    useEffect(() => {
      console.log("we have an error", errorValue);
      setError(errorValue);
    },[errorValue]);
  
    const handleClose = () => { 
      console.log("handleClose"); 
      setShowModalLocal(false);
      if(actionClose) {actionClose()};
    };
  
    return (
      <>
      { showModalLocal && 
        <Modal show={showModalLocal} onHide={actionClose}>
          <Modal.Header>
            <Modal.Title>Confirm {actionName}</Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            { error && <Alert variant="danger">There was a problem attempting to {actionDescription}. {error} please <a href="mailto:support@rapaygo.com">contact support.</a></Alert>}
            <p><strong>Are you sure?</strong> This will {actionDescription}.</p>
          </Modal.Body>
  
          <Modal.Footer>
            <button className="bg-gray-500 hover:bg-gray-600 rounded text-white p-1" 
            onClick={handleClose}>Cancel</button>
            <button className="bg-blue-500 hover:bg-blue-600 rounded p-1 " 
            onClick={actionCallback}>Yes</button>
          </Modal.Footer>
        </Modal> }
      
      </>
    );
  
  };

  export default ConfirmModal;