import React, { useState, useEffect } from "react";

import { createSignal } from "@react-rxjs/utils"
import { bind, Subscribe } from "@react-rxjs/core"

import { useParams } from "react-router-dom";
import { Container, Row, Col, Badge, Button } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { ShoppingCart, Trash, Mail } from "react-feather";

import {ProductService} from '../../service/ProductService'
import {CartService} from '../../service/CartService'


import {formatFiat} from '../../service/WalletService'

import {PaymentItemDetails} from "../../components/PaymentItemDetails";

import "./style.css";



//now a button
const [inventoryItemsChange$, setInventoryItems] = createSignal();
const [useInventoryItems, inventoryItems$] = bind(inventoryItemsChange$, []);

function InventoryItemsButton({paymentItemId}) { 

    // const savedCartItems = useInventoryItems();

    const [paymentItem, setPaymentItem] = useState();

    useEffect(() => {
        console.log("useEffect", paymentItemId)
        ProductService.getById(paymentItemId).then(r => {
            // console.log(r.data);
            setPaymentItem(r.data);
        });
      
    }, [paymentItemId]); 

    let addItem = () => {
        CartService.addItem(paymentItem, 1, paymentItem.store_name_slug);
        setInventoryItems(CartService.cartAsList(paymentItem.store_name_slug));
    };

    return (
        <div className="mt-3">
            <Button variant="primary" onClick={(e) => addItem()}>Add Item</Button>
        </div>
      );
};

let CartItemsContainer = ({storeSlug}) => {

    const savedCartItems = useInventoryItems();

    const [showItems, setShowItems] = useState(false);
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        console.log("CartItemsContainer useEffect", savedCartItems)
        let iItems = [...savedCartItems];
        setCartItems(iItems); // make sure to set the state

    }, []);    

    const calcTotal = (items) => {
        let total = 0.0;
        for (let ival in items){
            total += items[ival].price*items[ival].quantity;
        };
        return total;
    };

    return (
        <div className="cart-col w-100">

            <Row>
                {/* <Col className="col-6 align-left">{formatFiat.format(calcTotal(cartItems))} USD</Col> */}
                <Col className="col-6 align-right">
                    <ShoppingCart onClick={() => setShowItems(!showItems)}/> 
                    <Badge className="m-1" bg="warning" text="dark">{cartItems.length}</Badge>
                    <Button className="m-1" variant="orange" size="sm">Checkout</Button>
                </Col>
            </Row>
                
            {showItems?
            <Row>
                <Col>
                <div>
                    {cartItems.map((item, index) => {
                        return (
                            <div className="mt-1 mb-1" key={index}>{item.name} ${item.price} {item.quantity} <Trash/></div> 
                        );
                    })}
                </div>
                {cartItems.length>0?<Button className="mt-1" variant="secondary" size="sm">Empty Cart</Button>:<></>}
                </Col>
            </Row>
            :<></>}
        </div>
    );

};


function CartControl({paymentItemId}) {
    const [storeSlug, setStoreSlug] = useState();
    const savedCartItems = useInventoryItems();

    useEffect(() => {
        console.log("CartControl useEffect", paymentItemId)
        ProductService.getById(paymentItemId).then(r => {
            console.log("initialize cart", r.data);
            setStoreSlug(r.data.store_name_slug);
            setInventoryItems(CartService.cartAsList(r.data.store_name_slug));

        });
    }, [paymentItemId]);


    return (
      <div className="w-100">
        {storeSlug?
        <Subscribe>
            <CartItemsContainer storeSlug={storeSlug}/>
            <PaymentItemDetails isCartItem={true} paymentItemId={paymentItemId}/>
            <InventoryItemsButton paymentItemId={paymentItemId}/>
        </Subscribe>
        :<>Loading...</>}
      </div>
    )
  }


const CartPaymentItem = () => { 

    const { id } = useParams();

    return (
    <>
        <Container className="cart-text">
             <Row className="bg-dark text-light">
                 <Col>Bitcoin payment on the Lightning Network by rapaygo.com</Col>
             </Row>
             <Row>
                 <Col className="p-0">
                     <CartControl paymentItemId={id}/>
                </Col>
             </Row>
        </Container>
    </>);
};

export default CartPaymentItem

