import React, {useState, useEffect} from "react";
//import { withLocale } from "react-easy-localization";
import { Spinner, Alert, ButtonGroup, ToggleButton } from "react-bootstrap";

import { CheckCircle } from "react-feather";
import ReactAudioPlayer from 'react-audio-player';

import { IoIosArrowBack } from "react-icons/io";
import { FaBitcoin } from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";

import Page from "../../components/Page";
import CalculatorNew from "../../components/CalculatorNew";
import VoucherDetail from "../../components/VoucherDetail";
import InvoiceDetail from "../../components/InvoiceDetail";
import HelpAlert from "../../components/HelpAlert";
import PrintableVoucherDetail from "../../components/PrintableVoucherDetail";


import PrintableInvoiceDetails from "../../components/PrintableInvoiceDetails"; 

import { fiatConvert, balConvert } from "../../service/WalletService";
import { LightningPaymentService } from "../../service/LightningPaymentService";
import { AxiosService } from "../../service/AxiosService";

import "./style.css";


/**
 * 
 * 
	"lnurl": "LNURL1DP68GURN8GHJ7ER9WESHQ6FWWFSHQCTEVAHJUCM0D5HHVVF0WA5HG6RYWFSHWTMVDE6HYMP0WD5KUEMVV5HKX4NNX4CHJ5JWVDXNG4NJT9G4J4222DZ9X7SQY287J",
	"max_withdrawable": 765,
	"min_withdrawable": 765,
	"number": 0,
	"open_time": 1655663148,
	"payment_hash": null,
	"status": "VALID",
	"title": "rapaygo POS outgoing voucher 43-TethsMtw ",
	"unique_hash": "cVs5qyRNcM4VrYQYUJSDSz",
	"updated_at": "Sun, 19 Jun 2022 18:25:48 GMT",} param0 
 * @returns 
 */
const VoucherPaymentSuccess = ({voucher}) => {

  return (
    <div className="flex-row justify-content-center flex">
        <div className="text-center flex flex-col justify-center">
          <h1>Voucher Payment Success</h1>
          <div className="flex justify-center">
            <CheckCircle size={200} color="#3aeb34" />
          </div>
          <div className="m-2">
            <div><strong>The payment was successfully sent to the receiving party.</strong></div>
            {voucher && <div>
              <div className="flex justify-center flex-row">
                <div className="bd-highlight p-2">unique hash:</div>
                <div className="bd-highlight font-mono p-2">{voucher.unique_hash}</div>
              </div>
            </div>}
          </div>
        </div>
      <ReactAudioPlayer
        src="/media/audio/583949__fenodyrie__bell-ringing-once.wav"
        autoPlay={true}
        controls={false}
      />
    </div>

  );
};


const PointOfSale = ({mode, useStore}) => {
  const { userCurrency, userLang, satsRate, effectiveBalanceMSats, 
    getVouchersUnspent, setEffectiveBalanceMSats, walletInfo, 
    unpaidVoucherSats  } = useStore();


  const radios = [
    { name: 'Lightning Network', value: 'ln' },
    { name: 'On Chain', value: 'btc' },
  ];
  

  const [loading, setLoading] = useState(false);
  const [amountFiat, setAmountFiat] = useState(0);
  const [amountSats, setAmountSats] = useState(0);
  const [ts, setTs] = useState(Date.now());
  const [stage, setStage] = useState("calculator");
  const [voucher, setVoucher] = useState();
  const [invoice, setInvoice] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [donePolling, setDonePolling] = useState(false);
  const [countdown, setCountdown] = useState(180000);
  const [showHelp, setShowHelp] = useState(true);
  const [timerId, setTimerId] = useState();
  const [calculatorEnabled, setCalculatorEnabled] = useState(true);
  const [radioValue, setRadioValue] = useState('ln');

  useEffect(() => { 
    if (stage === 'invoice') {
      setShowHelp(false);
      setTimerId(setTimeout(() => {
        if(!donePolling) {
          console.log("POLLING", mode);
          // let payment_hash = '';

          if (mode === 'receive' && invoice) {

            LightningPaymentService.getById(invoice.id)
              .then((r) => {     
                setInvoice(r.data);  
                if(r.data.status === 'PENDING') {
                  setTs(Date.now()); //keep polling
                  setCountdown(countdown - 10000);
                  if(countdown <= 0) { 
                    setError("Payment timed out");
                    handleShowPos();
                  }
                }
                if(r.data.status === 'COMPLETED') {
                  handleSuccess(r.data.status);
                }
              }).catch((err) => {
                setError(err.message);
                handleShowPos();
              });

          }

          if(mode === 'send' && voucher) {            
            LightningPaymentService.getLNURLBody(voucher.lnurl)
            .then((r) => {
              console.log("POLLING getLNURLBody",r);
              if(undefined !== r.tag && r.tag === 'withdrawRequest'){
                setTs(Date.now()); //keep polling
              } else if(undefined !== r.reason && r.reason === "Withdraw is spent.") {
                console.log("The invoice is paid",r);
                handleSuccess(r.reason);
              }
            }).catch((err) => {
              console.log("getLNURLBody ERROR", err);
              setError(err.message);
              handleShowPos();
            });
          }
          
        }     
      }, 10000));
    }
  },[ts, stage]);
  
  const handleSuccess = (message) => {
    setSuccess(message);
    setDonePolling(true);
    clearTimeout(timerId);
    getVouchersUnspent();
    setEffectiveBalanceMSats(walletInfo.balance-(unpaidVoucherSats*1000));
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
  };

  const handleShowPos = () => {
    console.log("SHOW POS");
    setDonePolling(true);
    clearTimeout(timerId);
    setShowHelp(true);
    setStage('calculator');
    // setCountdown(expires);
  };

  const handleSetRadioValue = (value) => {
    console.log("handleSetRadioValue", value);
    setRadioValue(value);
    setCalculatorEnabled(true);
  };

  const makeReceiveInvoice = (amountSats) => {  
    setAmountSats(amountSats);
    setLoading(true);
    setCountdown(180000);
    LightningPaymentService.createInvoice(amountSats, `rapaygo POS income payment invoice`)
      .then(invoice => {
        setInvoice(invoice.data);
        setLoading(false);
        setStage("invoice");
      }).catch(err => {
        let error_model = AxiosService.handleAxiosError(err);
        console.log("error on create invoice", error_model);
        
        setError(`There was an error when attempting to create this invoice. ${error_model.message} reasons: ${error_model.data.reasons}`); 
        setLoading(false); 
      });
  };

  const makeReceiveInvoiceOnchain = (amountSats) => {  
    setAmountSats(amountSats);
    setLoading(true);
    setCountdown(1800000);
    LightningPaymentService.createOnchainInvoice(amountSats, `rapaygo POS income payment invoice`)
      .then(invoice => {
        setInvoice(invoice.data);
        setLoading(false);
        setStage("invoice");
      }).catch(err => {
        let error_model = AxiosService.handleAxiosError(err);
        console.log("error on create invoice", error_model);
        
        setError(`There was an error when attempting to create this invoice. ${error_model.message} reasons: ${error_model.data.reasons}`); 
        setLoading(false); 
      });
  };

  const makeSendVoucher = (amountSats) => {
    setAmountSats(amountSats);
    setLoading(true);
    LightningPaymentService.createSendVoucher(amountSats, `rapaygo POS outgoing voucher`)
          .then(voucher => {
            setVoucher(voucher.data);
            setLoading(false);
            setStage("invoice");
        }).catch(err => {
          setError(`${err.message}. Make sure that your wallet has enough funds to send the voucher.`); 
          setLoading(false); 
        });
  };

  const handleCreateInvoice = (amountFiat) => {
    setAmountFiat(amountFiat);

    if (radioValue === 'ln') {
      if(mode === "receive" || mode === "payment"){ 
        makeReceiveInvoice(fiatConvert(satsRate, amountFiat).sats);
        setDonePolling(false);
      }
  
      if (mode === "send") {
        makeSendVoucher(fiatConvert(satsRate, amountFiat).sats);
        setDonePolling(false);
      }
    }

    if (radioValue === 'btc') {
      if(mode === "receive" || mode === "payment"){ 
        makeReceiveInvoiceOnchain(fiatConvert(satsRate, amountFiat).sats);
        setDonePolling(false);
      }     
    }



  };
  
  
  return (
    <>
      { mode === "send" || mode === "receive" ?
      <Page useStore={useStore}>
        <div className="p-4 justify-content-center mt-2">
          {loading && <div className="justify-content-center d-flex"><Spinner animation="border"/></div>}
          { showHelp && <HelpAlert variant='info' textClass='text-blue' helpLink='/docs/#/pos'>
            <div className="flex">
                <div>Enter the amount that you would like to {mode} in {userCurrency}, then create the invoice. A customer can then scan as needed.  {mode === "receive" && <span className="font-bold">NOTE: Point of sale is an "on demand payment" and invoices can expire. </span>} Please read the help docs for more info.</div>       
            </div></HelpAlert>}
          {error && <Alert variant="danger">{error}</Alert>}
          {stage === 'calculator' && 
            <div>
              {mode === 'send' ? <div className="flex-row justify-content-center d-flex">
                Available Balance: {Intl.NumberFormat(userLang,
                      {style:'currency', currency: userCurrency }).format(balConvert(satsRate, effectiveBalanceMSats).fiat)} {userCurrency}
              </div> : <div className="flex-row justify-content-center d-flex m-1 p-1"></div> }
              <div className="flex-row justify-content-center d-flex mb-1">

                {mode === 'receive' && <ButtonGroup id="select-rail" className="flex flex-row">
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      className="flex flex-row"
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      variant={idx % 2 ? 'outline-ln' : 'outline-onchain'}
                      name="radio"
                      value={radio.value}
                      checked={radioValue === radio.value}
                      onChange={(e) => handleSetRadioValue(e.currentTarget.value)}
                    >
                      <div className="flex flex-row">{radio.value === 'btc' && <FaBitcoin className="text-2xl mr-1"/>} 
                      {radio.value === 'ln' && <BsLightningFill className="text-2xl mr-1"/>} 
                      {radio.name}</div>
                    </ToggleButton>
                  ))}
                </ButtonGroup>}


              </div>
              <div className="flex-row justify-content-center d-flex">
                <CalculatorNew userCurrency={userCurrency} mode={mode} handleSave={handleCreateInvoice} useStore={useStore} handleError={handleError} enabled={calculatorEnabled} railType={radioValue}/>
              </div>
            </div>
            }

          {stage === 'invoice' && <>
            { !donePolling &&
            <div className="flex-row">
              {voucher && 
                <>
                  <VoucherDetail userCurrency={userCurrency} userLang={userLang} amountSats={amountSats} amountFiat={amountFiat} voucher={voucher} satsRate={satsRate}/>
                  <PrintableVoucherDetail voucher={voucher} satsRate={satsRate}/>
                </>
              }
              {invoice && 
              <div className="flex flex-col w-full">
                <InvoiceDetail userCurrency={userCurrency} userLang={userLang} invoice={invoice} satsRate={satsRate}/>
                <div><PrintableInvoiceDetails userCurrency={userCurrency} userLang={userLang} invoice={invoice} satsRate={satsRate}/></div>
              </div>}             
            </div>
            }
            {/* @TODO we need to work on modes here, we are using invoice weird, dont allow print */}
            {mode === 'receive' && donePolling && success && <InvoiceDetail userCurrency={userCurrency} userLang={userLang} invoice={invoice} satsRate={satsRate}/>} 

            {mode === 'send' && donePolling && success && <VoucherPaymentSuccess voucher={voucher}/>}

            <div className="m-3 flex flex-row justify-center w-full">
              <div><button className="btn-common-blue" onClick={()=>handleShowPos()}><IoIosArrowBack/> Back to {mode} POS</button></div>            
            </div>
           </>}
        </div>
        
      </Page>
      : <></>}

    </>
 );
};

export default PointOfSale;
