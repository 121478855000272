import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
// import { LocaleProvider } from "react-easy-localization";
import TagManager from "react-gtm-module";
import lodash from "lodash";

import create from "zustand";
import { persist } from "zustand/middleware";

import Index from "./pages/Index";
import Login from "./pages/Login";
import Wallet from "./pages/Wallet";
import Vouchers from "./pages/Vouchers";
import Invoices from "./pages/Invoices";
import PayInvoice from "./pages/PayInvoice";
import PointOfSale from "./pages/PointOfSale";
import Signup from "./pages/Signup";
import Pricing from "./pages/Pricing";
import UserSettings from "./pages/UserSettings";
import ProductEditor from "./pages/ProductEditor";
import Voucher from "./pages/Voucher";

import CartCheckout from "./pages/CartCheckout";
import EmailVerify from "./pages/EmailVerify";
import DrainWallet from "./pages/DrainWallet";
import ECommerce from "./pages/ECommerce";

import PaymentSuccess from "./pages/PaymentSuccess";
import ResetPassword from "./pages/ResetPassword";
import ProductItems from "./pages/ProductItems";

import Customers from "./pages/Customers";
import Customer from "./pages/Customer";

import { getUser, getAccessTokenInfo } from "./service/auth";
import { WalletService } from "./service/WalletService";
import { UserSettingService } from "./service/UserSettingService";
import { VoucherService } from "./service/VoucherService";
import ProductViewer from "./pages/ProductViewer";
import CartPaymentItem from "./pages/CartPaymentItem";
import HowItWorks from "./pages/HowItWorks";
import Privacy from "./pages/Privacy";

import "./App.css";

import { currencyLang } from "./env";
import Orders from "./pages/Orders";
import InvoiceInfo from "./pages/InvoiceInfo";
import Addresses from "./pages/Addresses";
import Order from "./pages/Order";
import SendBolt11 from "./pages/SendBolt11";

const useStore = create(
  persist(
    (set, get) => ({
      isLoading: false,
      setIsLoading: (isLoading) => set({ isLoading }),
      walletInfo: {},
      getWalletInfo: async () => {
        const response = await WalletService.getWallet();
        set({ walletInfo: response.data });
      },
      setWalletInfo: async (nWallet) => {
        const response = await WalletService.put(nWallet);
        set({ walletInfo: response.data });
      },
      apiVersion: {},
      getApiVersion: async () => {
        const response = await WalletService.getApiVersion();
        set({ apiVersion: response.data });
      },
      selectedPath: "/wallet",
      setSelectedPath: (selectedPath) => set({ selectedPath }),
      userCurrency: "USD",
      userLang: "en-US",
      getUserSettings: async () => {
        const response = await UserSettingService.getAll();
        // set({ userSettings: response.data });
        let currency = response.data.find(
          (setting) => setting.name === "i8n_currency"
        );
        set({ userCurrency: currency.value });
        set({ userLang: currencyLang[currency.value] });
        console.log(currency.value, currencyLang[currency.value]);
      },
      satsRate: {
        pair: ["USD", "SAT"],
        rate: [1, 2571.6940069885786],
      },
      getSatsRate: async (currencyRate) => {
        const response = await WalletService.getSatsQuote(currencyRate);
        set({ satsRate: response.data });
      },
      vouchersUnspent: [],
      unpaidVoucherSats: 0,
      getVouchersUnspent: async () => {
        const response = await VoucherService.getAllUnspent();
        set({ vouchersUnspent: response.data });
        let sum_unpaid = lodash.sumBy(response.data, "max_withdrawable");
        set({ unpaidVoucherSats: sum_unpaid });
      },
      effectiveBalanceMSats: 0,
      setEffectiveBalanceMSats: (effectiveBalanceMSats) =>
        set({ effectiveBalanceMSats }),
      productCart: [],
      emptyCart: () => set({ productCart: [] }),
      getCartSize: () => {
        return lodash.sumBy(get().productCart, "qty");
      },
      removeProductFromCart: (product) => {
        const isItemInCart = get().productCart.find(
          (productInCart) => productInCart.id === product.id
        );
        if (isItemInCart && isItemInCart.qty > 1) {
          set((state) => ({
            ...state,
            productCart: state.productCart.map((item) => {
              console.log("", item.id, product.id);
              return item.id === product.id
                ? {
                    ...item,
                    qty:
                      typeof item?.qty === "number" ? item.qty - 1 : item.qty,
                  }
                : item;
            }),
          }));
        } else {
          let cartCopy = [...get().productCart];
          lodash.remove(cartCopy, (item) => item.id === product.id);
          set((state) => ({
            ...state,
            productCart: cartCopy,
          }));
        }
      },
      addProductToCart: (product) => {
        const isItemInCart = get().productCart.find(
          (productInCart) => productInCart.id === product.id
        );
        if (isItemInCart) {
          set((state) => ({
            ...state,
            productCart: state.productCart.map((item) => {
              console.log("", item.id, product.id);
              return item.id === product.id
                ? {
                    ...item,
                    qty:
                      typeof item?.qty === "number" ? item.qty + 1 : item.qty,
                  }
                : item;
            }),
          }));
        } else {
          // add if doesnt exist
          set((state) => ({
            ...state,
            productCart: [
              ...state.productCart,
              {
                ...product,
                qty: 1,
              },
            ],
          }));
        }
      },
    }),
    {
      name: "rapaygo-storage", // unique name
      getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
    }
  )
);

// const [useStore, setUseStore] = useState();

const tagManagerArgs = {
  gtmId: "G-EVDEHPQG2X",
};
TagManager.initialize(tagManagerArgs);

const PrivateRoute = () => {
  const [tokenInfo] = useState(getAccessTokenInfo(getUser()));
  return tokenInfo.active ? <Outlet /> : <Navigate to="/" />;
};

const PrivateRoleRoute = ({ role }) => {
  const [tokenInfo] = useState(getAccessTokenInfo(getUser()));
  let valid =
    tokenInfo.active && lodash.includes(tokenInfo.payload.scopes, role);
  return valid ? <Outlet /> : <Navigate to="/" />;
};

export default function App() {
  const { getWalletInfo, getApiVersion, apiVersion } = useStore();

  useEffect(() => {
    getWalletInfo();
    getApiVersion();
    console.log("api version", apiVersion);
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/cart" element={<PrivateRoute />}>
            <Route
              exact
              path="/cart"
              element={<CartCheckout useStore={useStore} />}
            />
          </Route>
          <Route exact path="/wallet" element={<PrivateRoute />}>
            <Route
              exact
              path="/wallet"
              element={<Wallet useStore={useStore} />}
            />
          </Route>
          <Route exact path="/ecommerce" element={<PrivateRoute />}>
            <Route
              exact
              path="/ecommerce"
              element={<ECommerce useStore={useStore} />}
            />
          </Route>
          <Route exact path="/items" element={<PrivateRoute />}>
            <Route
              exact
              path="/items"
              element={<ProductItems useStore={useStore} />}
            />
          </Route>
          <Route exact path="/vouchers" element={<PrivateRoute />}>
            <Route
              exact
              path="/vouchers"
              element={<Vouchers useStore={useStore} />}
            />
          </Route>
          <Route exact path="/invoices" element={<PrivateRoute />}>
            <Route
              exact
              path="/invoices"
              element={<Invoices useStore={useStore} />}
            />
          </Route>

          <Route exact path="/invoices/:id" element={<PrivateRoute />}>
            <Route
              exact
              path="/invoices/:id"
              element={<InvoiceInfo useStore={useStore} />}
            />
          </Route>

          <Route exact path="/item/edit/:id" element={<PrivateRoute />}>
            <Route
              exact
              path="/item/edit/:id"
              element={<ProductEditor useStore={useStore} />}
            />
          </Route>
          <Route exact path="/item/create" element={<PrivateRoute />}>
            <Route
              exact
              path="/item/create"
              element={<ProductEditor useStore={useStore} />}
            />
          </Route>
          <Route exact path="/item/details/:id" element={<PrivateRoute />}>
            <Route
              exact
              path="/item/details/:id"
              element={<ProductViewer useStore={useStore} />}
            />
          </Route>
          <Route exact path="/voucher/:id" element={<PrivateRoute />}>
            <Route
              exact
              path="/voucher/:id"
              element={<Voucher useStore={useStore} />}
            />
          </Route>
          <Route path="/pos">
            <Route path="receive" element={<PrivateRoute />}>
              <Route
                path="/pos/receive"
                element={<PointOfSale mode="receive" useStore={useStore} />}
              />
            </Route>
            <Route path="send" element={<PrivateRoleRoute role="wallet.own" />}>
              <Route
                path="/pos/send"
                element={<PointOfSale mode="send" useStore={useStore} />}
              />
            </Route>
            <Route
              path="/pos/payment"
              element={<PointOfSale mode="payment" useStore={useStore} />}
            />
          </Route>
          <Route path="/send">
            <Route path="/send/bolt11" element={<PrivateRoute />}>
              <Route
                path="/send/bolt11"
                element={<SendBolt11 useStore={useStore} />}
              />
            </Route>

          </Route>

          <Route
            exact
            path="/drainwallet"
            element={<PrivateRoleRoute role="wallet.own" />}
          >
            <Route
              exact
              path="/drainwallet"
              element={<DrainWallet useStore={useStore} />}
            />
          </Route>
          <Route exact path="/settings" element={<PrivateRoute />}>
            <Route
              exact
              path="/settings"
              element={<UserSettings useStore={useStore} />}
            />
          </Route>
          <Route exact path="/orders" element={<PrivateRoute />}>
            <Route
              exact
              path="/orders"
              element={<Orders useStore={useStore} />}
            />
          </Route>

          <Route exact path="/order/:id" element={<PrivateRoute />}>
            <Route
              exact
              path="/order/:id"
              element={<Order useStore={useStore} />}
            />
          </Route>

          <Route exact path="/customers" element={<PrivateRoute />}>
            <Route
              exact
              path="/customers"
              element={<Customers useStore={useStore} />}
            />
          </Route>

          <Route exact path="/customer/:id" element={<PrivateRoute />}>
            <Route
              exact
              path="/customer/:id"
              element={<Customer useStore={useStore} />}
            />
          </Route>

          <Route exact path="/addresses" element={<PrivateRoute />}>
            <Route
              exact
              path="/addresses"
              element={<Addresses useStore={useStore} />}
            />
          </Route>
          <Route
            exact
            path="/item/cart/:id"
            element={<CartPaymentItem useStore={useStore} />}
          />
          <Route
            exact
            path="/emailverify/:status"
            element={<EmailVerify useStore={useStore} />}
          />
          <Route
            exact
            path="/pricing"
            element={<Pricing useStore={useStore} />}
          />
          <Route
            exact
            path="/signup"
            element={<Signup useStore={useStore} />}
          />
          <Route
            exact
            path="/privacy"
            element={<Privacy useStore={useStore} />}
          />
          <Route path="/login" element={<Login useStore={useStore} />} />
          <Route
            path="/reset"
            element={<ResetPassword useStore={useStore} />}
          />
          <Route
            path="/howitworks"
            element={<HowItWorks useStore={useStore} />}
          />
          <Route
            path="/reset?reset_token=:resetToken"
            element={<ResetPassword useStore={useStore} />}
          />
          <Route
            path="/invoice_payment/pay/:payment_hash"
            element={<PayInvoice useStore={useStore} />}
          />
          <Route
            path="/lnurl/success"
            element={<PaymentSuccess useStore={useStore} />}
          />
          <Route
            path="/payment/success"
            element={<PaymentSuccess useStore={useStore} />}
          />
          <Route path="/" element={<Index useStore={useStore} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

// export default function App() {
//   return (
//     <h1 className="text-3xl font-bold">
//       Hello world!
//     </h1>
//   )
// }

// export default App;
