import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
//import { withLocale } from "react-easy-localization";
import { Row, Alert, Modal, Badge, Spinner } from "react-bootstrap";
import lodash from 'lodash';
// import { ShoppingCart } from "react-feather";

import Page from "../../components/Page";
import HelpAlert from "../../components/HelpAlert";

import ProductList from "../../components/ProductList";
import { ProductService } from "../../service/ProductService";
import {FiShoppingCart} from "react-icons/fi";

import {
  getUser,
  getAccessTokenInfo
} from '../../service/auth';

import "./style.css";

const FileUpload = ({setShowModal}) => {
  const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	const handleSubmission = () => {
		const formData = new FormData();

		formData.append('File', selectedFile);
    setIsLoading(true);
    ProductService.uploadProductsCSVFile(formData)
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          console.log("File uploaded successfully.");
          setShowModal(false);
          setIsLoading(false);
          window.location.reload();

        }
      })
      .catch((error) => {
        setError(error.message);
        console.log("error", error);
        setIsLoading(false);
      });
	};

  return(
    <div>
      { error && <Alert variant="danger">There was a problem attempting to upload products. {error} please <a href="mailto:support@rapaygo.com">contact support.</a></Alert>}
       <input className="rounded bg-slate-300 p-2" type="file" name="file" onChange={changeHandler} />
       {isFilePicked ? (
         <div>
           <p>Filename: {selectedFile.name}</p>
           <p>Filetype: {selectedFile.type}</p>
           <p>Size in bytes: {selectedFile.size}</p>
           <p>
             lastModifiedDate:{' '}
             {selectedFile.lastModifiedDate.toLocaleDateString()}
           </p>
         </div>
       ) : (
         <p>Select a file to show details</p>
       )}
       <div className="flex flex-row justify-end">
         <button className="btn-common-gray">Cancel</button>
         <button className="btn-common-blue" onClick={handleSubmission}>Submit</button>
       </div>
       {isLoading && <Spinner animation="border"/>}
     </div>
   )
};

const ProductUploadModal = ({showModal, setShowModal}) => {

  return (
    <>
    { showModal && 
      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title>Upload Products From CSV</Modal.Title>
        </Modal.Header>

        <Modal.Body>       
          <FileUpload setShowModal={setShowModal}/>
        </Modal.Body>

        <Modal.Footer>
          {/* <button className="bg-gray-500 hover:bg-gray-600 rounded text-white p-1" 
          onClick={setShowModal(false)}>Cancel</button> */}
          {/* <button className="bg-blue-500 hover:bg-blue-600 rounded p-1 " 
          onClick={actionCallback}>Yes</button> */}
        </Modal.Footer>
      </Modal> }
    
    </>
  );

};

const ProductItems = ({useStore}) => {

  const { productCart, getCartSize  } = useStore();
  const navigate = useNavigate();
  const [tokenInfo] = useState(getAccessTokenInfo(getUser()));

  const [showModalLocal, setShowModalLocal] = useState(false);

  
  return (
    <Page useStore={useStore}>
      <div className='p-4'>
        <div className="p-1 flex justify-between">        
          <div><h1>Product Items</h1></div>
          <div className="flex md:max-h-10">
            {lodash.includes(tokenInfo.payload.scopes, 'wallet.own') &&
              <>
              <button className="p-1 rounded bg-blue hover:bg-blue-600 m-1 flex" onClick={() => navigate('/item/create')}>New Product</button>
              <button className="p-1 rounded bg-blue hover:bg-blue-600 m-1 flex" onClick={() => setShowModalLocal(true)}>Import Products</button>
              </>

            }
            {productCart.length > 0 && <>
              <button onClick={() => navigate('/cart')} className="p-1 rounded bg-orange hover:bg-orange-600 m-1 flex">Product Cart <FiShoppingCart className="mr-1"/> <Badge className="text-dark" bg="light">{getCartSize()}</Badge></button>
            </>}
          </div>
        </div>
        {/* <div className="p-1"><FileUpload/></div> */}
        <div>
          <HelpAlert variant='info' textClass='text-blue' 
            helpLink='/docs/#/products'>Product items are your "Scan to pay" items that can be used to automatically receive payment with no activity required for the receiver. You can also use products to collect "ad hoc" payments using the cart.</HelpAlert>
        </div>
        <div><ProductList useStore={useStore}/></div>
      </div>
      <div><ProductUploadModal showModal={showModalLocal} setShowModal={setShowModalLocal}/></div>


    </Page>
  );
};

export default ProductItems;