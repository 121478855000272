import React from 'react';
import { CustomerItem } from './CustomersTable';
import { OrderLines, OrderLinesSummary } from './OrderLines';
import moment from 'moment'
/**
 * 
 * 
 * {
	"created_at": "Sat, 24 Sep 2022 09:36:24 GMT",
	"customer": {
		"company_email": "clay@rapaygo",
		"company_name": "rapaygo LLC",
		"contact_email": null,
		"contact_name": null,
		"created_at": "Fri, 23 Sep 2022 15:45:23 GMT",
		"id": 5,
		"notes": null,
		"phone": null,
		"updated_at": "Fri, 23 Sep 2022 15:45:23 GMT",
		"wallet_id": "9"
	},
	"customer_id": 5,
	"id": 4,
	"items": [{
		"created_at": "Sat, 24 Sep 2022 09:36:24 GMT",
		"id": 2,
		"order_id": 4,
		"payment_item": {
			"categories": [],
			"currency": "USD",
			"description": "Atomic Fireball super intense cinnamon candy",
			"description_n": "<strong>Revolutionary Flavor</strong>\r\\nThe original super intense cinnamon candy! What makes our flaming hot and spicy sweet candies so dang fuego? Our secret is the use of capsaicin, the naturally-occurring compound that makes jalapenos and chilis spicy. At around 3500 Scoville Heat units, we dare you to eat more than one\u2014how much hot can you handle?\r\\n\r\\n<strong>About Atomic Fireball</strong>\r\\nNello Ferrara, son of Salvatore Ferrara, the creator of the Ferrara Candy Company, invented the Atomic Fireball in 1954. He wanted to bring a spicy candy to the masses. To begin, they could only manufacture 200 cases per day. Within the first three weeks, orders exceeded 50,000 cases per day. The Atomic Fireball gained global recognition shortly and has maintained its status ever since.",
			"external_id": "d826a_814",
			"external_system": "woocommerce",
			"id": 86,
			"images": [{
				"data_url": "https://s3.us-west-2.amazonaws.com/dev.rapaygo.com/uploaded_images/533f6808-db3c-4669-a269-5cdb55ffb640.png",
				"id": 11
			}],
			"name": "Ferrara Atomic Fireball, Individually Wrapped",
			"payment_request": "This is a payment item, but it has no payment request",
			"price": 0.25,
			"publish_on": "2022-09-21 13:51:17.107376",
			"slug": "ferrara-atomic-fireball-individually-wrapped-623977",
			"stock": 0,
			"tags": []
		},
		"payment_item_id": 86,
		"quantity": 3,
		"updated_at": "Sat, 24 Sep 2022 09:36:24 GMT"
	}, {
		"created_at": "Sat, 24 Sep 2022 09:36:24 GMT",
		"id": 3,
		"order_id": 4,
		"payment_item": {
			"categories": [],
			"currency": "USD",
			"description": "",
			"description_n": "<div class=\"mb3\">\r\\n<div class=\"dangerous-html mb3\">Tootsie Rolls are the iconic treat that launched America\u2019s favorite candy company. Tootsie Roll offers a perfectly balanced cocoa taste, lined with a subtle, fruit-flavored undertone-all incorporated into a delicious treat. Tootsie Roll Midgees are perfect little bite size treat. Perfect for theme parties, parades, birthday parties, candy buffets, showers and more. Great value for a giveaway to trick or treaters at Halloween. These Tootsie Roll candies contain calcium and iron and have zero cholesterol. Tootsie Roll Midgees are only 11 calories per piece. Peanut Free and Gluten Free</div>\r\\n</div>\r\\n<div class=\"mb3\">\r\\n<div class=\"dangerous-html mb3\">\r\\n\r\\n<strong>Tootsie Roll Chocolate Midgees, 15 Oz:</strong>\r\\n<ul>\r\\n \t<li>Classic favorite in a small size</li>\r\\n \t<li>Chewy and chocolaty</li>\r\\n \t<li>No cholesterol</li>\r\\n \t<li>Tootsie Roll Midgees contain artificial and natural flavors</li>\r\\n \t<li>Has iron and calcium</li>\r\\n \t<li>Sweet treat for taking on trips or to parties</li>\r\\n \t<li>Peanut Free and Gluten Free</li>\r\\n</ul>\r\\n</div>\r\\n</div>",
			"external_id": "d826a_37",
			"external_system": "woocommerce",
			"id": 87,
			"images": [{
				"data_url": "https://s3.us-west-2.amazonaws.com/dev.rapaygo.com/uploaded_images/bbf0fa1b-9b39-418c-9600-e1590cdcc33a.png",
				"id": 12
			}],
			"name": "Tootsie Roll Chocolate Midgees",
			"payment_request": "This is a payment item, but it has no payment request",
			"price": 0.25,
			"publish_on": "2022-09-21 13:51:29.323525",
			"slug": "tootsie-roll-chocolate-midgees-254042",
			"stock": 0,
			"tags": []
		},
		"payment_item_id": 87,
		"quantity": 2,
		"updated_at": "Sat, 24 Sep 2022 09:36:24 GMT"
	}, {
		"created_at": "Sat, 24 Sep 2022 09:36:24 GMT",
		"id": 4,
		"order_id": 4,
		"payment_item": {
			"categories": [],
			"currency": "USD",
			"description": "",
			"description_n": "Lens material: resin\r\\nStyle: Vintage\r\\nFrame material: plastic + metal\r\\n\r\\nRetro Metal Punk Steam Flip Sunglasses Flip Sunglasses\r\\n\r\\n<img src=\"https://cc-west-usa.oss-accelerate.aliyuncs.com/15299424/158755490115.png\" />\r\\n\r\\n<img src=\"https://cc-west-usa.oss-accelerate.aliyuncs.com/15299424/1946819808556.png\" />",
			"external_id": "d826a_502",
			"external_system": "woocommerce",
			"id": 90,
			"images": [{
				"data_url": "https://s3.us-west-2.amazonaws.com/dev.rapaygo.com/uploaded_images/9ab3df9f-a5b4-45f1-abea-01d93867ff22.png",
				"id": 15
			}],
			"name": "Style: Gold red - Retro Metal Punk Steam Flip Sunglasses",
			"payment_request": "This is a payment item, but it has no payment request",
			"price": 18.54,
			"publish_on": "2022-09-21 18:31:25.325648",
			"slug": "style-gold-red-retro-metal-punk-steam-flip-sunglasses-36c1fa",
			"stock": 500,
			"tags": []
		},
		"payment_item_id": 90,
		"quantity": 1,
		"updated_at": "Sat, 24 Sep 2022 09:36:24 GMT"
	}],
	"order_status": "processed",
	"payment_status": "CREATED",
	"tracking_number": null,
	"updated_at": "Sat, 24 Sep 2022 09:36:24 GMT"
}
 * 
 * 
 * @param {} param0 
 * @returns 
 */
const OrderDetail = ( {order, satsRate, walletInfo, isPrintable=false}) => {

	const dueDate = () => {
		// const
		let created = moment(order.created_at); 
		return created.add(order.terms_qty, order.terms_period).format("dddd, MMMM Do YYYY");
	};

    return(<>
        {order && <div>
			{/* {isPrintable && <div>
				<div className='uppercase font-bold'>From</div>
                <CustomerItem customer={order.customer} isPrintable={isPrintable}/>
            </div>} */}
			<div className='flex flex-col justify-start'>
				{/* <>{JSON.stringify(walletInfo)}</> */}
				
				{isPrintable && 
					<>				
					<div className='border-solid border-b-2 border-gray-300 text-2xl font-bold'>{walletInfo.store_name}</div>
					<div className='text-xs uppercase font-monospace'>{walletInfo.company_address ? `${walletInfo.company_address.street_address} ${walletInfo.company_address.city} ${walletInfo.company_address.state} ${walletInfo.company_address.zip_code}` : ""}</div>
					<div className='text-xs lowercase font-monospace'>{walletInfo.billing_email ? `${walletInfo.billing_email}` : ""}</div>
					<div className='text-xs uppercase font-monospace border-solid border-b-2 border-gray-300'>{walletInfo.billing_phone ? `${walletInfo.billing_phone}` : ""}</div>					
					<div className='text-xs uppercase font-monospace border-solid border-b-2 border-gray-300'>INVOICE DATE: {moment().format("dddd, MMMM Do YYYY")}</div>		
					</>
				}
				<div className='text-xs uppercase font-monospace border-solid border-b-2 border-gray-300'>ORDER NO: {order.id}</div>
				<div className='text-xs uppercase font-monospace border-solid border-b-2 border-gray-300'>TERMS: {order.terms_qty} {order.terms_period} </div>
				<div className='text-xs uppercase font-monospace border-solid border-b-2 border-gray-300'>DUE: {dueDate()}</div>
				{order.customer && order.customer.sales_rep_pos_key_name &&
				 <div className='text-xs uppercase font-monospace border-solid border-b-2 border-gray-300'>{order.customer.sales_rep_pos_key_name}</div>}
				
			</div>
            <div className='mt-2'>
				{isPrintable && <div className='uppercase font-bold'>CUSTOMER:</div>}
                <CustomerItem customer={order.customer} isPrintable={isPrintable} isEditable={false}/>
            </div>
            <div className='mt-3'>
				<h3>Order Lines</h3>
                <OrderLines orderLines={order.items} satsRate={satsRate}/>
                <OrderLinesSummary orderLines={order.items} satsRate={satsRate}/>
            </div>
        </div>}


    </>);

};

  
export default OrderDetail;