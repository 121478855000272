import React, { useEffect, useState } from 'react';
import create from 'zustand';
import { evaluate } from 'mathjs';
import "./style.css"

const useStore = create((set) => ({
    posAmount: '0',
    setPosAmount: (posAmount) => set({ posAmount }),
}));

const CalculatorResult = ({ result }) => {
    return (
        <div className="c-result">
            <p className="calculator-result-amount">{result}</p>
        </div>
    )
};

const CalculatorButtons = ({ mode, handleClick, enabled=true, railType='ln' }) => {
    const [invoiceBtnClass, setInvoiceBtnClass] = useState(`c-btn c-btn-create ${mode}`);
    
    const [buttonText, setButtonText] = useState();

    useEffect(() => { 
        if (mode === "send") {
            setButtonText(`create ${railType} voucher`)
        } else {
            setButtonText(`create ${railType} invoice`)
        }

        if (enabled) {
            setInvoiceBtnClass(`c-btn c-btn-create ${mode}`)
        } else {
            setInvoiceBtnClass(`c-btn c-btn-create disabled`)
        }

      },[mode, enabled, railType]);
    

    const handleClickEnabled = (letter) => {
        if (enabled) { handleClick(letter); }      
    };
         
    return (
        <>
            <div className='buttons'>
                <div className="d-flex flex-row c-row1">
                    <div value="c" className="c-btn c-btn-clear" onClick={(e) => handleClickEnabled("c")}>C</div>
                    <div value="+/-" className="c-btn c-btn-action" onClick={(e) => handleClickEnabled("+/-")}>+/-</div>
                    <div value="%" className="c-btn c-btn-action" onClick={(e) => handleClickEnabled("%")}>%</div>
                    <div value="/" className="c-btn c-btn-calc" onClick={(e) => handleClickEnabled("/")}>÷</div>
                </div>
                <div className="d-flex flex-row c-row2">
                    <div value="7" className="c-btn" onClick={(e) => handleClickEnabled("7")}>7</div>
                    <div value="8" className="c-btn" onClick={(e) => handleClickEnabled("8")}>8</div>
                    <div value="9" className="c-btn" onClick={(e) => handleClickEnabled("9")}>9</div>
                    <div value="*" className="c-btn c-btn-calc" onClick={(e) => handleClickEnabled("*")}>X</div>
                </div>
                <div className="d-flex flex-row c-row3">
                    <div value="4" className="c-btn" onClick={(e) => handleClickEnabled("4")}>4</div>
                    <div value="5" className="c-btn" onClick={(e) => handleClickEnabled("5")}>5</div>
                    <div value="6" className="c-btn" onClick={(e) => handleClickEnabled("6")}>6</div>
                    <div value="-" className="c-btn c-btn-calc" onClick={(e) => handleClickEnabled("-")}>-</div>
                </div>
                <div className="d-flex flex-row c-row4">
                    <div value="1" className="c-btn" onClick={(e) => handleClickEnabled("1")}>1</div>
                    <div value="2" className="c-btn" onClick={(e) => handleClickEnabled("2")}>2</div>
                    <div value="3" className="c-btn" onClick={(e) => handleClickEnabled("3")}>3</div>
                    <div value="+" className="c-btn c-btn-calc" onClick={(e) => handleClickEnabled("+")}>+</div>
                </div>
                <div className="d-flex flex-row c-row5">
                    <div value="0" className="c-btn-0" onClick={(e) => handleClickEnabled("0")}>0</div>
                    <div value="." className="c-btn" onClick={(e) => handleClickEnabled(".")}>.</div>
                    <div value="=" className="c-btn c-btn-calc" onClick={(e) => handleClickEnabled("=")}>=</div>
                </div>
                <div className="d-flex flex-row c-row5">
                    <div value="0" className={invoiceBtnClass} onClick={(e) => handleClickEnabled("create")}><span className='create-txt'>{buttonText}</span></div>
                </div>
            </div>

        </>
    )
};

const CalculatorNew = ({ mode, handleSave, handleError, userCurrency='USD', enabled=true, railType='ln'  }) => {

    const [result, setResult] = useState("0");
    
    const setPosAmount = useStore((state) => state.setPosAmount);
    const posAmount = useStore((state) => state.posAmount);

    const handleButtonClick = (buttonValue) => {
        handleError(null);
        switch (buttonValue) {
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
            case '.':
            case '+':
            case '-':
            case '/':
            case '*':
                // console.log("result:",result);
                if (result === "0") {
                    setResult(buttonValue);
                } else {
                    setResult(result + buttonValue);
                }
                break;
            case '=':
                let c_val = evaluate(result);
                setResult(c_val);
                setPosAmount(parseFloat(c_val));
                break;
            case 'c':
                setResult('0');
                break;
            case '+/-':
                setResult(parseInt(result, 10) * -1);
                break;
            case '%':
                setResult(parseFloat(result, 10) / 100);
                break;
            case 'create':               
                setPosAmount(parseFloat(result));
                console.log("posAmount:", posAmount, parseFloat(result) );
                if(parseFloat(result) !== 0){
                    handleSave(parseFloat(result));
                } else {
                    handleError("Please enter amount, 0 not allowed");
                }
                break;
            default:
                break;
        }
    };
    return (
        <>
            <div className="caculator-body bd-highlight mb-3 justify-content-center">               
                <div className="p-2">
                    <div>Enter amounts in {userCurrency}</div>
                    <CalculatorResult result={result} />
                </div>
                <div className="p-2"><CalculatorButtons handleClick={handleButtonClick} mode={mode} enabled={enabled} railType={railType}/></div>
            </div>
        </>
    )
};
export default CalculatorNew;