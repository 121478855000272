import React, { useRef, useEffect, useState } from 'react'

import { useReactToPrint } from 'react-to-print'
import { Spinner } from 'react-bootstrap';
import QRCode from "react-qr-code";
import { FiCopy, FiCheckCircle, FiPrinter } from "react-icons/fi";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { ProductService } from '../../service/ProductService'


import './style.css';

export const HybridPaymentItemDetails = ({ paymentItemId }) => {
    const [paymentItem, setPaymentItem] = useState();
    useEffect(() => {
        console.log("useEffect", paymentItemId)
        ProductService.getById(paymentItemId).then(r => {
            console.log(r.data);
            setPaymentItem(r.data);
        });

    }, [paymentItemId]);

    return(
        <div className='mb-2'>
            <PrintablePaymentItemDetails paymentItem={paymentItem}/> 
        </div>
    )
};

export const PrintablePaymentItemDetails = ({ paymentItem }) => {
    const componentRef = useRef();
    const handlePrint =  useReactToPrint({
      content: () => componentRef.current,
    });

    const hp = () => {
        console.log("handlePrint")
        handlePrint();
    };
  
    return (
        <>{paymentItem && 
            
            <div className='flex flex-col justify-center'>
                <div className='w-full lg:w-3/4 xl:w-1/2 p-2 border-2 rounded-md'>
                    <div><PaymentItemDetails paymentItem={paymentItem}/></div>
                    <div><PrintablePaymentCard paymentItem={paymentItem} ref={componentRef}/></div>
                    <div className='justify-center md:justify-end flex sm:justify-center w-full'>
                        <button className='w-fit btn-common-blue' onClick={()=>hp()}><FiPrinter className='mr-1'/>Print</button>
                    </div>
                </div>
        
            </div>
        }
        </>
    );
};


export const PrintablePaymentCard = React.forwardRef(({paymentItem}, ref) => {
    return (
    <div className="p-4 hidden print:block bg-white" ref={ref}>
        {paymentItem && 
            <div className="flex w-fit flex-col p-4">
                <div><h1 className="text-2xl">{paymentItem.name}</h1></div>
                <div className="flex flex-row">
                <div className="m-1 w-fit">
                    {paymentItem.images && paymentItem.images.length > 0 &&
                        <img className="" src={paymentItem.images[0]['data_url']} alt="" />}
                </div>
                <div className="m-1 flex flex-col bg-orange-300">
                    <div className="mb-1">{paymentItem.description}</div>
                    <div className="mb-1 flex flex-row justify-start">
                        <PriceBox price={paymentItem.price}/>
                    </div>
                    <div>
                        <div className='mb-2'><QRCode size={160} value={paymentItem.payment_request} /></div>
                        <div className="text-left font-bold">LIGHTNING LNURL ONLY</div>
                        <div className="text-left font-bold">SCAN TO PAY<div/>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
        }
    </div>);

});

export const PriceBox = ({price}) => {
    return (<>
            <div className='flex flex-row justify-center md:justify-start'>
                <div className='mr-1'><strong>USD Price:</strong></div>
                <div className='text-4xl text-green-600 font-bold font-monospace'>{Intl.NumberFormat('en-US',{ style:'currency', currency: 'USD' }).format(price)}
                </div>
            </div>    
    </>);
};

export const PaymentItemDetails = React.forwardRef(({paymentItem}, ref) => {

    // const [paymentItem] = useState(paymentItem);
    const [copied, setCopied] = useState(false);

    return (
        <div ref={ref}>
            {paymentItem ?

                <div className='w-full flex flex-col'>
                    <div className='flex justify-center md:justify-start'><h4>{paymentItem.name}</h4></div>
                    <div className='flex sm:justify-center md:justify-start w-full'>
                        <div className='grid sm:grid-cols-1 md:grid-cols-2'>
                            <div className='justify-center flex'>

                                <div className='justify-center md:justify-end p-4'>
                                    {paymentItem.images && paymentItem.images.length > 0 &&
                                    <img className="" src={paymentItem.images[0]['data_url']} alt="" />}
                                </div>
                            </div>

                            <div className='flex flex-col w-full justify-center md:justify-start'>
                                <div className='text-xl sm:text-center md:text-start'>{paymentItem.description}</div>
                                <div className='visible md:invisible md:h-0 sm:h-fit mt-3'><PriceBox price={paymentItem.price}/></div>
                                <div className='w-full justify-center md:justify-start grid grid-cols-1 mt-3 md:flex'>
                                    <div className='flex flex-col'>
                                        <div className='invisible sm:invisible sm:h-0 md:h-fit md:visible'>
                                            <PriceBox price={paymentItem.price}/>
                                        </div>
                                        <div className='sm:m-auto md:m-1 flex justify-center md:justify-start'><QRCode size={160} value={paymentItem.payment_request} /></div>
                                        <div><strong>LIGHTNING LNURL ONLY</strong></div>
                                        <p className='text-center font-bold'>SCAN TO PAY</p>

                                        <div className='flex justify-content-center'>
                                            <CopyToClipboard text={paymentItem.payment_request}
                                                onCopy={() => setCopied(true)}>
                                                <button className='bg-blue hover:bg-blue-600 rounded flex m-1 p-1 justify-center items-center hover:underline'><FiCopy className='mr-1'/>Copy</button>   
                                            </CopyToClipboard>{' '}
                                            {copied ? <div className='flex mr-1 text-green font-bold items-center'><FiCheckCircle/>{' '} Copied.</div> : null}
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                :
                <Spinner animation='border' />
            }

        </div>
    );

});



