import React, { useState, useEffect  } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Page from "../../components/Page";
import {OrderService} from '../../service/OrderService'
import OrdersTable from "../../components/OrdersTable";
import HelpAlert from "../../components/HelpAlert";
import NewPagination from '../../components/NewPagination';

import "./style.css";


/**
 *  an order is a bundle of products and quantities that can be paid for with a single payment
 *  via an LNURL, can be created from a cart, or from a list of products
 * 
 * @param {*} props
 * @returns 
 */

const Orders = ({ useStore }) => {
  

  const { walletInfo, satsRate  } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState();
  const [ordersPage, setOrdersPage] = useState({page: 1, page_size: 15});
  const [ordersPageMeta, setOrdersPageMeta] = useState({total_items_count:15, current_page_number:1, total_pages_count:1});


  useEffect(() => {
    setIsLoading(true);
    fetchOrdersPage(ordersPage);
    setIsLoading(false);
  } , []);

  let fetchOrdersPage = (cPage) => { 
    console.log("fetching orders page:",cPage);
    setIsLoading(true);
    OrderService.getPaged(cPage).then((cr) => {
      setOrders(cr.data.orders);
      setOrdersPage(cPage)
      setOrdersPageMeta(cr.data.page_meta);
      setIsLoading(false);
      // console.log("orders", orders);
    });
  };

  let handlePageSelect = (e) => {
    console.log("page select", e);
    // {page_size:pageInfo.page_size, page:pageMeta.prev_page_number}
    let newPage = {...ordersPage, page: e.page};
    setOrdersPage(newPage);
    fetchOrdersPage(newPage);
  };

    // should trigger reload
  const handlePageSizeChange = (e) => {
    console.log("page Size change", e);
    let newPage = {...ordersPage, page_size: e};
    setOrdersPage(newPage);
    fetchOrdersPage(newPage);
  }

  const handleCSV = () => {
    OrderService.getOrdersCSV().then((csv) => {
      const blob = new Blob([csv.data], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${uuidv4().replace("-","").substring(0,6)}-address.csv`;
      link.click();
    });
  };

  return (
    <Page useStore={useStore}>
        <div className="p-4 row">
            <h1>Orders</h1>
            <div>
              <HelpAlert variant='info' textClass='text-blue' 
                helpLink='/docs/#/orders'>As a merchant your are a request to buy goods or
                services by a Customer. To create an order use the Product Cart.{' '}
                 <strong> All fiat values are based on USDC to SAT quote at time of order creation.</strong></HelpAlert>
            </div>    

            <div className="m-1 flex flex-row w-full justify-end">
                <button className="btn-common-blue" onClick={()=>handleCSV()}>Download CSV</button>
            </div>            

            <OrdersTable orders={orders} setOrders={setOrders} satsRate={satsRate} showCustomer={true}/>
            <hr/>
            {ordersPage && <NewPagination pageInfo={ordersPage} pageMeta={ordersPageMeta} handlePageChange={handlePageSizeChange} fetchPage={handlePageSelect}/>}
        </div>
    </Page>
  );
};

export default Orders;
