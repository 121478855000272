import React, {useEffect, useState} from "react";

import { Row, Col, Button } from "react-bootstrap";
import {useNavigate } from 'react-router-dom';
import Page from "../../components/Page";
import { getUser, getAccessTokenInfo } from '../../service/auth';
import { WalletService } from '../../service/WalletService';

import "./style.css";

const Pricing = () => {

    let navigate = useNavigate();

    const [tokenInfo, setTokenInfo] = useState(getAccessTokenInfo(getUser()));
    const [showAlert, setShowAlert] = useState(false);
    const [wallet, setWallet] = useState();

    useEffect(() => {

        if(tokenInfo.active) {
            WalletService.getWallet().then((wallet) => {
                setWallet(wallet.data); 
            });
        }

    }, [tokenInfo]);

    return (
        <Page withSidenav={false}>
            <div className="pricing-page">
                <div className="pricing-header justify-content-center">
                    <h1>Plans and Pricing</h1>
                    <div>{wallet ? `Your Plan: ${wallet.subscription_type}` : ""}</div>
                </div>
                <div className="pricing-content">
                    <Row className="pricing-content-row">
                        <Col md={1}></Col>
                        <Col md={10} className="pricing-content-item justify-content-center d-flex">
                        <section className="pricing py-5">
                            <div className="container">
                            <div className="row"></div>
                                <div className="row">
                                    {/* free tier (green) */}
                                    <div className="col-lg-4">
                                    <div className="card mb-5 mb-lg-0">
                                        <div className="card-body">
                                        <h5 className="card-title text-uppercase text-center pricing-name pricing-name-amber">Basic</h5>
                                        <h6 className="card-price text-center">$0<span className="period">/always</span></h6>
                                        <hr />
                                        <ul className="fa-ul">
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Up To $1000 USD Value Wallet Size</li>
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Web Browser Point Of Sale</li>
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Transactions up to 100,000 SATs</li> 
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Free Full Drain Withdrawals</li>  
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Touchless QR Code Payment</li>  
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Product and Inventory Tracking</li>                                                             
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Documentation Site</li>

                                            {/* <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Discord Chat Access</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Email Support</li>
                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Slack Support</li> */}
                                        </ul>
                                        {/* {isLoggedIn() && this.state.showPayButtons ? (this.state.subscription.common_name == "AMBER" ? pricingCurrentPlan : "") : pricingButtonRegisterInactive} */}
                                        {/* { !tokenInfo.active ? <Button className="btn-block btn-primary w-100" onClick={() => {}}>Sign Up Now</Button>:<div className="w-100 text-center color-green"><strong>Your Current Plan!</strong></div>} */}
                                        { tokenInfo.active && wallet && wallet.subscription_type == "FREE" ? <div className="w-100 text-center color-green"><strong>Your Current Plan!</strong></div> : <></>}
                                        { tokenInfo.active ? <></> : <Button variant="orange" className="btn-block btn-primary w-100" onClick={() => {}}>Sign Up Now!</Button>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="card mb-5 mb-lg-0 ">
                                        <div className="card-body ">
                                        <div className=" color-dark-blue text-bold">
                                            <h5 className="card-title text-uppercase text-center pricing-name pricing-name-sapphire">Merchant</h5>
                                            <h6 className="card-price text-center">$29.95 USD<span className="period">/year</span></h6>
                                        </div>
                                        <hr />
                                        <ul className="fa-ul">
                                            <li className="color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Up To $1500 USD Value Wallet Size</li>
                                            <li className="color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Transactions up to 250,000 SATs</li> 
                                            <li className="color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>rapaygo Vanguard Touchless POS Support</li> 
                                            <li className="color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Print Touchless QR Code Payment Cards</li>                                                                                            
                                            <li className="color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Discord Server Access</li>
                                            <li className="color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Email Support</li>

                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Web Browser Based Point Of Sale</li>
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Product and Inventory Tracking</li>
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Documentation Site</li>

                                        </ul>
                                        {/* {isLoggedIn() && this.state.showPayButtons ? pricingButtonSapphire : pricingButtonRegisterInactive} */}
                                        { tokenInfo.active && wallet && wallet.subscription_type == "MERCHANT" ? <div className="w-100 text-center color-green"><strong>Your Current Plan!</strong></div> : <></>}
                                        {/* { !tokenInfo.active ? <div className="w-100 text-center"><Button className="w-100" variant="green">Learn More</Button></div> : <></>} */}
                                        { tokenInfo.active && wallet && wallet.subscription_type == "FREE" ? <div className="w-100 text-center"><Button className="w-100" variant="green" onClick={()=>navigate('/upgrade')}>Upgrade Now</Button></div> : <></>}
                                        
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className=" color-dark-green text-bold">
                                            <h5 className="card-title text-uppercase text-center pricing-name  color-dark-green text-bold">Seller</h5>
                                            <h6 className="card-price text-center  color-dark-green text-bold">Contact Us</h6>
                                        </div>

                                    <hr />
                                    <ul className="fa-ul">
                                        <li className=" color-dark-green text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Up To $2500 USD Value Wallet Size</li>
                                        <li className=" color-dark-green text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Transactions up to 500,000 SATs</li>
                                        <li className=" color-dark-green text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Order Management and Shipping</li> 
                                        <li className=" color-dark-green text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>E-Commerce Support for Shop Sites</li> 
                                        


                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>rapaygo Vanguard Touchless POS Support</li> 
                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Product and Inventory Tracking</li> 
                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>rapaygo Vanguard Touchless POS Support</li> 
                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Print Touchless QR Code Payment Cards</li>                                                                                            
                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Discord Server Access</li>
                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Email Support</li>
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>Web Browser Based Point Of Sale</li>
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>Product and Inventory Tracking</li>
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>Documentation Site</li>



                                    </ul>
                                    {/* {isLoggedIn() && this.state.showPayButtons ? pricingButtonAmethyst : pricingButtonRegisterInactive} */}
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </section>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </div>
            </div>    



        </Page>)
};

export default Pricing;