import {AxiosService} from "./AxiosService";

export const CustomerService = {

    getPaged(pageInfo={page: 1, page_size: 10}) {
        return AxiosService.getPage(`/customer`, pageInfo);
    },
    getById (id){
        return AxiosService.get(`/customer/${id}`,{
            headers: {'Accept': 'application/json'}
        });
    },
    getOrdersById (id){
        return AxiosService.get(`/customer/${id}/orders`,{
            headers: {'Accept': 'application/json'}
        });
    },    
    create (customer){
        return AxiosService.post(`/customer`, customer, {
            headers: {'Content-Type': 'application/json'}
        });
    },
    update (customer){
        return AxiosService.put(`/customer`, customer, {
            headers: {'Content-Type': 'application/json'}
        });
    },
    delete(id){
        return AxiosService.del(`/customer/${id}`,{
            headers: {'Accept': 'application/json'}
        });
    }
};