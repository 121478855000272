import React from "react";
import Axios from "axios";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Row, Col, Alert } from 'react-bootstrap';
import { CheckCircle } from 'react-feather';
//import { withLocale } from "react-easy-localization";


import "./style.css";

import Page from "../../components/Page";

Axios.defaults.withCredentials = false;

const EmailVerifyFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const divStyle = {
  WebkitTransition: 'all', // note the capital 'W' here
  msTransition: 'all' // 'ms' is the only lowercase vendor prefix
};

const LeftCol = styled(Col)`
  text-align: left;
  `;

const EmailVerify = ({ i18n, languageCode, changeLanguage }) => {
  const { status } = useParams();

  return (
    <Page withSidenav={false}>

      <EmailVerifyFormWrapper className="mt-1 mb-2">
        <div className="flex justify-center p-4">
          {status === "success" ?
            <div className="text-center">
              <h1 className="uppercase">{status}</h1>
              <h3>You did it! We hope you are proud of yourself.</h3>
              <Row className="mb-5">
                <Col className="text-center flex justify-center">
                    <CheckCircle size={200} className="text-green-500" />
                </Col>
              </Row>
              <Row className="mb-5">
                <Col className="col-12">
                {/* <Alert variant="info">Look out for an email in your inbox giving you instructions on how to pay and login, here are your remaining steps to start.</Alert> */}
                You should receive an email from us shortly with a temporary password 
                and instructions on how to set a new password.            
                </Col>
              </Row>
          

            </div>
            :<></> }

          {status === "failure" ?
            <div className="text-center">
              <h1>{status}</h1>
              <p>Something went wrong.</p>
            </div> : <></>}


        </div>
      </EmailVerifyFormWrapper>
    </Page>
  );
};

export default EmailVerify;
