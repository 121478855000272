import React, {useEffect, useState} from "react";
import Axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
//import { withLocale } from "react-easy-localization";

import "./style.css";
import Page from "../../components/Page";
import InvoiceDetail from "../../components/InvoiceDetail";
import { LightningPaymentService } from "../../service/LightningPaymentService";

Axios.defaults.withCredentials = false;

const InvoiceInfo = ({ useStore }) => {
  const { id } = useParams();

  const {satsRate, userCurrency} = useStore();

  const [invoice, setInvoice] = useState(null);

  const expires = 1800000; //30 minutes
  const [countdown, setCountdown] = useState(expires);
  
  const navigate = useNavigate();

  useEffect(() => {
    LightningPaymentService.getById(id)
      .then((response) => {
        console.log("invoice", response.data);
        setInvoice(response.data);
      })
      .catch((err) => {
        console.log("err", err);
        navigate("/invoices");
      });
  }, [id, navigate]);

  useEffect(() => { 
    setTimeout(() => {
      setCountdown(countdown - 1000);
      LightningPaymentService.getById(id)
      .then((response) => {
        console.log("invoice", response.data);
        setInvoice(response.data);
      })
      .catch((err) => {
        console.log("err", err);
        navigate("/invoices");
      });
    }, 10000);
  },[countdown]);

  return (
    <Page useStore={useStore}>
      <div className="flex p-4">
        <div className="text-left">
          { invoice ? 
            <>
              <h1>Invoice Info</h1>
              <InvoiceDetail invoice={invoice} satsRate={satsRate} userCurrency={userCurrency}/>         
            </> 
            : 
            <>No Invoice Found</> }
        </div>      
      </div>
    </Page>
  );
};

export default InvoiceInfo;


