import React, {useState, useEffect} from 'react';

import { formatFiat, fiatConvert } from '../service/WalletService'

/**
 * categories: []
 * @param {*} param0 
 * @returns 
 */
export const OrderLines = ({ orderLines, satsRate, userLang='en-US' }) => {
    
    const getLinePrice = (item) => {
        return item.payment_item.price * item.quantity;
    };

    return (
        <>
        {orderLines.map((orderLine, index) => (      
            <div key={index} className="flex w-full flex-row justify-between p-1 border-b-4">
                                         
                {orderLine.fiat_amount ? 
                <>
                <div className="flex w-4/6 flex-col">
                    <div className="text-sm font-bold">{orderLine.payment_item.name}</div>
                    <div>{orderLine.description}</div>
                </div>
                <div className="flex w-1/6 flex-col justify-start bg-green-300">
                    <div className="text-right font-monospace text-sm font-bold">{orderLine.quantity} x {formatFiat(orderLine.payment_item.price, satsRate, orderLine.payment_item.currency, userLang, false)}</div>
                    <div className="text-right font-monospace text-xs text-gray-500">{fiatConvert(satsRate, orderLine.payment_item.price).sats} SATS each</div>
                </div>
                <div className="flex w-1/6 flex-col justify-start bg-blue-300">           
                    <div className="text-right font-monospace text-sm font-bold">= {formatFiat(orderLine.fiat_amount, satsRate, orderLine.fiat_i8n_currency, userLang, false)}</div>
                    <div className="text-right font-monospace text-xs text-gray-500">{orderLine.amount_sats} SATS</div>
                </div>

                </> :
                <>
                    <div className="flex w-4/6 flex-col">
                        <div className="text-lg font-bold">{orderLine.payment_item.name} (Estimated)</div>
                        <div>{orderLine.description}</div>
                    </div>
                    <div className="flex w-1/6 flex-col justify-start bg-green-300">
                        <div className="text-right font-monospace text-sm font-bold">{orderLine.quantity} x {formatFiat(orderLine.payment_item.price, satsRate, orderLine.payment_item.currency, userLang, false)}</div>
                        <div className="text-right font-monospace text-xs text-gray-500">{fiatConvert(satsRate, orderLine.payment_item.price).sats} SATS each</div>
                    </div>
                    <div className="flex w-1/6 flex-col justify-start bg-blue-300">
                        <div className="text-right font-monospace text-sm font-bold">= {formatFiat(getLinePrice(orderLine), satsRate, orderLine.payment_item.currency, userLang, false)}</div>
                        <div className="text-right font-monospace text-xs text-gray-500">{fiatConvert(satsRate, getLinePrice(orderLine)).sats} SATS</div>
                    </div>
                </>}
                

            </div>))}
    </>);
};



export const OrderLinesSummary = ({ orderLines, satsRate, userLang='en-US' }) => {
    const getTotalFiat = () => {
        let total = 0;
        orderLines.forEach(orderLine => {
            if (orderLine.amount_sats !== null) {
                total += orderLine.fiat_amount;
            } else {
                total += orderLine.payment_item.price * orderLine.quantity;
            }
            
        });
        return total;
    };

    const getTotalSats = () => {
        let total = 0;
        orderLines.forEach(orderLine => {
            // total += orderLine.amount_sats;
            if (orderLine.amount_sats !== null) {
                total += orderLine.amount_sats;
            } else {
                let o_l = orderLine.payment_item.price * orderLine.quantity;
                total += fiatConvert(satsRate, o_l).sats;
            }
        });
        return total;
    };

    return (
        <>
            <div className="flex w-full flex-row justify-between p-2">
                <div className="flex w-4/6 flex-col">
                    <div className="text-2xl font-bold">Total</div>
                </div>
                <div className="flex w-1/6 flex-col justify-start bg-blue-300">
                    <div className="text-right font-monospace text-2xl">= {Intl.NumberFormat('en-US', {style:'currency', currency: 'USD' }).format(getTotalFiat())}</div>
                    <div className="text-right font-monospace text-xs text-gray-500">{getTotalSats()} SATS</div>
                </div>
            </div>                   
        </>
    );
};