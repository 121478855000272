import React, {useState, useEffect} from 'react';
import QRCode from "react-qr-code";
import Countdown from "react-countdown";
import ReactTimeAgo from 'react-time-ago';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactAudioPlayer from 'react-audio-player';
import { FiCopy, FiCheckCircle } from "react-icons/fi";

import { FaBitcoin } from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";

import { WalletService, fiatConvert, satsConvert } from "../service/WalletService" 

import { networkBrowserBaseUrl } from "../env";


const OnchainDetail = ( {invoice, satsRate, userCurrency, network="mainnet", mode='wallet'}) => {
    const [fiatStyle, setFiatStyle] = useState();
    const expires = 3600000; //60 minutes
    const [countdown, setCountdown] = useState(expires);
    const [networkBrowserUrl] = useState(networkBrowserBaseUrl(network));
    //window.location = `${networkBrowserUrl}/address/${invoice.onchain_address}`
    

    useEffect(() => { 
        setTimeout(() => {
          setCountdown(countdown - 1000);
        }, 1000);
      },[countdown]);
      

    useEffect(() => {
        // setNetworkBrowserUrl(networkBrowserBaseUrl[network]);
        setFiatStyle(`d-flex flex-row justify-content-center fiat-number-${mode}`)
    }, [mode]);


    return (<>
        {/* {invoice && networkBrowserUrl && <>{networkBrowserUrl}</>} */}
        {invoice &&
        <div className='flex justify-center'>
            {/* <>ON CHAIN PAYMENT</> */}
            <div className='rounded bg-slate-200 w-96 p-3'>
                <div className="flex flex-row justify-center">
                    <FaBitcoin className='text-4xl text-yellow-600'/>
                    {network === 'testnet' && <div className='m-1 rounded-lg bg-slate-300 items-center text-xs h-fit p-1'>{network}</div>}
                </div>
                {/* status */}
                <div className="flex flex-row justify-center">
                    {invoice.status === "CREATED" && <div className="m-1 mr-3 h-7 rounded bg-yellow-600 p-1 text-sm font-bold text-white">{invoice.status}</div>}
                    {invoice.status === "PENDING" && <div className="m-1 mr-3 h-7 rounded bg-gray-400 p-1 text-sm font-bold text-white">{invoice.status}</div>}
                    {invoice.status === "CONFIRMED" && <div className="m-1 mr-3 h-7 rounded bg-blue-600 p-1 text-sm font-bold text-white">{invoice.status}</div>}
                    {invoice.status === "PAID" && <div className="m-1 mr-3 h-7 rounded bg-green-500 p-1 text-sm font-bold text-white">{invoice.status}</div>}
                    {invoice.status === "COMPLETED" && <div className="m-1 mr-3 h-7 rounded bg-green-600 p-1 text-sm font-bold text-white">{invoice.status}</div>}   
                </div>
                <div className="flex flex-row justify-center">
                    <div className="text-xs text-slate-700">confirmations: {invoice.onchain_confirmations}</div>
                </div>

                {invoice.status !== "COMPLETED" && <div className="font-bold text-3xl text-center">SCAN TO PAY</div>}

                <div className="text-center text-sm text-gray-500">{new Date(invoice.created_at).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour:"numeric", minute:"2-digit"})} - <ReactTimeAgo className="italic text-slate-600" date={Date.parse(invoice.created_at)}/></div>


                {/* sats rate */}
                {satsRate && 
                <div>
                    {invoice.amount>0 ? 
                    <div className="flex justify-center text-4xl font-bold font-monospace text-green-600">
                        {Intl.NumberFormat('en-US', 
                        {style:'currency', currency: 'USD' }).format(satsConvert(satsRate, parseInt(invoice.amount)).fiat)} USD
                    </div>:
                    <div className="flex justify-center text-4xl font-bold font-monospace text-red-700">
                        {Intl.NumberFormat('en-US', 
                        {style:'currency', currency: 'USD' }).format(satsConvert(satsRate, parseInt(invoice.amount)).fiat)} USD
                    </div>}    

                

                    <div className="d-flex flex-row justify-content-center sats-number-wallet">
                        {invoice.amount} SATs
                    </div>
                    <div className='flex justify-center'>
                        <div className="sats-rate-wallet">1 {userCurrency} = {fiatConvert(satsRate, 1).sats} SATS</div>
                    </div>      
                </div>}
                

                {/* payment request */}
                <div className='w-full'>

                {invoice.status === "COMPLETED" ? 
                    <>
                    <div className='w-full flex justify-center'>
                        <div className="p-2 rounded-full bg-green-100 flex  items-center">
                            <div className='w-36 h-36 text-green font-bold items-center'><FiCheckCircle className='h-full w-full'/></div>                                                    
                        </div> 
                        <ReactAudioPlayer
                            src="/media/audio/583949__fenodyrie__bell-ringing-once.wav"
                            autoPlay={true}
                            controls={false}
                        />
                    </div>
                    </> :
                    <div className='w-full flex justify-center'>
                        <div className="p-2 rounded-xl bg-white flex items-center flex-col">
                            {invoice?.onchain_address && 
                            <>
                                <QRCode size={200} value={invoice.onchain_address}/>
                                <div className="mt-1 text-xs text-slate-700 font-monospace underline cursor-pointer"
                                    onClick={()=>window.open(`${networkBrowserUrl}/address/${invoice.onchain_address}`)}>
                                    {invoice.onchain_address}
                                </div>   
                            </>}                      
                        </div>                                   
                    </div>}

                    {invoice.onchain_tx_hash && 
                        <div className="mt-1 text-xs text-slate-700 font-monospace break-all underline cursor-pointer"
                        onClick={()=>window.open(`${networkBrowserUrl}/tx/${invoice.onchain_tx_hash}`)}>
                            {invoice.onchain_tx_hash}</div>}

                    {(invoice.status !== 'COMPLETED' ) && <div className="d-flex flex-col justify-content-center mb-2">
                        <div className='w-full text-center text-xs font-bold'>polling stops:</div>
                        <Countdown className="w-full text-center font-monospace font-bold" date={Date.now() + countdown}/>
                        
                    </div>}
                </div>
                
            </div>
        </div>
        }
    </>)
};

const OnchainDetailForPrint = ( {invoice, satsRate, userCurrency='USD', mode='wallet'}) => {
    const [fiatStyle, setFiatStyle] = useState();
    const expires = 3600000; //60 minutes
    const [countdown, setCountdown] = useState(expires);
    

    useEffect(() => { 
        setTimeout(() => {
          setCountdown(countdown - 1000);
        }, 1000);
      },[countdown]);
      

    useEffect(() => {
        setFiatStyle(`d-flex flex-row justify-content-center fiat-number-${mode}`)
    }, [mode]);


    return (<>
        {invoice &&
        <div className='flex flex-row'>
        

            <div className="flex flex-col mr-3">
                {/* <>{JSON.stringify(invoice)}</> */}
                <div className="text-2xl font-bold flex"><FaBitcoin className='text-4xl mr-2 mb-3'/> ONCHAIN BTC PAYMENT</div> 
                <div className="flex flex-row text-xl">FIAT AMOUNT: {Intl.NumberFormat('en-US', 
                        {style:'currency', currency: 'USD' }).format(invoice.fiat_amount)} USD</div>
                <div className="flex text-xl flex-row font-bold">PAY THIS AMOUNT: {invoice.amount} SATs</div>
                <div className="flex"><div className="sats-rate-wallet"><div className="sats-rate-wallet">1 {userCurrency} = {fiatConvert(satsRate, 1).sats} SATS</div></div></div> 
                <div className='text-xs p-1'>Your company will pay this invoice "on chain" with bitcoin. 
                Use the QR code or the address, and ether the total number of SATs shown in this invoice. 
                Your payment will be considered complete once 4 confirmations have occurred. Your team will
                receive an email when payment is confirmed. Need help paying? Email support@rapaygo.com</div>   
            </div>
            <div className="flex flex-col justify-center">
                <div className="text-center">ONCHAIN ADDRESS</div>
                <div className="m-1"><QRCode size={200} value={invoice.onchain_address}/> </div>               
                <div className="break-all w-56 text-xs font-mono mt-2">{invoice.onchain_address}</div>
            </div>


        </div>
        }
    </>)
};

const LightningInvoiceDetail = ( {invoice, satsRate, userCurrency='USD', mode='wallet'}) => {
    const [fiatStyle, setFiatStyle] = useState();
    const expires = 3600000; //30 minutes
    const [countdown, setCountdown] = useState(expires);
    const [copied, setCopied] = useState(false);
    

    useEffect(() => { 
        setTimeout(() => {
          setCountdown(countdown - 1000);
        }, 1000);
      },[countdown]);
      

    useEffect(() => {
        setFiatStyle(`d-flex flex-row justify-content-center fiat-number-${mode}`)
    }, [mode]);
    
    return(<>
        {invoice && 
        <div className='flex justify-center'>
            {/* <>LN PAYMENT</> */}
            <div className='rounded bg-slate-200 w-96 p-3'>
                <div className="flex flex-row justify-center"><BsLightningFill className='text-4xl text-yellow-600'/></div>
                {/* status */}
                <div className="flex flex-row justify-center">
                    {invoice.status === "CREATED" && <div className="m-1 mr-3 h-7 rounded bg-yellow-600 p-1 text-sm font-bold text-white">{invoice.status}</div>}
                    {invoice.status === "PENDING" && <div className="m-1 mr-3 h-7 rounded bg-gray-400 p-1 text-sm font-bold text-white">{invoice.status}</div>}
                    {invoice.status === "PAID" && <div className="m-1 mr-3 h-7 rounded bg-green-500 p-1 text-sm font-bold text-white">{invoice.status}</div>}
                    {invoice.status === "COMPLETED" && <div className="m-1 mr-3 h-7 rounded bg-green-600 p-1 text-sm font-bold text-white">{invoice.status}</div>}   
                </div>

                {invoice.status !== "COMPLETED" && <div className="font-bold text-3xl text-center">SCAN TO PAY</div>}

                <div className="text-center text-sm text-gray-500">{new Date(invoice.created_at).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour:"numeric", minute:"2-digit"})} - <ReactTimeAgo className="italic text-slate-600" date={Date.parse(invoice.created_at)}/></div>


                {/* sats rate */}
                {satsRate && 
                <div>
                
                {invoice.amount>0 ? 
                    <div className="flex justify-center text-4xl font-bold font-monospace text-green-600">
                        {Intl.NumberFormat('en-US', 
                        {style:'currency', currency: 'USD' }).format(satsConvert(satsRate, parseInt(invoice.amount)).fiat)} USD
                    </div>:
                    <div className="flex justify-center text-4xl font-bold font-monospace text-red-700">
                        {Intl.NumberFormat('en-US', 
                        {style:'currency', currency: 'USD' }).format(satsConvert(satsRate, parseInt(invoice.amount)).fiat)} USD
                    </div>}    




                <div className="d-flex flex-row justify-content-center sats-number-wallet">
                    {invoice.amount} SATs
                </div>
                <div className='flex justify-center'>
                    <div className="sats-rate-wallet">1 {userCurrency} = {fiatConvert(satsRate, 1).sats} SATS</div>
                </div>      
                </div>}
                

                {/* payment request */}
                <div className='w-full'>

                {invoice.status === "COMPLETED" ? 
                    <>
                    <div className='w-full flex justify-center'>
                        <div className="p-2 rounded-full bg-green-100 flex  items-center">
                            <div className='w-36 h-36 text-green font-bold items-center'><FiCheckCircle className='h-full w-full'/></div>                                                    
                        </div> 
                        <ReactAudioPlayer
                            src="/media/audio/583949__fenodyrie__bell-ringing-once.wav"
                            autoPlay={true}
                            controls={false}
                        />
                    </div>
                    </> :
                    <div className='w-full flex flex-col justify-center'>
                        {invoice.payment_request && 
                        <div className='w-full flex flex-row justify-center'>
                            <div className="p-2 rounded-xl bg-white flex items-center w-fit">
                                <QRCode size={200} value={invoice.payment_request}/>
                            </div> 
                        </div>}
                        <div>
                            {(invoice.status === 'PENDING' ) && 
                                <div className='flex justify-content-center'>
                                <CopyToClipboard text={invoice.payment_request}
                                    onCopy={() => setCopied(true)}>
                                    <button className='bg-blue hover:bg-blue-600 rounded flex m-1 p-1 justify-center items-center hover:underline'><FiCopy className='mr-1'/>Copy</button>   
                                </CopyToClipboard>{' '}
                                {copied ? <div className='flex mr-1 text-green font-bold items-center'><FiCheckCircle/>{' '} Copied.</div> : null}
                                </div>}    
                        </div> 


                    </div>}

                    {invoice.payment_hash && 
                        <div className="mt-1 text-xs text-slate-700 font-monospace break-all">{invoice.payment_hash}</div>}

                    {(invoice.status !== 'COMPLETED' ) && <div className="d-flex flex-col justify-content-center mb-2">
                        <div className='w-full text-center text-xs font-bold'>expires in</div>
                        <Countdown className="w-full text-center font-monospace font-bold" date={Date.now() + countdown}/>
                         
                    </div>}
                </div>
                

            


            </div>
        </div>}
        </>);
};

const LightningInvoiceDetailForPrint = ( {orderPayment, satsRate, userCurrency, mode='wallet'}) => {
    const [fiatStyle, setFiatStyle] = useState();
    const expires = 3600000; //30 minutes
    const [countdown, setCountdown] = useState(expires);
    
    useEffect(() => { 
        setTimeout(() => {
          setCountdown(countdown - 1000);
        }, 1000);
      },[countdown]);
      

    useEffect(() => {
        setFiatStyle(`d-flex flex-row justify-content-center fiat-number-${mode}`)
    }, [mode]);
    

    // 'id': self.id,
    // 'order_id': self.order_id,
    // 'invoice_payment_id': self.invoice_payment_id,
    // 'created_at': self.created_at,
    // 'amount_msat': self.invoice.amount,
    // 'amount_sat': int(self.invoice.amount/1000),       
    // 'updated_at': self.updated_at,
    // 'fiat_amount': self.fiat_amount,
    // 'fiat_currency': self.fiat_currency
    return(<>
        {orderPayment && 
        <div className='flex flex-row'>      

            <div className="flex flex-col mr-3">
                {/* <>{JSON.stringify(invoice)}</> */}
                <div className="uppercase text-2xl font-bold flex"><BsLightningFill className='text-4xl mr-2 mb-3'/> Lightning PAYMENT</div> 
                <div className="flex flex-row text-xl">FIAT AMOUNT: {Intl.NumberFormat('en-US', 
                        {style:'currency', currency: 'USD' }).format(orderPayment.fiat_amount)} {orderPayment.fiat_currency}</div>
                <div className="flex text-xl flex-row font-bold">PAY THIS AMOUNT: {orderPayment.amount_sat} SATs</div>
                <div className="flex"><div className="sats-rate-wallet"><div className="sats-rate-wallet">1 {userCurrency} = {fiatConvert(satsRate, 1).sats} SATS</div></div></div> 
                <div className='text-xs p-1'>Your company will pay this invoice using "SATS" on the Lightning Network. Use the QR code and a memo if you wish. 
                </div>   
            </div>

            <div className="flex flex-col justify-center">
                <div className="text-center">PAYMENT REQUEST</div>
                <div className="m-2"><QRCode size={200} value={orderPayment.invoice_payment.payment_request}/></div>
                <div className="break-all w-56 text-xs font-mono mt-2">{orderPayment.invoice_payment.payment_request}</div>
            </div>
        </div>}
        </>);
};

// ung I hate this these are not invoices, they are payments
const InvoiceDetail = ( {invoice, satsRate, userCurrency, isPrintable=false}) => {

    const [apiVersion, setApiVersion] = useState();

    useEffect(() => {
        
        if(apiVersion === undefined){
            console.log("useStore is undefined");
            WalletService.getApiVersion().then((cr) => {
                setApiVersion(cr.data);
            });

        } 
    }, [apiVersion]);

    return(<>
        {isPrintable ? <>
            
            {invoice && invoice.is_onchain === 1 ? 
                <OnchainDetailForPrint invoice={invoice} satsRate={satsRate} userCurrency={userCurrency}/>: 
                <LightningInvoiceDetailForPrint invoice={invoice} satsRate={satsRate} userCurrency={userCurrency}/>}     
                
        </> : 
        <>
            {apiVersion && invoice && invoice.is_onchain === 1 ? 
                    <OnchainDetail invoice={invoice} satsRate={satsRate} userCurrency={userCurrency} network={apiVersion.lnd_rest_network}/>: 
                    <LightningInvoiceDetail invoice={invoice} satsRate={satsRate} userCurrency={userCurrency} />}        
                    </>}
        </>);

};

export const OrderPaymentDetail = ( {orderPayment, satsRate, userCurrency, isPrintable=false}) => {
    const [apiVersion, setApiVersion] = useState();

    useEffect(() => {
        
        if(apiVersion === undefined){
            console.log("useStore is undefined");
            WalletService.getApiVersion().then((cr) => {
                setApiVersion(cr.data);
            });

        } 
    }, [apiVersion]);
    
    return(<>
        {isPrintable ? <>           
            {orderPayment && orderPayment.invoice_payment.is_onchain === 1 ? 
                <OnchainDetailForPrint invoice={orderPayment.invoice_payment} satsRate={satsRate} userCurrency={userCurrency}/>: 
                <LightningInvoiceDetailForPrint orderPayment={orderPayment} satsRate={satsRate} userCurrency={userCurrency}/>}     
                
        </> : 
        <>
            {apiVersion && orderPayment && orderPayment.invoice_payment.is_onchain === 1 ? 
                    <OnchainDetail invoice={orderPayment.invoice_payment} satsRate={satsRate} userCurrency={userCurrency} network={apiVersion.lnd_rest_network}/>: 
                    <LightningInvoiceDetail orderPayment={orderPayment} satsRate={satsRate} userCurrency={userCurrency}/>}        
                    </>}
        </>);

};


  
export default InvoiceDetail;