import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import { Spinner,Button, Alert} from "react-bootstrap";

import Page from "../../components/Page";
import { fiatConvert, balConvert } from "../../service/WalletService";
import {LightningPaymentService} from "../../service/LightningPaymentService";
import PaymentsTable from "../../components/PaymentsTable";
import VouchersTable from "../../components/VouchersTable";
import HelpAlert from "../../components/HelpAlert";
import {currenciesLookup} from "../../env";

import "./style.css";



const Wallet = ({useStore}) => {

  const { setIsLoading, getUserSettings, walletInfo, userCurrency, userLang, satsRate, getSatsRate,
    vouchersUnspent, unpaidVoucherSats, effectiveBalanceMSats, 
    getVouchersUnspent, setEffectiveBalanceMSats, getWalletInfo  } = useStore();

  const navigate = useNavigate();
  const [payments, setPayments] = useState([]);
  const [paymentsPage] = useState({page: 1, page_size: 5});
  const [error, setError] = useState();


  useEffect(() => {
    setIsLoading(true);
    getUserSettings();
    getSatsRate(userCurrency);
    getWalletInfo();   
    getVouchersUnspent();


    LightningPaymentService.getInvoicePaymentsPaged(paymentsPage).then((payments) => {
      setPayments(payments.data.invoice_payments);
      getVouchersUnspent();
      setIsLoading(false);
    });

    //check settings
    if(walletInfo && !walletInfo.company_address && walletInfo.subscription_type !== "FREE") {
      setError(<div>No company address set. Please go to <span className="cursor-pointer underline" onClick={()=>navigate("/settings")}>settings</span> and set your company address.</div>)
    }
    
  }, []);

  useEffect(() => {
    getWalletInfo();
    setEffectiveBalanceMSats(walletInfo.balance-(unpaidVoucherSats*1000));
  }, [unpaidVoucherSats]);


  return (
    <Page useStore={useStore}>
      <div className="p-4">
      {walletInfo && satsRate ? 
          <>
          
          {/** wallet dashboard card */}
          <div className="row">
            <div><h1>Dashboard</h1></div>
            <HelpAlert variant='info' textClass='text-blue' 
                helpLink='/docs/#/wallet'>Your Wallet Dashboard is 
                the overview of your account activity.</HelpAlert>
            {error && <Alert variant="danger">{error}</Alert>}
            
            {satsRate ? 
            <div className="p-2 rounded-md bg-gray-200 text-center">
                <div className="text-gray-500">Effective Withdrawable Balance</div>
                <div className="text-green-600 text-5xl font-bold monospace">
                  {Intl.NumberFormat(userLang,
                  {style:'currency', currency: userCurrency }).format(balConvert(satsRate, effectiveBalanceMSats).fiat)} {userCurrency}
                </div>
                <div className="sats-number-wallet">{parseInt(effectiveBalanceMSats/1000)} SATS</div>
                <div className="sats-rate-wallet">{currenciesLookup[userCurrency]}</div>
                <div className="sats-rate-wallet mb-3">1 {userCurrency} = {fiatConvert(satsRate, 1).sats} SATS</div>
            </div>:<Spinner animation="border"/> }
          </div>

          {/** invoices */}                   
          <div className="row mt-3">
            <div className="d-flex justify-content-between">
                   <div className="bd-highlight"><h3>Invoices</h3></div>
                   <div className="bd-highlight"><Button variant="blue" onClick={()=> navigate('/invoices')}>View All Invoices</Button></div>
                 </div>               
                 <div>{balConvert(satsRate, walletInfo.balance).sats} SATS COMPLETED (ALL INVOICES)</div>
                 <h5>Last {paymentsPage.page_size} Invoices</h5>  
                 {payments.length > 0 && <PaymentsTable satsRate={satsRate} payments={payments}  setPayments={setPayments} showDelete={false}/>}        
          </div>

          {/** vouchers */}                   
          <div className="row mt-3">
                
            <div className="d-flex justify-content-between">
                  <div className="bd-highlight"><h3>Unspent Vouchers</h3></div>
                <div className="bd-highlight"><Button variant="blue" onClick={()=> navigate('/vouchers')}>View All Vouchers</Button></div>
            </div>          
            <div>{unpaidVoucherSats && <>{unpaidVoucherSats}</>} SATS UNSPENT VOUCHERS</div>
            <VouchersTable vouchers={vouchersUnspent} satsRate={satsRate} showInvalidate={false}/>    

          </div>


          </>
          

        
          
       
       : 
       <div>
        Loading...
       </div>
      }    
      </div>
    </Page>
  );
};


export default Wallet;
