import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Page = ({
  withSidenav = true,
  useStore,
  children,
}) => {
  return (
    <>
      <div id="container" className="flex min-h-screen flex-col">
        <div id="header" className="p-2 bg-black flex"><Header/></div>
        {withSidenav ? <div id="main" className="flex flex-grow flex-col md:flex-row">
          <div className="flex bg-gray-900  md:bg-gray-800 text-white"><Sidebar useStore={useStore}/></div>
          <div className="flex-grow">{children}</div>
        </div>:
        <div id="main" className="flex flex-grow flex-col md:flex-row">
            <div className="flex-grow">{children}</div>
        </div>
        }
        <div id="footer" className="p-0 bg-black color-white">
         <Footer useStore={useStore}/>
        </div>
      </div>   
    </>
  );
};

export default Page;
