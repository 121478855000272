import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert
} from 'react-bootstrap';


import Page from "../../components/Page";
import NewPagination from "../../components/NewPagination";
import {CustomerService} from '../../service/CustomerService'
import {AxiosService} from "../../service/AxiosService"
import {CustomersTable} from "../../components/CustomersTable";
import CustomerForm from "../../components/CustomerForm";
import HelpAlert from "../../components/HelpAlert";

import "./style.css";

const Customers = ({ useStore }) => {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState();
  const [customer, setCustomer] = useState();
  
  const [customersPage, setCustomersPage] = useState({page: 1, page_size: 15});
  const [customersPageMeta, setCustomersPageMeta] = useState({total_items_count:15, current_page_number:1, total_pages_count:1});

  const [showForm, setShowForm] = useState(false);

  const [error, setError] = useState();

  useEffect(() => {
    setIsLoading(true);
    fetchCustomersPage(customersPage);
    setIsLoading(false);
  } , [customer]);

  const fetchCustomersPage = (cPage) => { 
    setIsLoading(true);
    CustomerService.getPaged(cPage).then((cr) => {
      setCustomers(cr.data.customer_accounts);
      setCustomersPage(cPage)
      setCustomersPageMeta(cr.data.page_meta);
      setIsLoading(false);
    });
  };

  const setEditItem = (customerId) => {
    console.log("setEditItem ID", customerId);
    CustomerService.getById(customerId)
    .then((cr) => {
      console.log("setEditItem", cr);
      if(cr.data){     
        if(cr.data.sales_rep_pos_key === null){ cr.data.sales_rep_pos_key = -1 }
        setCustomer(cr.data);
        setShowForm(true);
      }
    });    
  };

  const deleteItem = (customerId) => {
    console.log("deleteItem", customerId);
    CustomerService.delete(customerId)
    .then((cr) => {
      // copy the array of customers
      let newCustomers = [...customers];
      // find the index of the customer to delete
      let index = newCustomers.findIndex((a) => a.id === customerId);
      // remove the customer from the array
      newCustomers.splice(index, 1);
      // update the state
      setCustomers(newCustomers);
    }).catch((err) => {
      let error_model = AxiosService.handleAxiosError(err);
      console.log("error", error_model);
      setError(error_model.message);
    });
  };

    // should trigger reload
  const handlePageChange = (e) => {
    setCustomersPage({page: 1, page_size: parseInt(e)});
  }

  const handleNew = () => {
    setShowForm(true);
  };

  const handleCancel = () => {
    setShowForm(false);
    setCustomer(null);
  };
 
  const handleCreated = (customer) => {
    // console.log("handleCreated", customer);
    let addr = {
      ...customer
    };

    setCustomers([...customers, addr]);    
    setShowForm(false);
    setCustomer(null);
  };

  const handleUpdated = (customer) => {
    let cCustomers = [...customers];

    //replace the customer in the array
    cCustomers = cCustomers.map((a) => {
      if(a.id === customer.id){
        return customer;
      }
      return a;
    });

    setCustomers(cCustomers);

    setShowForm(false);
    setCustomer(null);
  };  


  return (
    <Page useStore={useStore}>
        <div className="p-4 row">
            <h1>Customers</h1>
            <HelpAlert variant='info' textClass='text-blue' 
                helpLink='/docs/#/customers'>As a merchant your customers are people who buy goods 
                and services from you. Anything linked to a customer will show up as an order, and
                anything you pay them will show up as a bill.</HelpAlert> 
            {error && <Alert variant="danger" className="text-red-700">{error}</Alert>}
            <div className="m-1 flex flex-row w-full justify-end">
                {(customer == null || customer.id == null) && showForm === false && <button className="btn-common-blue" onClick={()=>handleNew()}>Add New</button>}
                <button className="btn-common-blue">Download CSV</button>
            </div>
            <div className='mb-2'>
              <CustomerForm customer={customer} setCustomer={setCustomer} showForm={showForm} handleCancel={handleCancel} handleCreated={handleCreated} handleUpdated={handleUpdated}/>
            </div>
            <CustomersTable customers={customers} setEditItem={setEditItem} deleteItem={deleteItem}/>

            <hr/>
            {customersPage && <NewPagination pageInfo={customersPage} pageMeta={customersPageMeta} handlePageChange={handlePageChange} fetchPage={setCustomersPage}/>}

        </div>
    </Page>
  );
};

export default Customers;
