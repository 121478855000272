import { Alert } from "react-bootstrap";
import { HelpCircle } from "react-feather";

import { appUrl } from '../env';

const HelpAlert = ({ variant, textClass, helpLink, children }) => { 

    let handleClick = () => {
        window.open(`${appUrl}${helpLink}`, '_blank');
    };

    return (
        <Alert className="flex" variant={variant}>
          <div className="w-3/4 text-start">
            <span className={textClass}>{children}</span>
          </div>
          <div className="w-1/4 text-end">
            <div
              className="cursor-pointer flex"
              onClick={() => handleClick()}>
              <div className={`hover:underline w-full mr-2 ${textClass}`}>
                Get Help
              </div>{" "}
              <HelpCircle className={textClass} />
            </div>
          </div>
        </Alert>
    );
};

export default HelpAlert;
