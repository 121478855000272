import React from "react";
import Axios from "axios";
import styled from "@emotion/styled";
import { useState, useEffect } from "react";
import { useNavigate,Link } from "react-router-dom";
import { Eye, EyeOff } from "react-feather";
import { Button, Form, Alert, Row, Col } from "react-bootstrap";
import {
  ReasonPhrases,
  StatusCodes,
  getReasonPhrase,
  getStatusCode,
} from "http-status-codes";

import { setUser } from "../../service/auth";

import { backendBaseUrl, deploymentEnv } from "../../env";

//import { withLocale } from "react-easy-localization";


import "./style.css";

import Page from "../../components/Page";
import { UserSettingService } from '../../service/UserSettingService';
import { AuthenticationService } from "../../service/auth";

Axios.defaults.withCredentials = false;

const LoginFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 600px;
`;
const Login = ({ i18n, languageCode, changeLanguage }) => {

  const navigate = useNavigate();

  const [state, setState] = useState({ error: null, type: 'wallet_owner' });
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(true);



  let fetchUserSettings = (pageInfo, user) => {
      UserSettingService.getAll(pageInfo).then(res => {
          console.log(res.data);
          let userLocalCopy = {...user};
          userLocalCopy.settings = res.data;
          setUser(userLocalCopy);

      }).catch(err => {      
          console.error(err);
      });       
  };


  const handleRadioChange = (event) => {
    console.log("handleRadioChange",event, event.target.checked);
    setIsChecked(event.target.checked);
    let checked = event.target.checked;
    
    setState((state) => ({
      ...state,
      'type': checked ? 'wallet_owner' : 'pos_user',
    }));

  };

  
  const submitWalletInfo = (event) => {
    AuthenticationService.loginWallet(state.username, state.wallet_phrase, state.type)
    .then((token) => {
        setUser(token.data); // need to set user so it can get the settings
        fetchUserSettings({page:1, page_size:10}, token.data);

        navigate("/wallet");
      })
      .catch((error) => {
        console.log(error);

        if (error.toJSON().status) {
          setState((state) => ({
            ...state,
            error: `Problem with login. MESSAGE: ${error.response.data.message} REASON:${getReasonPhrase(
              error.toJSON().status
            )} CODE:${error.toJSON().status}`,
          }));
        } else {
          setState((state) => ({
            ...state,
            error: `Problem with login. MESSAGE: ${error.message}`,
          }));
        }

      });
  };

  const handleInputChange = (event) => {
    console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  return (
    <Page withSidenav={false}>
      <Row className="p-4 min-page text-center">
        <h1>Login</h1>
        <Col sm={0} md={3} lg={3}></Col>
        <Col sm={12} md={6} lg={6}>
        
        <LoginFormWrapper>
        <div className="mt-4 w-full">
          {state.error && <Alert variant="danger">{state.error}</Alert>}
          <Form className="login-form-section" id="rapaygo-login-form">

          <Form.Group className="text-center align-center w-100">
            <div className="flex flex-row justify-center">
              <div>
              <Form.Check 
                  className="login-form-checkbox"
                  type="switch"
                  name="login_type"
                  id="login_type"
                  label={isChecked ? "OWNER" :"POINT OF SALE"}
                  checked={isChecked}
                  onChange={(e) => { handleRadioChange(e) }}
              />
              </div>

            </div>

            </Form.Group>



            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="login-form-input text-center"
                type="text"
                id="username"
                name="username"
                onChange={handleInputChange}
                placeholder="Enter username"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="text-center">Pass Phrase</Form.Label>
              {}

              {!showPassword?
              <div className="text-center">
                <Form.Control
                  className="text-center"
                  type="password"
                  id="wallet_phrase"
                  name="wallet_phrase"
                  onChange={handleInputChange}
                  placeholder="Pass Phrase"
                />
                <Eye className="mx-auto eye-icon" onClick={() => setShowPassword(!showPassword)} />
              </div>:
              <div className="text-center"> 
                <Form.Control
                  type="text"
                  className="text-center"
                  id="wallet_phrase"
                  name="wallet_phrase"
                  onChange={handleInputChange}
                  placeholder="Pass Phrase"
                  />
                <EyeOff className="mx-auto eye-icon" onClick={() => setShowPassword(!showPassword)}/>
              </div>}
            </Form.Group>
            <Button variant="blue" onClick={submitWalletInfo}>
              Submit
            </Button>
          </Form>
        </div>
        <div className="row login-footer">
            <div className="text-center">
              <div className="m-1"><Link to="/signup">Haven't got an account yet?</Link></div>
              <div className="m-1"><Link to="/reset">Forgot your password?</Link></div>
            </div>
        </div>
        </LoginFormWrapper>
        

        </Col>
        <Col sm={0} md={3} lg={3}></Col>
      </Row>


    </Page>
  );
};

export default Login;
