import React from "react";
import Axios from "axios";
import styled from "@emotion/styled";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Alert, Row, Col, Spinner } from "react-bootstrap";
import {
  getReasonPhrase,
} from "http-status-codes";

import { signup } from "../../service/WalletService";
//import { withLocale } from "react-easy-localization";
import Page from "../../components/Page";

import "./style.css";

Axios.defaults.withCredentials = false;

const SignupFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Signup = ({ i18n, languageCode, changeLanguage }) => {
  const [state, setState] = useState({ error: null, email: "" });
  const [signupConfirmed, setSignupConfirmed] = useState(false);
  const [savingForm, setSavingForm] = useState(false);


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isValidEmail = (email) => {
    if (validateEmail(email)) {
      return true;
    } else {
      return false;
    }
  }

  const handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const signupFormSubmit = (event) => {
    event.preventDefault();
    console.log("signupFormSubmit", state.email);

    if (isValidEmail(state.email)) {
      

      setTimeout(() => {  
          setSavingForm(true);
          signup(state.email).then((response) => {
            console.log(response);
            setSignupConfirmed(true);
            setSavingForm(false);
          }).catch((error) => {
          
            if (error.toJSON().status) {
              setState((state) => ({
                ...state,
                error: `Problem with signup. MESSAGE: ${error.response.data.message} REASON:${getReasonPhrase(
                  error.toJSON().status
                )} CODE:${error.toJSON().status}`,
              }));
              setSavingForm(false);
            } else {
              setState((state) => ({
                ...state,
                error: `Problem with signup. REASON:${error.response.data.message}`,
              }));
              setSavingForm(false);
            }
            
          });

       }, 1000);

    } else {
      setState((state) => ({
        ...state,
        error: "Please enter a valid email address",
      }));
      setSavingForm(false);
    }    
  };

  return (
    <Page withSidenav={false}>
      <Row className="p-4">
        <Col sm={0} md={1} lg={2}></Col>
        <Col sm={12} md={10} lg={8}> 
          {signupConfirmed ?
            <div>
              <h1>Thank you!</h1>
              <h2>You have successfully registered.</h2>
              <Alert variant="success">We need to verify the your email 
              address so make sure to look for an email from us and follow 
              the instructions.</Alert>
            </div>   
          :
          <SignupFormWrapper className="mt-1 mb-2">
            <div className="signup-form justify-center">
              <div>
                <h1>Signup</h1>
              </div>
              <Row>
                <Col>
                  <h2 className="color-green">Start Using The Power of the Lightning Network and Bitcoin for Just 1% In Payment Fees Now!</h2>
                  
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3>How do you signup?</h3>
                  <ol className="list-decimal">
                    <li>Enter your email address</li>
                    <li>Click on the verification link in the email you receive.</li>
                    <li>Rapaygo will send you instructions to login by email.</li>
                  </ol>
                </Col>
              </Row>

              <Row>
                <Col className="align-center">

                  {savingForm?<Spinner animation="border" variant="primary" />:
                  <>
                  {state.error && <Alert variant="danger">
                  {state.error} </Alert>}
                    <Form className="login-form-section" id="rapaygo-login-form">
                      {/* <Form.Group className="mb-3"> */}
                        <div className="flex justify-center p-3">
                          <div className="w-100 md:w-1/2 lg:w-1/3">
                            <Form.Control
                              className="text-center"
                              type="text"
                              id="email"
                              name="email"
                              onChange={handleInputChange}
                              placeholder="Enter Valid Email Address"
                            />
                          </div>

                        </div>

                      {/* </Form.Group> */}
                      <button className="bg-green-500 hover:bg-green-600 text-white p-1 rounded text-2xl" 
                        onClick={(e) => signupFormSubmit(e)}>
                        Submit
                      </button>
                    </Form></>}
                </Col>
              </Row>
              <Row className="mt-3 mb-3 color-grey">
                <Col>
                  <strong>Your security and privacy is crucial to rapaygo.</strong>
                  We are NOT collecting <a href="https://www.investopedia.com/terms/k/knowyourclient.asp">KYC</a> for your subscription, we collect your email address to securely send you information about the platform. Got more questions about
                    regulatory requirements? Read our <a href="/docs/#/faq">Frequently Ask Questions</a>.
                </Col>
              </Row>
            </div>
          </SignupFormWrapper>}
          </Col>
        <Col sm={0} md={1} lg={2}></Col>
      </Row>
    </Page>
  );
};

export default Signup;
