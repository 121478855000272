import React, { useState, useEffect } from "react";
import lodash from "lodash"; 
import { WalletService } from '../service/WalletService';
import { getUser, getAccessTokenInfo } from '../service/auth';


const AddressSelector = ({onChange, name, addresses, addressSelected, label="Address Selection"}) => {
    const [tokenInfo] = useState(getAccessTokenInfo(getUser()));  
    const [selectedAddressModel, setSelectedAddressModel] = useState(true);

    useEffect(() => {
        console.log("AddressSelector: useEffect", addressSelected, typeof addressSelected, addresses);
        let sval = lodash.filter(addresses,(o) => { 
          return o.id === parseInt(addressSelected);
        });
        console.log("AddressSelector: useEffect: sval", sval);
        setSelectedAddressModel(sval[0]);
    }, [addressSelected]);

  
    return (
      <>
        <div className="w-100">

          <div className="w-full">
              <label className="block">
                  <span className="text-gray-700">{label}</span>
                  <select name={name} value={addressSelected} onChange={(e)=>onChange(e)} className="mt-1 block w-full rounded">
                      <option value={-1}>None Selected</option>
                      {addresses && addresses.map((addressItem, index) => (<option key={index} value={addressItem.id}>{addressItem.name}</option>))}
                  </select>
              </label>
          </div>   
        </div>
      </>
    );
  }

  export default AddressSelector;