import {AxiosService} from "./AxiosService";

export const UserSettingService = {
    fetchPage(query = {location: '/user_setting', page: 1, page_size: 15}) {
        return AxiosService.fetchPage(query.location, query);
    },
    getById(id) {
        console.log("getById", id);
        return AxiosService.get(`/user_setting/${id}`);
    },
    create(formData) {
        return AxiosService.post(`/user_setting`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    update(formData) {
        return AxiosService.put(`/user_setting`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    delete(userSettingId) {
        return AxiosService.del(`/user_setting/${userSettingId}`);
    },
    getAll (){
        return AxiosService.get(`/user_setting`,{
            headers: {'Accept': 'application/json'}
        });
    },
    getPaged (pageInfo={page: 1, page_size: 15}){
        return AxiosService.getPage(`/user_setting`, pageInfo);
    },

};