import React , { useState, useEffect }from 'react'
import ProductSummary from "./ProductSummary";
import { ProductService } from '../service/ProductService'
import NewPagination from "./NewPagination";
import { useNavigate } from 'react-router-dom';
import ConfirmModal from "./ConfirmModal";

import { Row, Col, Spinner } from 'react-bootstrap';

const ProductList = ({useStore}) => {

    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [paymentItems, setPaymentItems] = useState([]);
    const [currentPage, setCurrentPage] = useState({page:1, page_size:5});
    const [currentPageMeta, setCurrentPageMeta] = useState({total_items_count:5, current_page_number:1, total_pages_count:1});

    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState();

    useEffect(() => {
        fetchPaymentItems(currentPage);
    },[currentPage]);
    
    let fetchPaymentItems = (pageInfo) => {
        setLoading(true);
        ProductService.getPaymentItemsPaged(pageInfo).then(res => {
            setPaymentItems(res.data.products);
            setCurrentPageMeta(pageInfo);
            setCurrentPageMeta(res.data.page_meta);
            setLoading(false);
        }).catch(err => {      
            console.error(err);
        });       
    };


    let deleteProduct = (id) => {
        setDeleteId(id);
        setShowModal(true);
    }

    let handleConfirmDelete = () => {
        ProductService.deletePaymentItem(deleteId)
        .then(res => {
            fetchPaymentItems(currentPage);
            navigate('/items');
        })
        .catch(err => {
            console.error(err);
        });
        setShowModal(false);
    }

    const handlePageChange = (e) => {
        setCurrentPage({page: 1, page_size: parseInt(e)});
    }
    

    const listItems = paymentItems.map((product) => (
        <div className="p-2 m-1 bg-white rounded" key={product.id}>  
            <ProductSummary
                useStore={useStore}
                handleDeleteCallback={deleteProduct}
                product={product}/>       
        </div>    
    ));

    return (<>

        <div>
            {loading && <Spinner animation='border' />}
            {paymentItems.length>0 ? 
            <>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                {listItems}
            </div>
            {currentPage && <div className='mb-5'><NewPagination pageInfo={currentPage} pageMeta={currentPageMeta} handlePageChange={handlePageChange} fetchPage={setCurrentPage}/></div>}
            <ConfirmModal showModal={showModal} actionName={'Delete Product'} actionDescription={'delete this product'} actionCallback={handleConfirmDelete}/>
            </>

            : 
            <div>No Products</div>}
        </div>

    </>);
};

export default ProductList;

